var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "time",
    {
      class: ["v-date", "hint", "hint--" + _vm.hintDirection],
      attrs: {
        timestamp: _vm.timestamp,
        title: _vm.relative ? _vm.formatted : _vm.relative_formatted,
        "data-hint": _vm.relative ? _vm.formatted : _vm.relative_formatted,
      },
    },
    [
      _vm._v(
        "\n\t\t" +
          _vm._s(_vm.relative ? _vm.relative_formatted : _vm.formatted) +
          "\n\t"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }