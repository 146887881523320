require('styles/vendor/hint.scss')

let Hint = {
	positions: ['top', 'left', 'bottom', 'right'],
	colors: ['info']
}

Hint.install = function(Vue, options) {
	Vue.directive('hint', function(el, binding) {
		el.classList.add('hint')
		el.dataset.hint = binding.value

		for (let pos of Hint.positions) {
			if (binding.modifiers[pos]) {
				el.classList.add('hint--' + pos)
			} else {
				el.classList.remove('hint--' + pos)
			}
		}

		for (let color of Hint.colors) {
			if (binding.modifiers[color]) {
				el.classList.add('hint--' + color)
			} else {
				el.classList.remove('hint--' + color)
			}
		}
	})
}

export default Hint
