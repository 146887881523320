export default {
	headline: 'How do I import orders from a file?',

	content: `
		<ol>
			<li>Open {orders_upload_link}.</li>
			<li>If you can access multiple clients, select the client you want to import orders for.</li>
			<li>
				Select a file by clicking on the file icon.<br />
				
				<small>
					Please note that which format is expected depends on your
					CRM integration. If you are unsure about which formats are accepted,
					<a href="mailto:support@meglerfront.no">send us an email</a>.
				</small>
			</li>
			
			<li>
				Click "{translation_verify_file}". This can take up to a minute.
			</li>
			
			<li>
				If there are issues with the file, you will be given an overview of all warnings and errors.
				
				<ul>
					<li>
						Warnings indicate missing values or other errors that do not prevent importing the file.<br />
						<small>Examples: Missing birthdates, org numbers or empty lines</small>
					</li>
					
					<li>
						Errors indicate invalid values or other errors that prevent importing the file.<br />
						<small>Examples: Invalid dates, phone numbers or addresses</small>
					</li>
				</ul>
				
				Correct the issues in your file and click "{translation_try_again}" to try again.
			</li>
			
			<li>
				If there no issues, a new button appears to submit the file. Click it. This can take up to a minute.
			</li>
			
			<li>
				Once the submission is finished it can take up to 12h for our systems to pick up your orders. If
				you think you found an issue, please send along the reference numbers given in the white box.
				<br />
				<small>
					Note: Should you have access to the order fetch tool, you can try to fetch your new orders
					immediately.
				</small>
			</li>
		</ol>
		
		<h3>Common Mistakes in Files</h3>
		
		<ul>
			<li>
				Phone numbers should be in a machine readable format, optionally with country code.<br />
				<small>Examples: +47 12121212, 0152 095 039 92, 4790154954</small>
			</li>
			
			<li>
				Email addresses should only occur once per cell.
			</li>
			
			<li>
				Addresses should not include comments, e.g. "send copy to x@y.com".
			</li>
			
			<li>
				Make sure that column names match your integration. If you are unsure about which columns are required,
				<a href="mailto:support@meglerfront.no">send us an email</a>.
			</li>
		</ul>
	`
}