"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const UIHelper_1 = require("lib/UIHelper");
class Container {
    constructor(container = {}) {
        this.container = container;
    }
    getObject(key) {
        let parts = key.split('.');
        let base = this.container;
        while (parts.length > 1) {
            let part = parts.shift();
            if (typeof base[part] !== 'object') {
                base[part] = {};
            }
            base = base[part];
        }
        return {
            object: base,
            key: parts.shift()
        };
    }
    /**
     * Set a Value in the container
     *
     * @param  {string} path  Dotted path, elements must be JS-compatible and JSON-stringifiable
     * @param  {any}    value Value
     */
    set(path, value) {
        let { object, key } = this.getObject(path);
        object[key] = value;
    }
    /**
     * Remove a Value
     *
     * @param  {string} path Dotted path
     */
    remove(path) {
        let { object, key } = this.getObject(path);
        delete object[key];
    }
    merge(obj) {
        this.container = UIHelper_1.default.$.extend({}, this.container, obj);
    }
    /**
     * Get a Value
     *
     * @param  {string} key               Dotted path
     * @param  {any}    defaultValue=null Default Value, if setting wasn't found
     */
    get(key, defaultValue = null) {
        let parts = key.split('.');
        let base = this.container;
        do {
            let part = parts.shift();
            if (base[part] === undefined) {
                return defaultValue;
            }
            if (parts.length && typeof base[part] !== 'object') {
                throw new Error('Keypath (' + key + ') is longer than storage');
            }
            base = base[part];
        } while (parts.length);
        return base;
    }
    export() {
        return JSON.parse(this.toJSON());
    }
    toJSON() {
        return JSON.stringify(this.container);
    }
    static fromJSON(json) {
        return new Container(json);
    }
}
exports.default = Container;
