"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Container_1 = require("lib/Container");
class UserSettings {
    constructor(container = new Container_1.default()) {
        this.container = container;
    }
    updateLocalStorage() {
        localStorage.setItem('settings', this.container.toJSON());
    }
    /**
     * Get a setting
     *
     * @param  {string} key               Dotted path
     * @param  {any}    defaultValue=null Default Value, if setting wasn't found
     */
    get(key, defaultValue = null) {
        return this.container.get(key, defaultValue);
    }
    /**
     * Set a setting in the setting container
     *
     * @param  {string} path  Dotted path, elements must be JS-compatible and JSON-stringifiable
     * @param  {any}    value Value
     */
    set(path, value) {
        this.container.set(path, value);
        this.updateLocalStorage();
    }
    /**
     * Remove a setting
     *
     * @param  {string} path Dotted path
     */
    remove(path) {
        this.container.remove(path);
        this.updateLocalStorage();
    }
    merge(obj) {
        this.container.merge(obj);
        this.updateLocalStorage();
    }
    static fromJSON(json) {
        return new UserSettings(Container_1.default.fromJSON(json));
    }
    static reviver(key, value) {
        return key === '' ? UserSettings.fromJSON(value) : value;
    }
}
exports.default = UserSettings;
