"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const UIHelper_1 = require("./UIHelper");
const UserManager_1 = require("./UserManager");
const Asgard_1 = require("./Asgard");
class ErrorHandler {
    static handleAjax(a, b, c) {
        if (b && c) {
            // If b and c are set, we're called by jQueryXHR
            ErrorHandler.handleAjax(Asgard_1.default.parseResponse(a));
        }
        else {
            let message = a.message || a.responseText || String(a);
            if (a.status) {
                if (a.raw) {
                    console.error('[API error]', a.raw);
                }
                else {
                    console.error('[Error]', a);
                }
                if (a.status == 500) {
                    try {
                        UIHelper_1.default.errorMessage('<pre style="text-align:left; overflow-x: auto; -webkit-overflow-scrolling: touch;">' + JSON.stringify(a, null, 4) + '</pre>', 'Internal Error', false, true, true, true);
                    }
                    catch (e) {
                        UIHelper_1.default.errorMessage(message);
                    }
                    // Report!!
                }
                else if (a.status == 401 && (message == 'Invalid token.' || a.raw.code == 3)) {
                    UIHelper_1.default.infoMessage('You have to log in again to continue.', '', null).then(_ => {
                        UserManager_1.default.logout();
                        self.location.reload();
                    });
                    console.log('Token invalidated, forced relogin');
                }
                else {
                    UIHelper_1.default.jConfirm(message, 'error');
                }
            }
            else {
                UIHelper_1.default.errorMessage(message);
            }
        }
    }
}
exports.default = ErrorHandler;
