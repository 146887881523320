"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class TDL {
    static getColorForEntry(entry) {
        if (entry.status < 0) {
            return 'red';
        }
        return this.UI_MAP[entry.status] ? this.UI_MAP[entry.status].color : 'light-grey-blue';
    }
    static getIconForEntry(entry) {
        if (entry.product && entry.product.category == 'electricity') {
            if (entry.status < -1) {
                return 'acp/powerless';
            }
            else if (entry.status == TDL.STATUS_DONE) {
                return 'acp/power';
            }
        }
        return this.UI_MAP[entry.status] ? this.UI_MAP[entry.status].icon : 'acp/help';
    }
    static getComponentForEntry(entry) {
        switch (entry.status) {
            case TDL.ERROR_BIX_DATA_NOT_FOUND:
            case TDL.ERROR_BIX_DATA_UNVERIFIED:
            case TDL.ERROR_INVALID_NAME:
            case TDL.ERROR_INVALID_EMAIL:
            case TDL.ERROR_INVALID_PHONE:
            case TDL.ERROR_INVALID_TYPE:
            case TDL.ERROR_INVALID_BIRTHDATE:
                return 'InvalidDataEntry';
            case TDL.STATUS_WAITING:
            case TDL.STATUS_PROCESSING:
            case TDL.STATUS_DONE:
                return 'DoneEntry';
            default:
                // let flags = new Flags(entry.tags)
                return 'OtherEntry';
        }
    }
}
exports.default = TDL;
TDL.TAG_PROVIDER_EXTERNAL_ERROR = 2;
TDL.TAG_INCOMPLETE = 4;
TDL.TAG_DUPLICATE = 8;
TDL.TAG_METER_SHARED = 16; // aka Fellesmålt
TDL.TAG_REVOKED = 32;
TDL.TAG_SELLER_RESALE = 64;
TDL.STATUS_ERROR = -1;
TDL.STATUS_WAITING = 0;
TDL.STATUS_PROCESSING = 1;
TDL.STATUS_DONE = 2;
TDL.STATUS_NO_INTEGRATION = 3;
// Errors directly related to us
TDL.ERROR_INVALID_NAME = -110;
TDL.ERROR_INVALID_BIRTHDATE = -111;
TDL.ERROR_INVALID_EMAIL = -112;
TDL.ERROR_INVALID_PHONE = -113;
TDL.ERROR_INVALID_TYPE = -114;
TDL.ERROR_INVALID_PERSON_NUMBER = -115;
// External errors
TDL.ERROR_BIX_NOT_REACHABLE = -201;
TDL.ERROR_BIX_DATA_NOT_FOUND = -202;
TDL.ERROR_BIX_DATA_UNVERIFIED = -203;
TDL.ERROR_BIX_TIMEOUT = -204;
// Map from SweetDesk to asgard
TDL.TYPE_MAP = {
    'ean-missing': 'ean',
    'invalid-person-data': 'invalid_person_data',
    'marked-failed': 'marked_failed',
    'other': 'other'
};
TDL.UI_MAP = {
    [TDL.STATUS_ERROR]: {
        icon: 'status/error',
        color: 'red'
    },
    [TDL.STATUS_WAITING]: {
        icon: 'status/waiting',
        color: 'blue'
    },
    [TDL.STATUS_PROCESSING]: {
        icon: 'status/processing',
        color: 'orange'
    },
    [TDL.STATUS_DONE]: {
        icon: 'status/done',
        color: 'green'
    },
    [TDL.STATUS_NO_INTEGRATION]: {
        icon: 'status/done',
        color: 'light-grey'
    }
};
