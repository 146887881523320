"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
let deepEqual = require('deep-equal');
let emojiRegex = require('emoji-regex');
/**
 * Functions
 * Global functions with no special theme.
 *
 * @author bluefirex
 * @version 1.0
 * @package as.adepto.sweet-acp.lib
 */
class Functions {
    /**
     * Filter an object to only contain key/value-pairs where the filter
     * function returns a truthy value.
     *
     * @param  {Object}       obj     Object to filter
     * @param  {(value, key?} filter  Filter function. Takes a value and a key as arguments with the key being optional.
     *
     * @return {Object}
     */
    static filterObject(obj, filter) {
        return Object.keys(obj)
            .filter(key => filter(obj[key], key))
            .reduce((res, key) => (res[key] = obj[key], res), {});
    }
    /**
     * Sort an object.
     * See, JavaScript doesn't really have an order for objects. But due to the implementation fact
     * in every browser it matters in what order you set the keys. This is what this does.
     * Note: When printing a "sorted" object to the console the order is lost in the console.
     * It is still sorted, though.
     *
     * @param  {Object} obj   Object to "sort"
     * @param  {(a, b}  sort  Function to decide, how to sort. Return -1 for a < b; 0 for a == b and 1 for b > a
     *
     * @return {Object}
     */
    static sortObject(obj, sort) {
        let temp = [];
        let sorted = {};
        // Gather data
        for (let key in obj) {
            temp.push({
                key,
                value: obj[key]
            });
        }
        // Sort
        let defaultSort = (a, b) => a.value - b.value;
        temp.sort(sort || defaultSort);
        for (let o of temp) {
            sorted[o.key] = o.value;
        }
        return sorted;
    }
    static mergeObjects(src, ...objs) {
        let source = $.extend(true, {}, src);
        for (let obj of objs) {
            source = $.extend(true, source, obj);
        }
        return source;
    }
    static indexOfLooseTypes(arr, b) {
        for (let i = 0; i < arr.length; i++) {
            if (deepEqual(arr[i], b)) {
                return i;
            }
        }
        return -1;
    }
    static deepEqual(obj1, obj2) {
        return deepEqual(obj1, obj2);
    }
    static group(array, decider) {
        let groups = {};
        for (let item of array) {
            let key = decider(item);
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(item);
        }
        return groups;
    }
    static clone(arr) {
        return JSON.parse(JSON.stringify(arr));
    }
    static isEmpty(val) {
        const type = typeof (val);
        if (type == 'number' || type == 'boolean') {
            return false;
        }
        if (val === undefined || val === null) {
            return true;
        }
        if (typeof (val.length) != 'undefined') {
            return val.length == 0;
        }
        for (let i in val) {
            if (val.hasOwnProperty(i)) {
                return true;
            }
        }
        return false;
    }
    /**
     * Create a difference array between arr1 and arr2 so it contains
     * all elements that are in arr1 but not in arr2 (read: arr1 - arr2).
     *
     * @param  {any[]}  arr1 An array
     * @param  {any[]}  arr2 Another array
     *
     * @return {any[]}      Difference Array
     */
    static arrayDiff(arr1, arr2) {
        return arr1.filter(a => Functions.indexOfLooseTypes(arr2, a) < 0);
    }
    /**
     * Create an array that only contains elements that are present in arr1 AND arr2.
     *
     * @param  {any[]}  arr1 An array
     * @param  {any[]}  arr2 Another array
     *
     * @return {any[]}      intersected array
     */
    static arrayIntersect(arr1, arr2) {
        return arr1.filter(a => Functions.indexOfLooseTypes(arr2, a) !== -1);
    }
    /**
     * Get the value of a path inside an object.
     *
     * @param  {Object} obj  Object
     * @param  {string} path Path, i.e. foo.bar.test
     *
     * @return {any}
     */
    static deepValue(obj, path) {
        for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
            obj = obj[path[i]];
        }
        return obj;
    }
    /**
     * Parse a query string:
     * test=bla&hello=imastring => { test: 'bla', hello: 'imastring' }
     *
     * @param  {string = ''}  hash Query string to parse
     *
     * @return {Object}
     */
    static parseQueryString(hash = '') {
        if (hash == '' || hash == undefined) {
            return {};
        }
        let data = hash.replace('#', '').split('&');
        let params = {};
        if (data.length < 1) {
            return params;
        }
        for (let i = 0, max = data.length; i < max; i++) {
            let split = data[i].split('=');
            let name = split[0];
            let value = split[1];
            if (name == '') {
                continue;
            }
            try {
                params[name] = JSON.parse(value);
            }
            catch (e) {
                params[name] = value;
            }
        }
        return params;
    }
    /**
     * Modify a parameter in a given query string.
     * i.e. you have a=1&b=2 and you want to set a to 9 (a=9&b=2).
     *
     * @param  {string} str   Query string to modify
     * @param  {string} key   Key to modify
     * @param  {string} value New value of the key
     *
     * @return {string}
     */
    static modifyQueryString(str, key, value) {
        let params = this.parseQueryString(str);
        params[key] = value;
        return $.param(params);
    }
    /**
     * Convert an object to a query string.
     * i.e.: { a: 1, b: 2} => 'a=1&b=2'
     *
     * @param  {Object} params Object
     *
     * @return {string}
     */
    static createQueryString(params) {
        return $.param(params);
    }
    /**
     * Calculate the size of something, i.e. an object or an array.
     *
     * @param  {mixed} arg  Thing to count/get the size
     *
     * @return {number}     Size or -1, if size cannot be calculated
     */
    static size(arg) {
        if (arg instanceof Array) {
            return this.arraySize(arg);
        }
        else if (typeof (arg) == 'object' && arg !== null && arg !== undefined) {
            return this.objectSize(arg);
        }
        return -1;
    }
    /**
     * Count the number of keys in an object.
     *
     * @param  {Object} obj Object to count
     *
     * @return {number}
     */
    static objectSize(obj) {
        return Object.keys(obj).length;
    }
    /**
     * Count the number of items in an array.
     *
     * @param  {Array} arr Array to count
     *
     * @return {number}
     */
    static arraySize(arr) {
        return arr.length;
    }
    /**
     * Convert a key-value object into a key-value paired array
     * e.g.
     * 		{ a: 1, b: 2 } becomes
     * 		[ { key: 'a', value: 1 }, { key: 'b', value: 2 } ]
     *
     * @param obj
     */
    static objectToArray(obj) {
        return Object.keys(obj).map(key => ({ key, value: obj[key] }));
    }
    /**
     * Create a random string with an optional prefix.
     *
     * @param  {string} prefix='' Prefix
     *
     * @return {string}
     */
    static randomString(prefix = '') {
        return prefix + (Math.random() + 1).toString(36).substring(2, 9);
    }
    static randomInt(min = 0, max = 1000) {
        return Math.floor(Math.random() * max) + min;
    }
    /**
     * Convert a string from camelCase to kebap-case.
     *
     * @param  {string} str String to convert
     *
     * @return {string}
     */
    static camelCaseToKebapCase(str) {
        return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
    }
    static capitalize(s) {
        if (s === null) {
            return '';
        }
        s = String(s);
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    static nl2br(str) {
        return String(str).replace(/\n\n/g, '<br />');
    }
    /**
     * Please slap http://stackoverflow.com/a/13542669/1486930 for this piece of code.
     *
     * @param {Number}             p  Percentage to blend, negative means darken, positive means lighten
     * @param {String}             c0 Color to blend
     * @param {String = undefined} c1 Color to blend in, optional
     */
    static shadeBlend(p, c0, c1) {
        var n = p < 0 ? p * -1 : p, u = Math.round, w = parseInt;
        if (c0.length > 7) {
            let f = c0.split(","), t = (c1 ? c1 : p < 0 ? "rgb(0,0,0)" : "rgb(255,255,255)").split(","), R = w(f[0].slice(4)), G = w(f[1]), B = w(f[2]);
            return "rgb(" + (u((w(t[0].slice(4)) - R) * n) + R) + "," + (u((w(t[1]) - G) * n) + G) + "," + (u((w(t[2]) - B) * n) + B) + ")";
        }
        else {
            let f = w(c0.slice(1), 16), t = w((c1 ? c1 : p < 0 ? "#000000" : "#FFFFFF").slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF;
            return "#" + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1);
        }
    }
    static rgbToHsl(r, g, b) {
        r /= 255, g /= 255, b /= 255;
        let max = Math.max(r, g, b), min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;
        if (max == min) {
            h = s = 0; // achromatic
        }
        else {
            var d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }
        return [h, s, l];
    }
    static hexToHsl(hex) {
        if (!hex) {
            return [0, 0, 0];
        }
        let [r, g, b] = Functions.hexToRgb(hex);
        return Functions.rgbToHsl(r, g, b);
    }
    static hexToRgb(hex) {
        if (hex.charAt(0) == '#') {
            hex = hex.substring(1);
        }
        return [
            parseInt(hex.substr(0, 2), 16),
            parseInt(hex.substr(2, 2), 16),
            parseInt(hex.substr(4, 2), 16),
        ];
    }
    /**
     * Return the perceived brightness of a color.
     * The perceived brightness is the brightness value between 0 and 255
     * as perceived by a human in RGB colorspace.
     *
     * @param  {string|number}    arg0 Hex Color or Red-value
     * @param  {number|undefined} g    Color of Green-value
     * @param  {number|undefined} b    Color of Blue-value
     *
     * @return {number}
     */
    static perceivedBrightnessOfColor(arg0, g, b) {
        if (g !== undefined || b !== undefined) {
            // RGB
            return 0.2126 * arg0 + 0.7152 * g + 0.0722 * b;
        }
        else {
            // Hex
            let hex = String(arg0).replace('#', '');
            if (hex.length < 6) {
                hex = hex.charAt(0).repeat(2) + hex.charAt(1).repeat(2) + hex.charAt(2).repeat(2);
            }
            let [r, g, b] = (hex.match(/.{2}/g) || [])
                .map(i => parseInt(i, 16));
            return Functions.perceivedBrightnessOfColor(r || 0, g || 0, b || 0);
        }
    }
    static formatNumber(number, decimals = 2, decPoint = '.', thousandsSeperator = ',', dropZeroes = false) {
        number = number * 1; // makes sure `number` is numeric value
        let parts = [];
        let str;
        if (dropZeroes) {
            str = number.toFixed(decimals || 0)
                .replace('.' + '0'.repeat(decimals), '')
                .toString()
                .split('.');
        }
        else {
            str = number.toFixed(decimals || 0)
                .toString()
                .split('.');
        }
        for (let i = str[0].length; i > 0; i -= 3) {
            parts.unshift(str[0].substring(Math.max(0, i - 3), i));
        }
        str[0] = parts.join(thousandsSeperator);
        return str.join(decPoint);
    }
    static pregQuote(str) {
        // http://kevin.vanzonneveld.net
        // +   original by: booeyOH
        // +   improved by: Ates Goral (http://magnetiq.com)
        // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
        // +   bugfixed by: Onno Marsman
        // *     example 1: preg_quote("$40");
        // *     returns 1: '\$40'
        // *     example 2: preg_quote("*RRRING* Hello?");
        // *     returns 2: '\*RRRING\* Hello\?'
        // *     example 3: preg_quote("\\.+*?[^]$(){}=!<>|:");
        // *     returns 3: '\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:'
        return String(str).replace(/([\\\.\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:])/g, "\\$1");
    }
    static generateID(prefix, length = 6) {
        return prefix + (Math.random() + 1).toString(36).substring(2, length + 2);
    }
    /**
     * Uppercases words in a string, i.e.
     * "hello there" => "Hello There"
     *
     * @param  {string} str String to uppercase words in
     *
     * @return {string}
     */
    static uppercaseWords(str) {
        //  discuss at: http://locutus.io/php/ucwords/
        return (str + '').replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase());
    }
    static regexReplaceCaseInsensitive(needle, haystack, replacement = '<b>$1</b>') {
        return String(haystack).replace(new RegExp('(' + Functions.pregQuote(needle) + ')', 'gi'), replacement);
    }
    static normalizeSweetDeskDates(val) {
        if (val === null) {
            return null;
        }
        else if (Array.isArray(val)) {
            for (let key in val) {
                if (val.hasOwnProperty(key)) {
                    val[key] = this.normalizeSweetDeskDates(val[key]);
                }
            }
        }
        else if (typeof (val) === "object") {
            if (val.human !== undefined && val.timestamp !== undefined) {
                return val.human;
            }
            for (let key in val) {
                if (val.hasOwnProperty(key)) {
                    val[key] = this.normalizeSweetDeskDates(val[key]);
                }
            }
        }
        return val;
    }
    static arrayFillCount(arr, start, step = 1) {
        return Array.apply(0, Array(arr.length)).map((_, b) => start + b * step);
    }
    static debounce(func, wait = 200, immediate = false) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            let later = function () {
                timeout = null;
                if (!immediate)
                    func.apply(context, args);
            };
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    }
    static throttle(func, threshhold = 250, scope = null) {
        let last, deferTimer;
        return function () {
            let context = scope || this;
            let now = +new Date, args = arguments;
            if (last && now < last + threshhold) {
                // hold on to it
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function () {
                    last = now;
                    func.apply(context, args);
                }, threshhold);
            }
            else {
                last = now;
                func.apply(context, args);
            }
        };
    }
    static humanFilesize(bytes, si = false) {
        const thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }
    static extractFirstEmoji(str) {
        const regex = emojiRegex();
        let match = regex.exec(str.substring(0, 4));
        if (match && match[0]) {
            return {
                emoji: match[0],
                remainder: str.replace(match[0], '').trim()
            };
        }
        return {
            emoji: null,
            remainder: str
        };
    }
    static hasFirstEmoji(str) {
        return emojiRegex().test(str.substring(0, 4));
    }
    static isFlagEmoji(emoji) {
        if (!emoji) {
            return false;
        }
        const flagRanges = [
            '\\u{1F1E6}[\\u{1F1E9}-\\u{1F1EC}\\u{1F1EE}\\u{1F1F1}\\u{1F1F2}\\u{1F1F4}\\u{1F1F6}-\\u{1F1FA}\\u{1F1FC}\\u{1F1FD}\\u{1F1FF}]',
            '\\u{1F1E7}[\\u{1F1E6}\\u{1F1E7}\\u{1F1E9}-\\u{1F1EF}\\u{1F1F1}-\\u{1F1F4}\\u{1F1F6}-\\u{1F1F9}\\u{1F1FB}\\u{1F1FC}\\u{1F1FE}\\u{1F1FF}]',
            '\\u{1F1E8}[\\u{1F1E6}\\u{1F1E8}\\u{1F1E9}\\u{1F1EB}-\\u{1F1EE}\\u{1F1F0}-\\u{1F1F4}\\u{1F1F7}\\u{1F1FA}-\\u{1F1FF}]',
            '\\u{1F1E9}[\\u{1F1EA}\\u{1F1EF}\\u{1F1F0}\\u{1F1F2}\\u{1F1F4}\\u{1F1FF}]',
            '\\u{1F1EA}[\\u{1F1E8}\\u{1F1EA}\\u{1F1EC}\\u{1F1ED}\\u{1F1F7}-\\u{1F1F9}]',
            '\\u{1F1EB}[\\u{1F1EE}\\u{1F1EF}\\u{1F1F0}\\u{1F1F2}\\u{1F1F4}\\u{1F1F7}]',
            '\\u{1F1EC}[\\u{1F1E6}\\u{1F1E7}\\u{1F1E9}-\\u{1F1EE}\\u{1F1F1}-\\u{1F1F3}\\u{1F1F5}-\\u{1F1FA}\\u{1F1FC}\\u{1F1FE}]',
            '\\u{1F1ED}[\\u{1F1F0}\\u{1F1F2}\\u{1F1F3}\\u{1F1F7}\\u{1F1F9}\\u{1F1FA}]',
            '\\u{1F1EE}[\\u{1F1E9}-\\u{1F1F4}\\u{1F1F6}-\\u{1F1F9}]',
            '\\u{1F1EF}[\\u{1F1EA}\\u{1F1F2}\\u{1F1F4}\\u{1F1F5}]',
            '\\u{1F1F0}[\\u{1F1EA}\\u{1F1EC}-\\u{1F1EE}\\u{1F1F2}\\u{1F1F3}\\u{1F1F5}\\u{1F1F7}\\u{1F1FC}\\u{1F1FE}\\u{1F1FF}]',
            '\\u{1F1F1}[\\u{1F1E6}-\\u{1F1E8}\\u{1F1EE}\\u{1F1F0}\\u{1F1F8}-\\u{1F1FB}\\u{1F1FE}]',
            '\\u{1F1F2}[\\u{1F1E6}\\u{1F1E8}-\\u{1F1ED}\\u{1F1F0}-\\u{1F1FF}]',
            '\\u{1F1F3}[\\u{1F1E6}\\u{1F1E8}\\u{1F1EA}-\\u{1F1EC}\\u{1F1EE}\\u{1F1F1}\\u{1F1F4}\\u{1F1F5}\\u{1F1F7}\\u{1F1FA}\\u{1F1FF}]',
            '\\u{1F1F4}\\u{1F1F2}',
            '\\u{1F1F5}[\\u{1F1E6}\\u{1F1EA}-\\u{1F1ED}\\u{1F1F0}-\\u{1F1F3}\\u{1F1F7}-\\u{1F1F9}\\u{1F1FC}\\u{1F1FE}]',
            '\\u{1F1F6}\\u{1F1E6}',
            '\\u{1F1F7}[\\u{1F1EA}\\u{1F1F4}\\u{1F1F8}\\u{1F1FA}\\u{1F1FC}]',
            '\\u{1F1F8}[\\u{1F1E6}-\\u{1F1EA}\\u{1F1EC}-\\u{1F1F4}\\u{1F1F7}-\\u{1F1F9}\\u{1F1FB}\\u{1F1FD}-\\u{1F1FF}]',
            '\\u{1F1F9}[\\u{1F1E8}\\u{1F1E9}\\u{1F1EB}-\\u{1F1ED}\\u{1F1EF}-\\u{1F1F4}\\u{1F1F7}\\u{1F1F9}\\u{1F1FB}\\u{1F1FC}\\u{1F1FF}]',
            '\\u{1F1FA}[\\u{1F1E6}\\u{1F1EC}\\u{1F1F2}\\u{1F1F8}\\u{1F1FE}\\u{1F1FF}]',
            '\\u{1F1FB}[\\u{1F1E6}\\u{1F1E8}\\u{1F1EA}\\u{1F1EC}\\u{1F1EE}\\u{1F1F3}\\u{1F1FA}]',
            '\\u{1F1FC}[\\u{1F1EB}\\u{1F1F8}]',
            '\\u{1F1FE}[\\u{1F1EA}\\u{1F1F9}]',
            '\\u{1F1FF}[\\u{1F1E6}\\u{1F1F2}\\u{1F1FC}]'
        ];
        const regex = new RegExp(flagRanges.join('|'), 'ug');
        return !!emoji.match(regex);
    }
    static getCompanyYears() {
        const startYear = 2015;
        let size = new Date().getFullYear() - startYear + 1;
        return [...new Array(size)].map((x, i) => i + startYear).reverse();
    }
    static getNameForMonth(month) {
        return [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December'
        ][month - 1] || null;
    }
    /**
     * Download a file containing some text.
     *
     * @param  {string} filename Filename
     * @param  {string} text     Content / Text
     */
    static downloadText(filename, text) {
        let a = document.createElement('a');
        a.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        a.setAttribute('download', filename);
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    /**
     * Download a file from a URL
     *
     * @param {string} filename Filename
     * @param {string} url      URL
     */
    static downloadLink(filename, url) {
        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', filename);
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    static copyToClipboard(text) {
        let textArea = document.createElement("textarea");
        //
        // *** This styling is an extra step which is likely not required. ***
        //
        // Why is it here? To ensure:
        // 1. the element is able to have focus and selection.
        // 2. if element was to flash render it has minimal visual impact.
        // 3. less flakyness with selection and copying which **might** occur if
        //    the textarea element is not visible.
        //
        // The likelihood is the element won't even render, not even a flash,
        // so some of these are just precautions. However in IE the element
        // is visible whilst the popup box asking the user for permission for
        // the web page to copy to the clipboard.
        //
        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;
        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;
        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        let ret;
        try {
            ret = document.execCommand('copy');
        }
        catch (err) {
            ret = false;
        }
        document.body.removeChild(textArea);
        return ret;
    }
}
exports.default = Functions;
Functions.CRM_WEBMEGLER = 'wm';
Functions.CRM_EMPROF = 'em';
Functions.CRM_ITMAKERIET = 'itm';
Functions.CRM_DEVIL = 'dvl';
Functions.SUPPORTED_CRMS = [
    Functions.CRM_WEBMEGLER,
    Functions.CRM_EMPROF,
    Functions.CRM_ITMAKERIET,
    Functions.CRM_DEVIL
];
/**
 * Curry a function: Bind arguments to a function and return a new function
 * with those arguments bound (starting from the left).
 *
 * @param {Function} fn
 * @param {any}      args Arguments to bind to fn
 *
 * @return {Function}
 */
Functions.curry = (fn, ...args) => {
    return (...a) => {
        return fn(...args, ...a);
    };
};
/**
 * Curry a function: Bind arguments to a function and return a new function
 * with those arguments bound (starting from the right).
 *
 * @param {Function} fn
 * @param {any}      args Arguments to bind to fn
 *
 * @return {Function}
 */
Functions.curryRight = (fn, ...args) => {
    return (...a) => {
        return fn(...a, ...args);
    };
};
