"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const UserManager_1 = require("./UserManager");
const ErrorHandler_1 = require("./ErrorHandler");
/**
 * Asgard
 * Abstract wrapper for calling any internal asgard API, be it the real
 * API or AJAX calls.
 *
 * @author bluefirex
 * @version 1.0
 * @package as.adepto.sweet-acp.lib
 */
class Asgard {
    /**
     * Make a call to log in a user and receive that user's information.
     *
     * @param  {string}    username Username
     * @param  {string}    password Password
     *
     * @return {JQueryXHR}
     */
    static login(username, password) {
        return Asgard.call({
            api: '/users/login',
            method: 'POST',
            data: {
                username: username,
                password: password
            }
        });
    }
    /**
     * Clean up things after a user has logged out
     */
    static logout() {
        // nothing, just for compatibility
    }
    static loginOTL(token) {
        return Asgard.call({
            api: '/users/login',
            method: 'POST',
            data: {
                token
            }
        });
    }
    /**
     * Refresh a user from its AppToken.
     *
     * @param  {string}    appToken AppToken
     *
     * @return {JQueryXHR}
     */
    static refreshUser(appToken) {
        return Asgard.call({
            api: '/users/login',
            method: 'POST',
            data: {
                token: appToken
            }
        });
    }
    /**
     * Load a given AJAX resource using GET.
     *
     * @param  {string}    path         Path to call
     * @param  {object}    query        Query params to add
     * @param  {boolean}   handleErrors Whether or not to auto-handle errors
     *
     * @return {JQueryXHR}
     */
    static load(path, query = {}, handleErrors = true) {
        return Asgard.call({
            sweetdesk: path,
            method: 'GET',
            data: query,
            global: handleErrors
        });
    }
    /**
     * Load a given AJAX resource using GET but return it as unparsed text
     *
     * @param  {string}    path         Path to call
     * @param  {object}    query        Query params to add
     * @param  {boolean}   handleErrors Whether or not to auto-handle errors
     *
     * @return {JQueryXHR}
     */
    static loadText(path, query = {}, handleErrors = true) {
        return Asgard.call({
            sweetdesk: path,
            method: 'GET',
            data: query,
            dataType: 'text',
            global: handleErrors
        });
    }
    /**
     * Make a PATCH request to a given AJAX resource.
     *
     * @param  {string}          path         Path to call
     * @param  {object|FormData} data         Data to send along (will be converted to JSON, if not FormData)
     * @param  {boolean}         handleErrors Whether or not to auto-handle errors
     *
     * @return {JQueryXHR}      [description]
     */
    static patch(path, data, handleErrors = true) {
        return Asgard.call({
            sweetdesk: path,
            method: 'PATCH',
            data: data,
            global: handleErrors
        });
    }
    /**
     * Make a POST request to a given AJAX resource.
     *
     * @param  {string}          path         Path to call
     * @param  {object|FormData} data         Data to send along (will be converted to JSON, if not FormData)
     * @param  {boolean}         handleErrors Whether or not to auto-handle errors
     *
     * @return {JQueryXHR}      [description]
     */
    static post(path, data, handleErrors = true) {
        return Asgard.call({
            sweetdesk: path,
            method: 'POST',
            data: data,
            global: handleErrors
        });
    }
    /**
     * Make a PUT request to a given AJAX resource.
     *
     * @param  {string}          path         Path to call
     * @param  {object|FormData} data         Data to send along (will be converted to JSON, if not FormData)
     * @param  {boolean}         handleErrors Whether or not to auto-handle errors
     *
     * @return {JQueryXHR}      [description]
     */
    static put(path, data, handleErrors = true) {
        return Asgard.call({
            sweetdesk: path,
            method: 'PUT',
            data: data,
            global: handleErrors
        });
    }
    /**
     * Make a DELETE request to a given AJAX resource.
     *
     * @param  {string}          path         Path to call
     * @param  {object|FormData} data         Data to send along (will be converted to JSON, if not FormData)
     * @param  {boolean}         handleErrors Whether or not to auto-handle errors
     *
     * @return {JQueryXHR}      [description]
     */
    static delete(path, data = {}, handleErrors = true) {
        return Asgard.call({
            sweetdesk: path,
            method: 'DELETE',
            data: data,
            global: handleErrors
        });
    }
    /**
     * Make a custom API request
     *
     * @param  {string}          method        Method to use, i.e. POST, PATCH
     * @param  {string}          path          Path to call
     * @param  {any}             data          Data
     * @param  {Object}          opts          Options to pass through to jQuery
     * @param  {boolean = true}  handleErrors  Whether to handle errors automatically
     *
     * @return {JQueryXHR}
     */
    static custom(method, path, data, opts, handleErrors = true) {
        return Asgard.call($.extend({}, {
            sweetdesk: path,
            method,
            data,
            global: handleErrors
        }, opts));
    }
    static elhubSearch(params) {
        return Asgard.call({
            api: '/elhub/search',
            method: 'POST',
            data: params,
            global: false
        });
    }
    /**
     * Parse a response from the API/AJAX-call.
     *
     * @param  {object}      res Browser's response object
     *
     * @return {APIResponse}
     */
    static parseResponse(res) {
        if (typeof (res) == 'string') {
            return {
                message: res,
                status: 500
            };
        }
        if (!res.responseText) {
            return {
                message: 'Unknown Error, response missing.',
                status: 500
            };
        }
        try {
            let d = JSON.parse(res.responseText);
            if (!d.message) {
                d.message = 'Unknown Error, message missing.';
            }
            return {
                message: d.message,
                status: parseInt(res.status),
                code: d.code || undefined,
                raw: d
            };
        }
        catch (e) {
            return {
                message: res.responseText,
                status: parseInt(res.status)
            };
        }
    }
    /**
     * Create a GET url for any given acp-endpoint for use with external
     * libraries.
     *
     * @param  {string} path      Endpoint Path
     * @param  {object} params={} Additional query params to add
     *
     * @return string             URL including authentication details
     */
    static makeURL(path, params = {}) {
        return Asgard.SWEETDESK_BASE_URL + path + '?' + $.param($.extend({}, params, {
            'X-Token': UserManager_1.default.user.token
        }));
    }
    /**
     * Resolve when all requests are resolved
     *
     * @param  {Promise|JQueryXHR} requests Requests
     *
     * @return {Promise}
     */
    static all(...requests) {
        let promises = [];
        for (let request of requests) {
            if (request instanceof Promise) {
                promises.push(request);
            }
            else {
                promises.push(Asgard.convertJQueryXHRToPromise(request));
            }
        }
        return Promise.all(promises);
    }
    static getClients() {
        return this.load('/clients');
    }
    static call(opts) {
        // If no specific URL is set but a key 'api' is present,
        // we're calling our own API and update the URL accordingly.
        if (opts.api && !opts.url) {
            opts.url = Asgard.API_BASE_URL + opts.api;
        }
        // If no specific URL is set but a key 'acp' is present,
        // we're calling our AJAX API for sweetdesk and update the URL accordingly.
        if (opts.sweetdesk && !opts.url) {
            opts.url = Asgard.SWEETDESK_BASE_URL + opts.sweetdesk;
        }
        let ajaxOpts = $.extend(true, {
            // Append default headers
            headers: {
                'X-Asgard-Debug': '0xDebuggyMcDebugface'
            }
        }, opts);
        if (opts.data instanceof FormData) {
            // Ignore jQuery processing for form data
            ajaxOpts.cache = false;
            ajaxOpts.contentType = false;
            ajaxOpts.processData = false;
        }
        else {
            // Make sure the response is JSON
            if (opts.dataType === undefined) {
                ajaxOpts.dataType = 'json';
            }
            if (opts.dataType === 'text') {
                ajaxOpts.processData = false;
                ajaxOpts.cache = false;
            }
            if (!("Content-Type" in ajaxOpts.headers)) {
                ajaxOpts.headers['Content-Type'] = 'application/json; charset=utf8';
            }
            if (opts.method != 'GET' && opts.data) {
                ajaxOpts.data = JSON.stringify(opts.data);
            }
        }
        // Authorize using Basic, when using our own internal API
        if (opts.api) {
            ajaxOpts.headers['Authorization'] = 'Basic ' + Asgard.AUTH_BASIC;
        }
        // Authorize using the current user's AppToken, when using our AJAX Asgard API.
        if (opts.sweetdesk) {
            ajaxOpts.headers['X-Token'] = UserManager_1.default.user.token;
        }
        // If debugging is enabled, help the current dev :)
        if (Asgard.debug) {
            console.log('AJAX Options', ajaxOpts);
        }
        return $.ajax(ajaxOpts);
    }
    static convertJQueryXHRToPromise(xhr) {
        return new Promise((resolve, reject) => {
            xhr.done((...args) => resolve(...args))
                .fail((...args) => reject(...args));
        });
    }
    /**
     * Set up any handlers that need to be registered
     */
    static setup() {
        $(document).ajaxError((event, request, settings) => {
            if (settings.url.substring(0, 4) == '/asg') {
                if (request.status >= 400) {
                    ErrorHandler_1.default.handleAjax(request, {}, {});
                }
            }
        });
    }
}
exports.default = Asgard;
Asgard.API_BASE_URL = '/asg/v1';
Asgard.SWEETDESK_BASE_URL = '/asg/sweetdesk';
Asgard.AUTH_BASIC = 'YXMuYWRlcHRvLnN3ZWV0ZGVzazo5MzJBRDAyREtRWVFHSkE5MTAzS0xT';
Asgard.ORDER_TYPES = null;
Asgard.debug = false;
