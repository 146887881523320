"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Domain = exports.DomainRouter = void 0;
/**
 * DomainRouter
 * Controller for routing domains based on protocol, path and environment.
 * Fully compatible with our DomainRouter implementation in Midgard
 * though this one was "JS-ified" (native getters and static typing).
 *
 * @author bluefirex
 * @version 1.0
 * @package as.adepto.sweet-acp.lib
 */
class DomainRouter {
    /**
     * Initialize domain configuration
     */
    static init() {
        /**
         * About this configuration format:
         * It is basically the same as the PHP DomainRouter in Midgard.
         * It has to be an object with objects in the following format:
         *
         * {
         *     host: 'some.host.tld',
         *     protocol: Domain.PROTOCOL_*,
         *     mode: Domain.MODE_*
         * }
         *
         * @type {Object}
         */
        let domainConfig = {
            [Domain.SWEETACP]: [
                {
                    host: 'sweetdesk.meglerfront.xyz',
                    protocol: Domain.PROTOCOL_HTTP,
                    mode: Domain.MODE_DEV
                },
                {
                    host: 'sweetdesk.staging.meglerfront.no',
                    protocol: Domain.PROTOCOL_HTTP,
                    mode: Domain.MODE_STAGING
                },
                {
                    host: 'sweetdesk.meglerfront.no',
                    protocol: Domain.PROTOCOL_HTTPS,
                    mode: Domain.MODE_PRODUCTION
                }
            ],
            [Domain.DEVIL]: [
                {
                    host: 'devilv2.meglerfront.xyz',
                    protocol: Domain.PROTOCOL_HTTP,
                    mode: Domain.MODE_DEV
                },
                {
                    host: 'devilv2.meglerfront.as',
                    protocol: Domain.PROTOCOL_HTTP,
                    mode: Domain.MODE_STAGING
                },
                {
                    host: 'devilv2.meglerfront.tech',
                    protocol: Domain.PROTOCOL_HTTPS,
                    mode: Domain.MODE_PRODUCTION
                }
            ],
            [Domain.DEVIL_ASSETS]: [
                {
                    host: 'devil-assets.meglerfront.xyz',
                    protocol: Domain.PROTOCOL_HTTP,
                    mode: Domain.MODE_DEV
                },
                {
                    host: 'devilassets.meglerfront.tech',
                    protocol: Domain.PROTOCOL_HTTP,
                    mode: Domain.MODE_STAGING
                },
                {
                    host: 'devilassets.meglerfront.tech',
                    protocol: Domain.PROTOCOL_HTTPS,
                    mode: Domain.MODE_PRODUCTION
                }
            ]
        };
        for (let id in domainConfig) {
            let hosts = domainConfig[id];
            for (let host of hosts) {
                this.domains.push(new Domain(id, host.host, host.protocol, host.mode || Domain.MODE_PRODUCTION));
            }
        }
    }
    /**
     * Check if the domain configuration has been initialized already.
     *
     * @return {boolean}
     */
    static isInitialized() {
        return this.domains.length > 0;
    }
    /**
     * Get all domain configurations
     *
     * @return {Domain[]}
     */
    static getAll() {
        return this.domains;
    }
    /**
     * Get a specific domain configuration
     *
     * @param  {string}                  id=null Domain.* constant or null to detect the current domain
     * @param  {string = this.getMode()} mode    Mode to get the domain for. Use Domain::MODE_* here. Leave null for auto-detection.
     *
     * @return {Domain}
     */
    static get(id = null, mode = this.getMode()) {
        if (!this.isInitialized())
            this.init();
        let host = this.getHost();
        for (let domain of this.domains) {
            if (id === null) {
                if (domain.host == host) {
                    return domain;
                }
            }
            else {
                if (domain.mode == mode && domain.id == id) {
                    return domain;
                }
            }
        }
        return this.domains[0];
    }
    /**
     * Get the current host.
     * Returns unknown.adepto.(dev|as) when host cannot be determined at all.
     *
     * @return {string}
     */
    static getHost() {
        return window.location.hostname || 'unknown.adepto.' + Domain.TLD_DEV;
    }
    /**
     * Get the currently used port.
     *
     * @return {number}
     */
    static getPort() {
        return parseInt(window.location.port);
    }
    /**
     * Check if the current host is using SSL/TLS.
     *
     * @return {boolean}
     */
    static isTLS() {
        return this.getPort() == 443;
    }
    /**
     * Get the current mode. Compare with Domain.MODE_*
     *
     * @return {string}
     */
    static getMode() {
        if (this.isDev()) {
            return Domain.MODE_DEV;
        }
        else if (this.isStaging()) {
            return Domain.MODE_STAGING;
        }
        return Domain.MODE_PRODUCTION;
    }
    /**
     * Check if the current mode is DEV.
     *
     * @return {boolean}
     */
    static isDev() {
        return this.getEnv() == 'development';
    }
    /**
     * Check if the current mode is STAGING.
     *
     * @return {boolean}
     */
    static isStaging() {
        return this.getEnv() == 'staging';
    }
    /**
     * Check if the current mode is PRODUCTION.
     *
     * @return {boolean}
     */
    static isProduction() {
        return this.getEnv() == 'production';
    }
    /**
     * Get the current env variable injected by Webpack.
     *
     * @return {string}
     */
    static getEnv() {
        return process.env.NODE_ENV;
    }
}
exports.DomainRouter = DomainRouter;
DomainRouter.domains = [];
class Domain {
    constructor(
    /**
     * A stringified ID to help identify a domain internally.
     * Good for comparing development to production domains.
     *
     * @type {string}
     */
    id, 
    /**
     * The host. This will be used to detect the domain,
     * i.e. 'acq.adepto.as'
     *
     * @type {string}
     */
    host, 
    /**
     * The protocol to be used. Use Domain.PROTOCOL_*
     *
     * @type {string}
     */
    protocol, 
    /**
     * The mode this domain is being used for. Use Domain.MODE_*
     *
     * @type {string}
     */
    mode = Domain.MODE_PRODUCTION) {
        this.id = id;
        this.host = host;
        this.protocol = protocol;
        this.mode = mode;
    }
    /**
     * Get the base URL.
     * This includes protocol, hostname+port (if port != 80/443) and trailing slash, i.e.:
     * https://example.com:8080/
     *
     * @return {string}
     */
    get baseURL() {
        return this.protocol + '://' + this.host;
    }
    /**
     * Get the base URL with a path, i.e.:
     * https://example.com:8080/echo/me
     *
     * @param  {string[]|string} path Array (without slashes) or string (including slashes)
     *
     * @return {string}
     */
    withPath(path) {
        if (path instanceof Array) {
            path = '/' + path.join('/');
        }
        return this.baseURL + path;
    }
    /**
     * Check if this domain is a specific ID.
     *
     * @param  {string}  id Domain.*
     *
     * @return {boolean}
     */
    is(id) {
        return this.id == id;
    }
    /**
     * Check if this domain is dev.
     *
     * @return {boolean}
     */
    isDev() {
        return this.mode == Domain.MODE_DEV;
    }
    /**
     * Check if this domain is staging
     *
     * @return {boolean}
     */
    isStaging() {
        return this.mode == Domain.MODE_STAGING;
    }
    /**
     * Check if this domain is production
     *
     * @return {boolean}
     */
    isProduction() {
        return this.mode == Domain.MODE_PRODUCTION;
    }
    /**
     * Alias for baseURL for your convenience.
     *
     * @return {string}
     */
    toString() {
        return this.baseURL;
    }
}
exports.Domain = Domain;
Domain.SWEETACP = 'sweetacp';
Domain.DEVIL = 'devil';
Domain.DEVIL_ASSETS = 'devil-assets';
Domain.PROTOCOL_HTTP = 'http';
Domain.PROTOCOL_HTTPS = 'https';
Domain.MODE_PRODUCTION = 'prod';
Domain.MODE_STAGING = 'staging';
Domain.MODE_DEV = 'development';
Domain.TLD_DEV = 'dev';
