import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import Hint from 'plugins/hint'
import Block from 'plugins/block'
import router from './router'
import {
	availableLocales,
	i18n
} from 'locales/i18n'
import Raygun from 'lib/Raygun'
import Functions from 'lib/Functions'
import DateTimeExt from 'lib/DateTimeExt'
import UIHelper from 'lib/UIHelper'
import UserManager from 'lib/UserManager'
import Asgard from 'lib/Asgard'
import Transitions from 'plugins/transitions'
import SweetUIv2 from './sweet-ui-2'

import VSvg from 'components/VSvg'
import VDate from 'components/VDate'

import mixinDebuggable from 'mixins/debuggable'
import mixinAppearance from 'mixins/appearance'

// Track Errors
Raygun.init()

// Change date & time locale
DateTimeExt.changeGlobalLocale(i18n.locale)

// Load VueRouter
Vue.use(VueRouter)

// Load SweetUI
Vue.use(SweetUIv2)

// Load Custom plugins
Vue.use(Hint)
Vue.use(Block)
Vue.use(Transitions)

// Load Vue Heaful
Vue.use(VueMeta, {
	keyName: 'meta'
})

// Load Global Components
Vue.component('VSvg', VSvg)
Vue.component('VDate', VDate)

// Load debuggable mixin globally
Vue.mixin(mixinDebuggable)

// Load appearance mixin globally
Vue.mixin(mixinAppearance)

// Filters
Vue.filter('number_format', function(value, decimals = 2, dropZeros = false) {
	return Functions.formatNumber(value, decimals, ',', '.', dropZeros)
})

Vue.filter('date_format', function(value, format = 'DD.MM.YYYY') {
	return DateTimeExt.fromTimestamp(value).format(format)
})

Vue.filter('filesize_format', function(value) {
	return Functions.humanFilesize(value)
})

Vue.filter('nl2br', function(value) {
	return value.replace(/\n\n/g, '<br />')
})

Vue.filter('country_emoji', function(country) {
	if (country == null) {
		return country
	}

	return {
		dk: '🇩🇰',
		de: '🇩🇪',
		no: '🇳🇴',
		se: '🇸🇪'
	}[country.toLowerCase()] || '🏳'
})

/** @deprecated */
Vue.filter('country_name', function(country) {
	if (country == null) {
		return country
	}

	return {
		dk: 'Denmark',
		de: 'Germany',
		no: 'Norway',
		se: 'Sweden'
	}[country.toLowerCase()] || country
})

// Load APIs
Asgard.setup()

// Injections
Vue.prototype.$asgard = Asgard

// Title Injection
const globalTitle = document.title
Vue.prototype.$makeTitle = title => title ? title + ' · ' + globalTitle : globalTitle

// Load UserManager so that values stay the same
window.UserManager = UserManager

// Load Vue
const app = new Vue({
	router,
	i18n,

	created() {
		UserManager.restoreLogin()
	},

	mounted() {
		this.$el.className = 'loaded'
		UIHelper.$(window).resize(this.onWindowResize).trigger('resize')

		UIHelper.setGlobalListeners()
	},

	updated() {
		UIHelper.setGlobalListeners()
	},

	data() {
		return {
			is_mobile: false,
			is_dev: process.env.NODE_ENV == 'development',
			available_locales: availableLocales
		}
	},

	methods: {
		onWindowResize() {
			this.is_mobile = !!window.matchMedia('screen and (max-width: 850px)').matches
		}
	}
}).$mount('#app')

// Load Modernizr
require('./vendor/modernizr/modernizr.js')
