import helpOverview from 'locales/help/en/overview'
import helpOrdersUpload from 'locales/help/en/orders_upload'

import TDL from 'data/asgard/TDL'

export default {
	locale: 'en',
	locale_specifier: 'en-US',

	messages: {
		generic: {
			copy_to_clipboard: 'Copy',
			copied_to_clipboard: 'Copied to the clipboard.',
			client: 'Client',
			order: 'Order',
			orders: 'Orders',
			order_type: 'Order Type',
			order_id: 'Order ID',
			order_reference: 'Reference',
			leads: 'Leads',
			meter: 'Meter',
			edit: 'Edit',
			change: 'Change',
			save: 'Save',
			delete: 'Delete',
			cancel: 'Cancel',
			open: 'Open',
			add: 'Add',
			view: 'View',
			create: 'Create',
			back: 'Back',
			download: 'Download',
			help: 'Help',
			submit: 'Submit',
			resubmit: 'Re-submit',
			done: 'Done',
			next: 'Next',
			search: 'Search',
			search_placeholder: 'What are you looking for?',
			simple_search_placeholder: 'Enter an order reference or date',
			details: 'Details',
			choose_clients: 'Choose Client(s)',
			title: 'Title',
			untitled: 'Untitled',
			dev: 'Developer',
			ok: 'OK',

			select_all: 'Select All',
			deselect_all: 'Deselect All',
			remove_all: 'Remove All',

			show_active_only: 'Show Active Only',

			welcome: {
				night: 'Working at night?<br /><b>Don\'t forget to sleep.</b>',
				morning: 'Good morning, {name}.',
				day: 'Nice to see you, {name}.',
				evening: 'Good evening, {name}.'
			}
		},

		date: {
			today: 'Today',
			yesterday: 'Yesterday',
			tomorrow: 'Tomorrow',
			days: '{0} days',

			generic: {
				day: 'Days | Day | Days',
				hour: 'Hours | Hour | Hours',
				minute: 'Minutes | Minute | Minutes',
				seconds: 'Seconds | Second | Seconds',

				before: 'before',
				after: 'after'
			}
		},

		navigation: {
			orders: 'Orders',
			leads: 'Leads',
			statistics: 'Statistics',
			finance: 'Finance',
			system: 'System'
		},

		sidebars: {
			orders: {
				overview: 'Overview',

				section_orders: 'Orders',
				orders_upcoming: 'Upcoming',
				orders_submitted: 'Submitted',
				orders_search: 'Search',
				orders_fetch: 'Fetch',
				orders_logs: 'Logs',

				create_order: 'Create Order',
				create_electricity: 'Create Electricity',
				upload: 'Upload',

				section_clients: 'Clients',
				clients: 'Clients',
				create_client: 'Create Client'
			},

			leads: {
				overview: 'Overview',
				section_leads: 'Leads',
				leads_search: 'Search',

				section_providers: 'Providers',
				providers: 'Providers',
				create_provider: 'Create Provider',

				dev_products: 'Products'
			},

			statistics: {
				overview: 'Overview',
				reports: 'Reports',
				orders_report: 'Orders',
				protocols_report: 'Protocols',
				leads_report: 'Leads'
			},

			help: {
				overview: 'Overview'
			}
		},

		user_widget: {
			edit_profile: 'Edit Profile',
			settings: 'Settings',
			refresh: 'Refresh',
			copy_app_token: 'Copy AppToken',
			report_issue: 'Report Issue',
			logout: 'Logout'
		},

		person: {
			title: 'Persons | Person | {count} Persons',

			types: {
				seller: 'Sellers | Seller | {count} Sellers',
				buyer: 'Buyers | Buyer | {count} Buyers',
				broker: 'Brokers | Broker | {count} Brokers',
				renter: 'Renters | Renter | {count} Renters',
				rentee: 'Rentees | Rentee | {count} Rentees',
				landlord: 'Landlords | Landlord | {count} Landlords',
				tenant: 'Tenants | Tenant | {count} Tenants',
				legal_owner: 'Current Owners | Current Owner | {count} Current Owners',
				new_owner: 'New Owners | New Owner | {count} New Owners',
				entrepreneur: 'Entrepreneurs | Entrepreneur | {count} Entrepreneurs',
				builder: 'Builders | Builder | {count} Builders',
				surveyor: 'Surveyors | Surveyor | {count} Surveyors',
				next_of_kin: 'Next of kins | Next of kin | {count} Next of kins',
				guardian: 'Guardians | Guardian | {count} Guardians',
				ceo: 'CEOs | CEO | {count} CEOs',
				board_member: 'Board members | Board member | {count} Board members',
				chairman: 'Chairmen | Chairman | {count} Chairmen',
				signature_authority: 'Signature authorities | Signature authority | {count} Signature authorities',
				general_agency: 'General agencies | General agency | {count} General agencies',
				real_rights_holder: 'Real rights holders | Real rights holder | {count} Real rights holders',
				contact: 'Contact persons | Contact person | {count} Contact persons'
			},

			selected_hint: 'This person is being used for product orders.',

			type: 'Type',

			firstname: 'Firstname',
			name: 'Name',
			person_number: 'Person Number',
			org_number: 'Org Number',
			birthdate: 'Birthdate',
			birthdate_format: 'Birthdate ({format})',

			contact: 'Contact',
			email: 'Email',
			phone: 'Phone',
			phone_prefix: 'Prefix',

			address: 'Address',
			street: 'Street',
			street_house_number: 'Street + House Nr',
			postcode: 'Postcode',
			city: 'City',
			country: 'Country',

			errors: {
				missing_person_name: 'One or more persons is missing a name.'
			}
		},

		orders_overview: {
			open_demo: 'Open Demo',
			open_demo_description: 'Open a demo order for a client.',

			open_demo_order_type: 'Order Type',
			open_demo_client: 'Client',

			no_permission_to_create_demo: 'You don\'t have permission to create demos for {client}.'
		},

		orders_collection: {
			type: {
				upcoming: 'Upcoming',
				submitted: 'Submitted'
			},

			submitted_on: 'Submitted',
			orders_per_day: 'Orders per Day',

			hints: {
				status_0: 'Waiting',
				status_1: 'Processing',
				status_2: 'Done',
				['status_-1']: 'Error'
			}
		},

		orders_upload: {
			title: 'Upload',

			upload: {
				instructions: `
					Choose a file to import orders from by clicking on the file icon.<br />
					Which filetypes are supported depends on the integration with your CRM.<br />
					<small>Maximum filesize: {filesize}</small>
				`,

				verify: 'Verify',
				try_again: 'Try again'
			},

			errors: {
				missing_values: 'This value is missing.',
				missing_values_short: 'Missing Value',

				invalid_values: 'This value is invalid.',
				invalid_values_short: 'Invalid Value',

				invalid_headers: 'This heading is unknown. Its column will be ignored.',

				other: 'Other Error',
				other_short: 'Other Error',

				incomplete_line: 'This line has less values than columns.',
				incomplete_line_short: 'Incomplete Line'
			},

			verification: {
				errors_title: '⚠️ We\'ve detected some issues',
				errors_description: 'Please go through them and try again.',
				date_notice: 'Note: Cells that are supposed to have a date but contain a number are still valid dates and will be imported correctly.',

				type_warnings: 'Warnings',
				type_errors: 'Errors',

				type_warnings_description: 'These cells might be ignored.',
				type_errors_description: 'This file cannot be imported until these are resolved.',
			},

			uploaded: {
				title: 'Your file has been uploaded.',
				description: 'It can take up to 12h for processing to finish and your new orders to appear.',
				upload_new_file: 'Upload another file',

				references_description: 'If you encounter any issues, please send us these references (click to select):'
			}
		},

		order_view: {
			actions: {
				view_on_google_maps: 'View on Google Maps',
				view_gallery: 'View Gallery',
				view_protocol_queue: 'View Protocol Queue',
				view_source: 'View Source',

				copy_reference: 'Copy reference'
			},

			housing_association: {
				title: 'Housing Association'
			},

			reject_reason: {
				title: 'Reject Reason'
			},

			tickets: {
				title: 'Links',

				hints: {
					auto_submit: 'Submits itself automatically, either directly or after some time has passed.',
					single_use: 'Can only be used to submit a protocol once.',
					protocols: 'Number of protocols created with this link.'
				},

				actions: {
					copy_link: 'Copy Link',
					create_link: 'Create Link',
					open: 'Open',
					edit_notes: 'Edit Notes',
					send_notifications: 'Send Notifications',
					clear_backups: 'Clear State Backups',
					view_devices: 'View Devices'
				},

				send_notifications: {
					choose_template: '@:order_view.tickets.actions.send_notifications: Choose Template',
					choose_groups: '@:order_view.tickets.actions.send_notifications: Choose Receivers',
					click_template_to_continue: 'Click a template to continue',
					send: 'Send {total} Notifications | Send {total} Notification | Send {total} Notifications',
					sent: 'Notifications have been sent.'
				},

				devices: 'Devices',

				create_testing: 'Testing?',
				notes_placeholder: 'Enter a note, e.g. who has received this link, …',
				notes_saved: 'Notes saved',

				link_created: 'The link has been created.',
				link_deleted: 'The link has been deleted.',
				link_delete_confirm: 'Are you sure you want to delete ticket {ticketID}?\nThe link will stop working and cannot be restored under its original ID.',

				link_backups_cleared: 'The backups for this link have been cleared.',
				link_backups_clear_confirm: 'Are you sure you want to clear the backups for this link?\nAny unsubmitted protocol will be deleted and the UI resetted to the newest version.'
			},

			notifications: {
				title: 'Notifications',

				tabs: {
					sent: 'Sent',
					confirmations: 'Confirmations',
					upcoming: 'Upcoming'
				},

				sender: 'Sender',
				subject: 'Subject',
				template: 'Template',
				params: 'Params'
			},

			protocols: {
				title: 'Protocols',
				new_protocol_incoming: 'A new protocol has just been submitted and will appear here shortly.',

				delete_confirm: 'Are you sure you want to delete this protocol?',

				protocol_deleted: 'Protocol deleted.'
			},

			no_persons: 'No persons.',
			no_notifications_sent: 'No notifications have been sent, yet.',
			no_notifications_to_be_sent: 'No notifications to be sent.',
			no_protocols: 'No protocols have been sent in, yet.',

			order_not_found: 'Could not find order the order you requested.',
			order_not_found_description: 'Check that the client and order ID are correct or try {0}.',

			last_updated: 'Last Updated',
			size: 'Size',
			price_net: 'Price (Net)',
			floor: 'Floor',
			contract_meeting_date: 'Contract Meeting',
			takeover_date: 'Takeover Date',
			portation_date: 'Portation Date',
			cadaster: 'Cadaster',
			proprietary: 'Proprietary',
		},

		order_edit: {
			broker: {
				preset: 'Preset?',
			},

			send_notifications: 'Send Notifications',
			choose_order_type: 'Choose Order Type',
			add_person: 'Add Person',

			testing_warning: 'Editing test orders is not recommended.',

			delete_order_warning: 'Are you sure you want to delete this order? This cannot be undone.',
			order_deleted: 'Order successfully deleted',

			errors: {
				invalid_address: 'Invalid address. Make sure the address, postcode and city are correct.',
				takeover_date_missing: 'Takeover Date missing.',
				no_persons: 'There are no persons.',

				delete_has_protocols: 'This order cannot be deleted because it has protocols.'
			}
		},

		leads_view: {
			overview: {
				search_description: 'Search for specific leads'
			},

			finance: {
				income: 'Income',
				kickback: 'Kickback',
				revenue: 'Revenue'
			},

			actions: {
				view_order: 'View Order'
			},

			tag: {
				[TDL.TAG_REVOKED]: 'Revoked',
				[TDL.TAG_INCOMPLETE]: 'Incomplete',
				[TDL.TAG_PROVIDER_EXTERNAL_ERROR]: 'Provider failed',
				[TDL.TAG_METER_SHARED]: 'Fellesmålt',
				[TDL.TAG_DUPLICATE]: 'Duplicate',
			},

			shortcuts: {
				today: 'Today',
				this_week: 'This Week',
				last_7_days: 'Last 7 Days',
				last_14_days: 'Last 14 Days',
				last_30_days: 'Last 30 Days',
				this_month: 'This Month',
				this_year: 'This Year',
				last_month: 'Last Month',
			}
		},

		protocol: {
			notice: {
				still_processing: 'This protocol is still processing. It may still be missing information. {0}',
				error: 'There was an error while processing this protocol. {0}',
				last_update: 'Last Update: {0}'
			},

			no_documents: 'No documents available.',
			no_persons: 'No persons submitted or the same as in the order.',
			no_meters: 'No meters submitted.',
			no_leads: 'No products have been ordered.',

			tabs: {
				information: 'Information',
				persons: 'Persons',
				meters: 'Meters',
				leads: 'Leads',
				review: 'Review',
				documents: 'Documents',
				stages: 'Progress',
				cost: 'Cost',
				actions: 'Actions'
			},

			actions: {
				view_protocol_queue_entry: 'View Protocol Queue Entry'
			},

			document: {
				copy_link: 'Copy Link'
			},

			meters: {
				types: {
					gas: 'Gas',
					electricity: 'Electricity',
					water: 'Water',
					'warm-water': 'Warm Water',
					'cold-water': 'Cold Water',
					heating: 'Heating'
				},

				type: 'Type',
				mileage: 'Mileage',
				number: 'Number',
				ean: 'EAN (e.g. Nubix MID)',
				images: 'Images'
			}
		},

		search: {
			title: 'Search',
			placeholder: 'What are you looking for?',

			tabs: {
				simple: 'Simple',
				advanced: 'Advanced'
			},

			no_results: 'No results.',
			no_results_generic_description: 'No results found matching your query.',
			no_results_orders_description: 'No orders found matching your query.',
			no_results_protocol_queue_entries_description: 'No queue entries found matching your query.',

			query_not_parseable: 'The query you have entered could not be identified. Please try advanced search.',

			export_as: 'Export as {type}',
		},

		orders_fetch: {
			add_order_ids: 'Add Order IDs',

			add_order_ids_description: 'Enter one or more order IDs or leave empty to fetch all.',

			actions: {
				fetch_all: 'Fetch All Orders',
				fetch: 'Fetch {num} Orders',
				next: 'Next',
				force_updates: 'Force Updates'
			}
		},

		protocol_queue: {
			title: 'Protocol Queue',
			overview: 'Overview',

			columns: {
				id: 'ID',
				order: 'Order',
				status: 'Status'
			},

			actions: {
				select_all: 'Select All',
				deselect_all: 'Deselect All',
				change_status: 'Change Status',
				submit_again: 'Submit again',
				view_order: 'View Order',
				run_again: 'Run again',
			},

			status: {
				0: 'Waiting',
				1: 'Processing',
				2: 'Done',
				error: 'Error'
			},

			edit: {
				title: '#{entry_id}',
				stages: 'Stages',
				entry: 'Entry',
				response: 'Response',
				request: 'Request',
				auto_submit: 'Auto-Submit?',
				scheduled_at: 'Scheduled at',
				created_at: 'Created at',
				delete_confirmation: 'Are you sure you want to delete this queue entry?',
				protocol_id: 'Protocol ID'
			}
		},

		client_overview: {
			actions: 'Actions',
			settings: 'Settings',

			action_all_orders: 'All Orders',
			action_all_orders_description: 'View all orders of this client'
		},

		clients_branding: {
			title: 'Branding',
			description: 'Edit logos and colors',
		},

		clients_notifications: {
			title: 'Notifications',
			description: 'Edit notification sendouts and templates',
			empty_content: 'Choose a routine or template on the left.',

			ticket_type: 'Which protocols?',
			template: 'Template',
			schedules: 'When?',
			receivers: 'Who?',

			timeframe: 'Timeframe (Optional)',
			start_date: 'First Sendout',
			end_date: 'Last Sendout',
			timeframe_swapped: 'First sendout must be before last sendout.',
			swap_dates: 'Swap',

			time_mode: {
				exact: 'Exactly at',
				relative: 'Exactly'
			},

			section_routines: 'Routines',
			section_templates: 'Templates',

			sender: 'Sender',
			sender_placeholder: 'Name or Email object, e.g. "Visma Meglerfront <support@meglerfront.no>", supports variables',
			content: 'Content',
			subject: 'Subject',
			subject_placeholder: 'Subject, supports variables',

			change_template: 'Change',
			choose_template: 'Choose',
			select_template: 'Select Template',
			template_predefined: 'Pre-Defined',

			action_simulate: 'Simulate',

			create_routine: 'Create Routine…',
			create_template: 'Create Template…',

			simulation_title: 'Simulation',
			simulation_description_key_date: 'Suppose, the takeover date was at:',
			simulation_description_reference_date: 'and today was:',
			simulation_description_notifications: '…then we\'d send these notifications:',

			notification_type: 'Medium',
			sendgrid_params: 'SendGrid: Parameters',
			sendgrid_template_id: 'SendGrid: Template ID',

			notification_types: {
				sms: 'SMS',
				sendgrid: 'Email',
				email: 'Email',

				MailSG: 'Email',
				SMS: 'SMS'
			},

			errors: {
				no_ticket_type: 'No protocol type selected.',
				no_template: 'At least one schedule has no template selected.',
				invalid_time: 'The time entered in at least one schedule is invalid. Make sure it is formatted like HH:mm, e.g. 07:45.',
				no_title: 'Title is missing.',
				no_notification_type: 'No @:clients_notifications.notification_type selected.',
				no_sender: '@:clients_notifications.sender is missing.',
				no_content: '@:clients_notifications.content is missing.',
				no_sendgrid_template: '@:clients_notifications.sendgrid_template_id is missing.',
				no_subject: '@:clients_notifications.subject is missing.',
			},

			editor: {
				variables_title: 'Variables',

				variables: {
					address: {
						address: 'Street + House Nr',
						postcode: 'Postcode',
						city: 'City'
					},

					order: {
						takeover_date: 'Takeover Date',
						contract_meeting_date: 'Contract Meeting Date',
						link: 'Link'
					},

					broker: {
						name: 'Name',
						phone: 'Phone',
						email: 'Email',
					},

					receiver: {
						fullname: 'Full Name'
					},

					client: {
						title: 'Title'
					}
				},

				sections: {
					address: 'Address',
					broker: 'Broker',
					receiver: 'Receiver'
				}
			},

			delete_routine_confirmation: 'Are you sure you want to delete this routine?',
			routine_deleted: 'Routine deleted.',
			routine_saved: 'Routine saved.',

			delete_template_confirmation: 'Are you sure you want to delete this template?',
			template_deleted: 'Template deleted.',
			template_saved: 'Template saved.'
		},

		clients_ticket_types: {
			title: 'Protocol Types',
			description: 'Control which types of protocol are available for a client.',

			enabled_types: 'Enabled',
			available_types: 'Available',
			new_types: 'New',

			removal_info: 'Disabling a protocol type does not disable or remove existing links of that type.',
			no_enabled_types: 'There are no protocol types enabled for this client.',
			changes_saved: 'All changes have been saved'
		},

		statistics: {

		},

		query_builder: {
			and: 'AND',
			or: 'OR',

			add_rule: 'Rule',
			add_group: 'Group',

			shortcuts: 'Shortcuts',
			results: '{0} Results',

			search: 'Search',

			equal: "equal",
			not_equal: "not equal",
			in: "in",
			not_in: "not in",
			less: "less",
			less_or_equal: "less or equal",
			greater: "greater",
			greater_or_equal: "greater or equal",
			between: "between",
			not_between: "not between",
			begins_with: "begins with",
			not_begins_with: "doesn't begin with",
			contains: "contains",
			not_contains: "doesn't contain",
			ends_with: "ends with",
			not_ends_with: "doesn't end with",
			is_empty: "is empty",
			is_not_empty: "is not empty",
			is_null: "is null",
			is_not_null: "is not null"
		},

		order_types: {
			estate: 'Estate',
			estate_description: 'Estate Acquisitions, New Construction, Leads, …',

			phone_number_handover: 'Phone Number Handover'
		},

		protocol_types: {
			estate_acquisition: 'Acquisition Protocol',
			estate_acquisition_commercial: 'Acquisition Commercial',
			estate_electricity: 'Digital electricity',
			estate_electricity_seller: 'Electricity for Seller',
			estate_electricity_buyer: 'Electricity for Buyer',
			estate_rental_commercial_moving_in: 'Rental Commercial In',
			estate_rental_commercial_moving_out: 'Rental Commercial Out',
			estate_moving: 'Moving',
			estate_leads: 'Deals',
			estate_single_product_leads: 'Deals (Single Product)',
			estate_new_construction: 'New Contruction Protocol',
			estate_rental_moving_in: 'Rental: Moving In',
			estate_rental_moving_out: 'Rental: Moving Out',
			estate_exchange: 'Estate Exchange (Boligbytte)',
			estate_forced_sale: 'Forced Sale (Tvangssalg)',
			estate_forced_sale_before_expiry: 'Forced Sale Before Expiry (Tvangssalg før ankefristens utløp)',
			estate_chairman_housing_association: 'Chairman Housing Association',
			estate_empty: 'Land Plot',
			api_leads: 'API Leads [no UI]',
			visitor_leads: 'Strømbestilling [external UI]',
			estate_meglerfront: 'WebOT Takeover',
			estate_settlement_seller: 'Settlement for Seller',
			estate_settlement_buyer: 'Settlement for Buyer (Domestic)',
			estate_settlement_buyer_commercial: 'Settlement for Buyer (Commercial)',
			estate_politically_exposed_person: 'Politically Exposed Person',
			estate_customer_information_seller: 'Customer Information for Seller',
			estate_customer_information_buyer: 'Customer Information for Buyer',
			estate_customer_declaration_commercial: 'Customer Declaration (Commercial)',

			estate_myrent_in_default: 'MyRent: Moving in (Default)',
			estate_myrent_in_no_electricity: 'MyRent: Moving In (No Electricity)',
			estate_myrent_in_with_water: 'MyRent: Moving In (With Water)',

			phone_handover_confirmation: 'Confirmation (Scenario 1)',
			phone_handover_addition: 'Addition (Scenario 2)',
			phone_handover_reparation: 'Reparation (Scenario 3)',
		},

		protocol_stages: {
			processing: 'Data Processing',
			protocol_pdf: 'PDF Creation',
			register_products: 'Leads Submission',
			submit_electricity_pdf: 'Send leads to Meglerfront',
			leads_email: 'Send leads via email to provider',
			notifications: 'Notifications',
			recycle_tickets: 'Update Links',
			update_crm: 'Update CRM'
		},

		product_entry_status: {
			[TDL.STATUS_ERROR]: 'Error',
			[TDL.STATUS_WAITING]: 'Waiting',
			[TDL.STATUS_PROCESSING]: 'Processing',
			[TDL.STATUS_DONE]: 'Done',
			[TDL.STATUS_NO_INTEGRATION]: 'No integration',

			[TDL.ERROR_BIX_DATA_UNVERIFIED]: 'EAN unverified',
			[TDL.ERROR_BIX_DATA_NOT_FOUND]: 'EAN not found',
			[TDL.ERROR_BIX_NOT_REACHABLE]: 'BIX not reachable',
			[TDL.ERROR_BIX_TIMEOUT]: 'BIX timeout',
			[TDL.ERROR_INVALID_BIRTHDATE]: 'Invalid birthdate',
			[TDL.ERROR_INVALID_EMAIL]: 'Invalid email',
			[TDL.ERROR_INVALID_PHONE]: 'Invalid phone',
			[TDL.ERROR_INVALID_NAME]: 'Invalid name',
			[TDL.ERROR_INVALID_PERSON_NUMBER]: 'Invalid person number',
			[TDL.ERROR_INVALID_TYPE]: 'Invalid type'
		},

		reports: {
			conversion_overview: 'Conversion Overview',
			view_order: 'View Order',

			attributes: {
				client: 'Client',
				takeover_date: 'Takeover Date',
				submission_date: 'Submisson Date',
				porting_date: 'Porting Date',
				leads_date: 'Date',
				protocol_type: 'Protocol Type',
				order_id: 'Order ID',
				office_id: 'Office ID',
				order_type: 'Order Type',
				address: 'Address',
				phone_number: 'Phone Number',
				provider: 'Provider',
				electricity_provider: 'Electricity Provider',
				security_provider: 'Security Provider',
				insurance_provider: 'Insurance Provider',
				gas_provider: 'Gas Provider',
				old_provider: 'Old Provider',     //for Phone numbers
				meter_id: 'Meter ID',
				meter_ean: 'Meter EAN',
				meter_number: 'Meter Number',
				cadaster: 'Cadaster',
				buyer: 'Buyer',
				seller: 'Seller',
				identification: 'Identification',
				status: 'Status',
				dashboard: 'Dashboard',
				acquisit: 'Acquisit'
			}
		},

		countries: {
			no: 'Norway',
			dk: 'Denmark',
			se: 'Sweden',
			de: 'Germany'
		},

		tdl: {
			title: 'Issues',

			types: {
				ean_missing: 'EAN missing',
				invalid_person_data: 'Invalid Person Data',
				marked_failed: 'Marked Failed',
				other: 'Other'
			},

			descriptions: {
				ean_missing: 'Add missing EANs',
				invalid_person_data: 'Correct person numbers or contact data',
				marked_failed: 'Show entries manually marked as failed',
				other: 'Other issues to be handled by tech staff'
			},

			errors: {
				type_not_found: 'Could not find type.',

				other_error_title: 'Other Error',
				other_error_description: 'Please contact IT to learn more about this submission.'
			},

			successes: {
				saved: 'Changes saved. The entry will be resubmitted in the next 1-2 minutes.',
				deleted: 'The entry has been deleted.',
				status_updated: 'Status updated.'
			},

			questions: {
				delete_entry: 'Are you sure you want to delete this entry?'
			},

			waiting_title: 'Waiting for Submission…',
			waiting_description: 'This submission is preparing. Please wait a few minutes.',

			processing_title: 'Processing…',
			processing_description: 'This submission is processing right now. Please wait a few seconds.',

			no_entries_title: 'Nothing left',
			no_entries_description: 'There are no entries to fix in this category  🎉',

			show_logs: 'Show Logs',
			show_images: 'Images',
			add_person: 'Add Person'
		},

		logs: {
			title: 'Logs',

			actions: {
				crm_request: 'Request to Provider',
				crm_response: 'Response from Provider',
				request: 'External Request',
				create: 'Created',
				edit: 'Edited',
				edit_status: 'Status changed'
			}
		},

		help: {
			title: 'Help',

			overview: helpOverview,
			orders_upload: helpOrdersUpload
		}
	}
}
