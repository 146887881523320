var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    class: ["svg", { colored: !_vm.multiColor }],
    style: _vm.style,
    domProps: { innerHTML: _vm._s(_vm.svg) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }