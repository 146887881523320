<template>
    <time :timestamp="timestamp" :title="relative ? formatted : relative_formatted" :class="['v-date', 'hint', 'hint--' + hintDirection]" :data-hint="relative ? formatted : relative_formatted">
		{{ relative ? relative_formatted : formatted }}
	</time>
</template>

<script>
	import DateTimeExt from 'lib/DateTimeExt'

    export default {
		props: {
			timestamp: {
				type: [String, Number],
				required: true
			},

			format: {
				type: String,
				required: false,
				default: 'DD.MM.YYYY · HH:mm'
			},

			relative: {
				type: Boolean,
				required: false,
				default: false
			},
			
			hintDirection: {
				type: String,
				required: false,
				default: 'right'
			}
		},

		computed: {
			date_object() {
				try {
					return DateTimeExt.fromTimestamp(this.timestamp)
				} catch (e) {
					this.$_debug('Invalid timestamp:', this.timestamp)
					return null
				}
			},
			
			formatted() {
				if (!this.date_object) {
					return '–'
				}
				
				return this.date_object.format(this.format)
			},

			relative_formatted() {
				if (!this.date_object) {
					return '–'
				}
				
				return this.date_object.fromNow(true)
			}
		}
    }
</script>

<style lang="scss">
	
	.v-date {
		display: inline;
	}
</style>