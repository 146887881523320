"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Tags_1 = require("./Tags");
/**
 * Permission
 * An access permission with optional modifier info
 *
 * @package as.adepto.sweet-acp.lib
 * @author suushie_maniac (translated by bluefirex)
 * @version 1.0
 */
class Permission {
    constructor(name, parent, info) {
        this.name = name;
        this.parent = parent;
        this.info = new Tags_1.default(info.sort());
    }
    /**
     * Parses a permission object from the given $perm string.
     * Strings have to be in a reverse domain pattern, i.e. adepto.acp.acq.some.permission
     * NULL is returned if the permission string is empty or has an invalid format
     *
     * @param  {string}     perm The permission to parse
     * @param  {any[]}      info The info to initiate the permission with, if any
     *
     * @return {Permission}      The parsed permission or NULL on failure
     */
    static fromString(perm, info = []) {
        if (perm.length <= 0 || !perm.match(Permission.PREG_PATTERN) || perm.indexOf(Permission.WILDCARD) === 0) {
            return null;
        }
        let matches = perm.match(Permission.PREG_PATTERN);
        if (!matches) {
            return null;
        }
        let root = matches[1];
        let name = matches[4];
        return new Permission(name, Permission.fromString(root.substring(0, root.length - 1)), info);
    }
    static fromJSON(json) {
        return Permission.fromString(json.p || json.permission, json.i || json.info);
    }
    /**
     * Get the name of the current sub-permission
     *
     * @return {string}
     */
    getOwnName() {
        return this.name;
    }
    getName() {
        let baseName = '';
        if (this.parent) {
            baseName = this.parent.getName() + '.';
        }
        return baseName + this.getOwnName();
    }
    getParent(withInfo = false) {
        let parent = this.parent;
        if (withInfo && parent) {
            parent.addAllInfo(this.getInfo());
        }
        return parent;
    }
    getInfo() {
        return this.info.getUnique();
    }
    hasAllInfo(info) {
        if (!this.info.length)
            return true;
        for (let i of info) {
            if (!this.info.has(i)) {
                return false;
            }
        }
        return true;
    }
    hasAnyInfo(info) {
        if (!this.info.length)
            return true;
        for (let i of info) {
            if (this.info.has(i)) {
                return true;
            }
        }
        return false;
    }
    countInfo() {
        return this.info.length;
    }
    hasInfo(info) {
        return this.getInfo().indexOf(info) > -1;
    }
    pullInfo(info) {
        return this.info.pull(info);
    }
    setInfo(info, unique = true) {
        this.info = new Tags_1.default(info, unique);
        return this;
    }
    addInfo(info, unique = true) {
        this.info.add(info, !unique);
        return this;
    }
    addAllInfo(info, unique = true) {
        for (let pos in info) {
            let infoItem = info[pos];
            this.addInfo(infoItem, unique);
        }
        return this;
    }
    removeInfo(info, unique = false) {
        this.info.remove(info, false, unique);
        return this;
    }
    equals(perm = null, withInfo = true) {
        if (perm === null || this.depth() != perm.depth()) {
            return false;
        }
        let equals = this.getOwnName() == perm.getOwnName();
        if (withInfo) {
            equals = equals && perm.hasAllInfo(this.getInfo()) && this.hasAllInfo(perm.getInfo());
        }
        if (this.getParent() && perm.getParent()) {
            equals = equals && this.getParent(withInfo).equals(perm.getParent(withInfo), false);
        }
        return equals;
    }
    matches(perm = null, withInfo = true) {
        if (perm === null || this.depth() != perm.depth()) {
            return false;
        }
        let equals = this.getOwnName() == perm.getOwnName() || this.getOwnName() == Permission.WILDCARD || perm.getOwnName() == Permission.WILDCARD;
        if (withInfo) {
            equals = equals && perm.hasAllInfo(this.getInfo()) && this.hasAllInfo(perm.getInfo());
        }
        if (this.getParent() && perm.getParent()) {
            equals = equals && this.getParent(withInfo).matches(perm.getParent(withInfo), false);
        }
        return equals;
    }
    equalsOrChildOf(perm, withInfo = true) {
        let equals = perm.equals(this, withInfo);
        let thisParent = this.getParent();
        while (thisParent) {
            equals = equals || perm.equals(thisParent, withInfo);
            thisParent = thisParent.getParent();
        }
        return equals;
    }
    equalsOrParentOf(perm, withInfo) {
        return perm.equalsOrChildOf(this);
    }
    depth() {
        let depth = 1;
        let parent = this.getParent();
        while (parent) {
            depth++;
            parent = parent.getParent();
        }
        return depth;
    }
    allows(perm = null, withInfo = true) {
        if (!this.getOwnName() && !this.getParent()) {
            return false;
        }
        if (perm === null) {
            return false;
        }
        let allows = this.matches(perm, false);
        if (withInfo) {
            let infoGood = !this.getInfo() || (this.hasAllInfo(perm.getInfo()) && perm.countInfo() > 0);
            allows = allows && infoGood;
        }
        return allows || this.allows(perm.getParent(true), withInfo);
    }
    toString() {
        return this.getName();
    }
    toJSON() {
        return {
            p: this.getName(),
            i: this.getInfo()
        };
    }
}
exports.default = Permission;
Permission.PREG_PATTERN = /^((([a-zA-Z\-]+|\*)\.)*)([a-zA-Z\-]+|\*)$/;
Permission.WILDCARD = '*';
