import * as r from 'routes/_functions'

const appearance = 'indigo'

export default [
	{
		path: '/help',
		component: r.loadLayout('Basic'),
		redirect: '/help/overview',
		name: 'help',

		children: [
			{
				name: 'help-overview',
				path: 'overview',

				components: {
					default: r.loadView('asgard/help/Overview')
				},
				
				props: {
					default: {
						appearance
					}
				}
			},
			
			{
				name: 'help-topic',
				path: 'topic/:topic',
				
				components: {
					default: r.loadView('asgard/help/Topic')
				},
				
				props: {
					default: {
						appearance
					}
				}
			}
		]
	}
]