import Velocity from 'velocity-animate'

export default {
	install(Vue, options) {
		Vue.component('sweet-transition-list-staggered', {
			functional: true,

			props: {
				stagger: {
					type: [String, Number],
					required: false,
					default: 0
				},

				tag: {
					type: String,
					required: false,
					default: 'div'
				}
			},

			render(h, context) {
				let cl

				if (Array.isArray(context.data.class)) {
					cl = context.data.class
				} else {
					cl = {
						[context.data.class || context.data.staticClass || 'no-class']: true
					}
				}

				return h('transition-group', {
					props: {
						name: 'sweet-transition-list-staggered',
						tag: context.props.tag
					},

					class: cl,

					on: {
						beforeEnter(el) {
							el.style.opacity = 0
							el.style.transform = 'scale(0.8) translateY(120px)'
						},

						enter(el, done) {
							let delay = el.dataset.index * Number(context.props.stagger)

							setTimeout(() => {
								Velocity(el, {
									opacity: 1,
									scale: 1,
									translateY: 0
								}, {
									complete: done,
									easing: 'easeInQuad',
									duration: 100
								})
							}, delay)
						},

						leave(el, done) {
							let delay = /*l.dataset.index * 100*/ 0

							setTimeout(() => {
								Velocity(el, {
									opacity: 0,
									scale: 0.8,
									translateY: '120px'
								}, {
									complete: done,
									duration: 0
								})
							}, delay)
						}
					}
				}, context.children)
			}
		})
	}
}