import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Functions from 'lib/Functions'

// Register with Vue
Vue.use(VueI18n)

// Load Locales
import locale_en from 'locales/en'
import locale_nb from 'locales/nb'

let availableLocaleMessages = {
	'en-US': locale_en,
	'no-NB': locale_nb,
	'no-NY': locale_nb,
	'nb': locale_nb
}

// Save overwrite locale, if available
let query = Functions.parseQueryString(window.location.search.substr(1) || '')

if (query.hl && Object.keys(availableLocaleMessages).indexOf(query.hl) > -1) {
	localStorage.setItem('locale', query.hl)
}

// Detect current locale
let navigatorLocales = navigator.languages
let locale
let savedLocale = localStorage.getItem('locale')

if (savedLocale && Object.keys(availableLocaleMessages).indexOf(savedLocale) > -1) {
	locale = availableLocaleMessages[savedLocale]
} else {
	for (let lang of navigatorLocales) {
		if (availableLocaleMessages[lang] !== undefined) {
			locale = availableLocaleMessages[lang]
			break
		}
	}
}

if (locale === undefined) {
	locale = availableLocaleMessages['en-US']
}

// Re-format locales
let availableMessages = {}

for (let key in availableLocaleMessages) {
	let l = availableLocaleMessages[key]
	availableMessages[l.locale] = l.messages
}

const vueI18n = new VueI18n({
	locale: locale.locale,
	fallbackLocale: availableLocaleMessages['en-US'].locale,
	silentTranslationWarn: true,
	messages: availableMessages
})

window.locale = locale.locale

// Hot Reload locales, if available
if (module.hot) {
	module.hot.accept(['locales/en', 'locales/nb'], function() {
		i18n.setLocaleMessage('en', require('locales/en').default.messages)
		i18n.setLocaleMessage('nb', require('locales/nb').default.messages)
	})
}

export const availableLocales = [
	'en-US',
	'no-NB'
]

// Export
export const i18n = vueI18n
