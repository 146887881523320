"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const _ = require("underscore");
/**
 * Tags
 * A basic class for accumulating any kind of tags.
 *
 * @package as.adepto.sweet-acp.lib
 * @author suushie_maniac (translated by bluefirex)
 * @version 1.0
 */
class Tags {
    /**
     * Constructor
     *
     * @param  {any[]}            tags  Tags to set
     * @param  {boolean = false}  unique If true duplicates will be removed
     */
    constructor(tags, unique = false) {
        this.tags = tags;
        if (unique) {
            this.tags = _.uniq(tags, true);
        }
    }
    /**
     * Get all currently set tags
     *
     * @return {any[]}
     */
    getAll() {
        return this.tags;
    }
    /**
     * Get all currently set tags without duplicates.
     *
     * @return {any[]}
     */
    getUnique() {
        return _.uniq(this.getAll(), true);
    }
    /**
     * Get the number of items in the list of tags.
     *
     * @return {number}
     */
    get length() {
        return this.tags.length;
    }
    /**
     * Add a new tag to the list of tags
     *
     * @param {any}      tag       The tag to add
     * @param {boolean}  forceAdd  Whether to ignore duplicates and even set the tag if already included. Defaults to false.
     *
     * @return {Tags}              self-reference for chained calls
     */
    add(tag, forceAdd = false) {
        if (forceAdd || !this.has(tag)) {
            this.tags.push(tag);
        }
        return this;
    }
    /**
     * Remove a tag, if set.
     * If the given $tag is not set, this really does not do anything else than stealing some runtime.
     *
     * @param {any}      tag          The tag to remove
     * @param {boolean}  strictMatch  If set to true, only removes on identity (===) match, else also removes on equality (==). Defaults to false.
     * @param {boolean}  onceOnly     Whether to stop after the first occurence of $tag has been removed
     *
     * @return {Tags}                 self-reference for chained calls
     */
    remove(tag, strictMatch = false, onceOnly = false) {
        let removals = [];
        for (let pos in this.tags) {
            let oldTag = this.tags[pos];
            let matches = strictMatch ? (oldTag === tag) : (oldTag == tag);
            if (matches) {
                removals.push(parseInt(pos));
            }
        }
        for (let pos in removals) {
            let removal = removals[pos];
            this.tags.splice(removal, 1);
            if (onceOnly) {
                break;
            }
        }
        return this;
    }
    /**
     * Remove all tags completely.
     * Use with caution, this call cannot be undone!
     *
     * @return {Tags} self-reference for chained calls
     */
    clear() {
        this.tags = [];
        return this;
    }
    /**
     * Checks whether the given $tag is set in this list of tags at least once.
     *
     * @param {any}      tag          The tag to check for
     * @param {boolean}  strictMatch  If set to true, only matches by identity (===), else also matches by equality (==). Defaults to false.
     *
     * @return {boolean}              Whether the given $tag is found at least once
     */
    has(tag, strictMatch = false) {
        for (let pos in this.tags) {
            let oldTag = this.tags[pos];
            let matches = strictMatch ? (oldTag === tag) : (oldTag == tag);
            if (matches) {
                return true;
            }
        }
        return false;
    }
    /**
     * Counts how many times the given $tag is listed in this tags instance
     *
     * @param {any}      tag          The tag to be counted
     * @param {boolean}  strictMatch  If set to true, only matches by identity (===), else also matches by equality (==). Defaults to false.
     *
     * @return {number}               The amount of how many times the flag is included
     */
    count(tag, strictMatch = false) {
        let count = 0;
        for (let pos in this.tags) {
            let oldTag = this.tags[pos];
            let matches = strictMatch ? (oldTag === tag) : (oldTag == tag);
            if (matches) {
                count++;
            }
        }
        return count;
    }
    /**
     * Checks whether the given $tag is set in this list of tags at least once.
     * In addition to $this->has, it also removes the tag if set
     *
     * @param {any}      tag          The tag to check for
     * @param {boolean}  strictMatch  If set to true, only matches by identity (===), else also matches by equality (==). Defaults to false.
     * @param {boolean}  onceOnly     How to handle removal if necessary. See {@see Tags.remove} for further details
     *
     * @return {boolean}              Whether the given $tag was included at least once
     */
    pull(tag, strictMatch = false, onceOnly = false) {
        if (!this.has(tag, strictMatch)) {
            return false;
        }
        this.remove(tag, strictMatch, onceOnly);
        return true;
    }
    /**
     * Control the state of the given $tag
     *
     * @param {any}      tag             The tag to control the state of
     * @param {boolean}  isAdd           Whether to add or remove $flag
     * @param {boolean}  forceMode       How to handle adding if necessary. See @see{$this->add} for further details
     * @param {boolean}  strictMatch     How to handle removal if necessary. See @see{$this->remove} for further details
     * @param {boolean}  removeOnceOnly  How to handle removal if necessary. See @see{$this->remove} for further details
     *
     * @return Tags                      self-reference for chained calls
     */
    set(tag, isAdd = true, forceMode = false, strictMatch = false, removeOnceOnly = false) {
        return isAdd ? this.add(tag, forceMode) : this.remove(tag, strictMatch, removeOnceOnly);
    }
    /**
     * An intelligent alias for $this->set
     * Sets the given $tag if it's currently not set, or removes it if set.
     *
     * @param {any}      tag          The tag to toggle the state of
     * @param {boolean}  strictMatch  If set to true, only determines the current $tag state by identity (===), else also determines by equality (==). Defaults to false.
     * @param {boolean}  forceMode    How to handle adding if necessary. See {@see Tags.set} for further details
     *
     * @return Tags                   self-reference for chained calls
     */
    toggle(tag, strictMatch = false, forceMode = false) {
        return this.set(tag, !this.has(tag, strictMatch), forceMode);
    }
    toJSON() {
        return this.tags;
    }
}
exports.default = Tags;
