"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Permissions {
}
exports.default = Permissions;
Permissions.SYSTEM = 'system';
Permissions.USERS = 'users';
Permissions.USERS_AUTH = 'users.auth';
Permissions.DEVELOPER = 'dev';
Permissions.INFRASTRUCTURE = 'infrastructure';
Permissions.APPS = 'apps';
Permissions.META_RESTRICTION = 'meta.restriction';
Permissions.META_MIDGARD_CLIENTS = 'midgard.clients';
Permissions.META_DEVIL = 'devil';
Permissions.ELHUB = 'elhub';
Permissions.MIDGARD = 'midgard';
Permissions.DEVIL = 'devil';
Permissions.META_OTP = 'otp';
Permissions.ORDERS = 'orders';
Permissions.ORDERS_VIEW = 'orders.view';
Permissions.ORDERS_EDIT = 'orders.edit';
Permissions.ORDERS_VIEWS = 'orders.views';
Permissions.ORDERS_SEARCH = 'orders.search';
Permissions.ORDERS_FETCH = 'orders.fetch';
Permissions.ORDERS_LOGS = 'orders.logs';
Permissions.ORDERS_SEND_NOTIFICATIONS = 'orders.notifications.send';
Permissions.CERBERUS = 'cerberus';
Permissions.CERBERUS_UPLOAD = 'cerberus.upload';
Permissions.CLIENTS = 'clients';
Permissions.CLIENTS_CREATE = 'clients.create';
Permissions.CLIENTS_EDIT = 'clients.edit';
Permissions.CLIENTS_EDIT_NOTIFICATIONS = 'clients.edit.notifications';
Permissions.CLIENTS_EDIT_BRANDING = 'clients.edit.branding';
Permissions.CLIENTS_EDIT_TICKET_TYPES = 'clients.edit.ticket-types';
Permissions.CLIENTS_VIEW = 'clients.view';
Permissions.CLIENTS_BLACKLIST = 'clients.blacklist';
Permissions.CLIENTS_OFFICES = 'clients.offices';
Permissions.CLIENTS_OFFICES_EDIT = 'clients.offices.edit';
Permissions.CLIENTS_OFFICES_VIEW = 'clients.offices.view';
Permissions.LEADS = 'leads';
Permissions.LEADS_EDIT = 'leads.edit';
Permissions.LEADS_SEARCH = 'leads.search';
Permissions.LEADS_VIEWS = 'leads.views';
Permissions.LEADS_TDL = 'leads.tdl';
Permissions.LEADS_PROVIDERS = 'leads.providers';
Permissions.LEADS_PROVIDERS_VIEW = 'leads.providers.view';
Permissions.LEADS_PROVIDERS_EDIT = 'leads.providers.edit';
Permissions.FINANCE = 'finance';
Permissions.FINANCE_INVOICES = 'finance.invoices';
Permissions.FINANCE_REPORTS = 'finance.reports';
Permissions.STATISTICS = 'statistics';
Permissions.REPORTS = 'statistics.reports';
Permissions.PROTOCOL_QUEUE = 'protocol-queue';
Permissions.PROTOCOL_QUEUE_VIEW = 'protocol-queue.view';
Permissions.PROTOCOL_QUEUE_EDIT = 'protocol-queue.edit';
Permissions.PROTOCOLS_VIEW_LOGS = 'protocols.logs';
Permissions.INFRASTRUCTURE_EC2 = 'infrastructure.ec2';
Permissions.INFRASTRUCTURE_VPC = 'infrastructure.vpc';
Permissions.INFRASTRUCTURE_VPN = 'infrastructure.vpn';
Permissions.INFRASTRUCTURE_S3 = 'infrastructure.s3';
Permissions.INFRASTRUCTURE_SENDGRID = 'infrastructure.sendgrid';
Permissions.INFRASTRUCTURE_SMS = 'infrastructure.sms';
Permissions.SYSTEM_BACKUPS = 'system.backups';
Permissions.DEVELOPER_GIT = 'dev.git';
Permissions.OTP_SMS = 'otp.sms';
Permissions.ISSUES = 'issues';
Permissions.ISSUES_REPORT = 'issues.report';
Permissions.EASTEREGGS_CAT_GIFS = 'eastereggs.cat-gifs';
