<template>
    <div :class="['sweet-planet-spinner', 'color-' + color, 'size-' + size]">
		<div class="plane main">
			<div class="circle" v-for="i in 5"></div>
		</div>
	</div>
</template>

<script>
    export default {
		props: {
			size: {
				type: String,
				validValues: ['large', 'small', 'normal'],
				required: false,
				default: 'normal'
			},

			color: {
				type: String,
				required: false,
				default: 'blue'
			}
		}
    }
</script>

<style lang="scss" scoped>
	@import "~styles/_mixins";
	@import "~styles/_colors";

	.sweet-planet-spinner {
		$size: 64px;
		$length: $size / 1.5;

		position: relative;
		perspective: 400;

		padding: {
			top: 48px;
			bottom: 48px;
		}

		@mixin center {
			position: absolute;
			top: 50%;
			left: 50%;
			margin: auto;
		}

		.plane {
			width: $size;
			height: $size;
			transform-style: preserve-3d;

			margin: auto;

			&.main {
				transform: rotateX(60deg) rotateZ(-30deg);
				animation: rotate 20s infinite linear;

				.circle {
					width: $size;
					height: $size;

					position: absolute;
					transform-style: preserve-3d;
					border-radius: 100%;
					box-sizing: border-box;
					box-shadow: 0px 0px 6px rgba(color(blue), 1),
					            inset 0 0 50px rgba(color(blue), 1);

					&::before,
					&::after {
						content: '';
						display: block;

						width: 10%;
						height: 10%;
						border-radius: 100%;
						box-sizing: border-box;

						background: rgba(color(blue), 1);
						box-shadow: 0px 0px 54px 2px rgba(color(blue), 1);

						@include center;
					}

					&::before {
						transform: translateZ(-$length);
					}

					&::after {
						transform: translateZ($length);
					}

					@for $i from 1 through 5 {
						&:nth-child(#{$i}) {
							transform: rotateZ($i / 5 * 360deg) rotateX(64.435deg);
						}
					}
				}
			}
		}

		$sizes: (
			small: 40px,
			bigger: 84px,
			large: 120px
		);

		@each $name, $size in $sizes {
			$length: $size / 1.5;

			&.size-#{$name} {

				.plane {
					width: $size;
					height: $size;

					&.main .circle {
						width: $size;
						height: $size;

						&::before {
							transform: translateZ(-$length);
						}

						&::after {
							transform: translateZ($length);
						}
					}
				}
			}
		}

		&.size-small {

			.plane .circle {
				box-shadow: 0px 0px 4px rgba(color(blue), 1),
							inset 0 0 16px rgba(color(blue), 1);
			}
		}

		@each $name, $color in getGenericColors() {
			&.color-#{$name} {

				.plane {

					.circle {
						box-shadow: 0px 0px 6px rgba($color, 1),
						            inset 0 0 50px rgba($color, 1);

						&::after, &::before {
							background: rgba($color, 1);
							box-shadow: 0px 0px 60px 2px rgba($color, 1);
						}
					}
				}
			}

			&.size-small.color-#{$name} {

				.plane .circle {
					box-shadow: 0px 0px 4px rgba($color, 1),
								inset 0 0 24px rgba($color, 1);
				}
			}
		}
	}

	@keyframes rotate {
		0% {
			transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
		}

		100% {
			transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
		}
	}
</style>
