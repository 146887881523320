import Permissions from 'lib/Permissions'
import * as r from 'routes/_functions'

const SystemSidebar = r.loadView('asgard/system/Sidebar')
const appearance = 'light-grey-blue'

export default [
	{
		path: '/system',
		component: r.loadLayout('Basic'),
		
		meta: {
			permissions: [
				Permissions.SYSTEM,
				Permissions.SYSTEM + '.*',
				Permissions.USERS,
				Permissions.USERS + '.*',
				Permissions.INFRASTRUCTURE,
				Permissions.PROTOCOL_QUEUE,
				Permissions.PROTOCOL_QUEUE + '.*',
				Permissions.DEVELOPER,
				Permissions.DEVELOPER + '.*',
			]
		},

		children: [
			{
				name: 'system',
				path: '/system',
				redirect: '/system/overview'
			},

			{
				name: 'system-overview',
				path: 'overview',

				components: {
					default: r.loadView('asgard/system/Overview'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				}
			},

			/*
				PROTOCOL QUEUE
			 */
			
			{
				name: 'system-protocol-queue-overview',
				path: 'protocol-queue',

				components: {
					default: r.loadView('asgard/system/protocol-queue/Overview'),
					sidebar: SystemSidebar
				},

				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},

				meta: {
					permissions: [
						Permissions.PROTOCOL_QUEUE_VIEW
					]
				}
			},

				{
					name: 'system-protocol-queue-edit',
					path: 'protocol-queue/:entryID(\\d+)',

					components: {
						default: r.loadView('asgard/system/protocol-queue/Edit'),
						sidebar: SystemSidebar
					},

					props: {
						default: (route) => ({
							entryID: route.params.entryID,
							appearance
						}),
						
						sidebar: {
							appearance
						}
					},

					meta: {
						permissions: [
							Permissions.PROTOCOL_QUEUE_EDIT
						]
					}
				},

			/*
				USERS
			 */

			{
				name: 'system-users-manage',
				path: 'users',

				components: {
					default: r.loadView('asgard/system/users/Manage'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},

				meta: {
					permissions: [
						Permissions.USERS
					]
				}
			},

				{
					name: 'system-users-edit-by-user-id',
					path: 'users/:userID(\\d+)',
					
					props: {
						default: (route) => ({
							userID: route.params.userID,
							appearance
						}),
						
						sidebar: {
							appearance
						}
					},

					components: {
						default: r.loadView('asgard/system/users/Edit'),
						sidebar: SystemSidebar
					},

					meta: {
						permissions: [
							Permissions.USERS
						]
					}
				},

				{
					name: 'system-users-edit-by-username',
					path: 'users/:username([a-zA-Z0-9]+)',

					components: {
						default: r.loadView('asgard/system/users/Edit'),
						sidebar: SystemSidebar
					},
					
					props: {
						default: (route) => ({
							username: route.params.username,
							appearance
						}),
						
						sidebar: {
							appearance
						}
					},

					meta: {
						permissions: [
							Permissions.USERS
						]
					}
				},

				{
					name: 'system-users-create',
					path: 'users/+create',

					components: {
						default: r.loadView('asgard/system/users/Edit'),
						sidebar: SystemSidebar
					},
					
					props: {
						default: {
							appearance
						},
						
						sidebar: {
							appearance
						}
					},

					meta: {
						create: true,

						permissions: [
							Permissions.USERS
						]
					}
				},

				{
					name: 'system-api-clients-manage',
					path: 'api-clients',
					
					props: {
						default: {
							appearance
						},
						
						sidebar: {
							appearance
						}
					},

					components: {
						default: r.loadView('asgard/system/users/APIClients'),
						sidebar: SystemSidebar
					},

					meta: {
						permissions: [
							Permissions.USERS
						]
					}
				},

			/* GROUPS */

			{
				name: 'system-groups-manage',
				path: 'groups',

				components: {
					default: r.loadView('asgard/system/groups/Manage'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},

				meta: {
					permissions: [
						Permissions.USERS
					]
				}
			},

				{
					name: 'system-groups-edit',
					path: 'groups/:groupID(\\d+)',

					components: {
						default: r.loadView('asgard/system/groups/Edit'),
						sidebar: SystemSidebar
					},
					
					props: {
						default: (route) => ({
							groupID: route.params.groupID,
							appearance
						}),
						
						sidebar: {
							appearance
						}
					},

					meta: {
						permissions: [
							Permissions.USERS
						]
					}
				},

			/*
				DEVELOPER
			 */
			{
				name: 'system-developer-pdfancy',
				path: 'dev/pdfancy',

				components: {
					default: r.loadView('asgard/system/dev/PDFancy'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},

				meta: {
					permissions: [
						Permissions.DEVELOPER
					]
				}
			},
			
			{
				name: 'system-developer-icons',
				path: 'dev/icons',
				
				components: {
					default: r.loadView('asgard/system/dev/Icons'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},
				
				meta: {
					permissions: [
						Permissions.DEVELOPER
					]
				}
			},
			
			{
				name: 'system-developer-playground',
				path: 'dev/playground',
				
				components: {
					default: r.loadView('asgard/system/dev/Playground'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},
				
				meta: {
					permissions: [
						Permissions.DEVELOPER
					]
				}
			},

			/*
				BACKUPS
			 */

			{
				name: 'system-backups',
				path: 'backups',

				components: {
					default: r.loadView('asgard/system/Backups'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},

				meta: {
					permissions: [
						Permissions.SYSTEM_BACKUPS
					]
				}
			},

			/*
				SENDGRID
			 */

			{
				name: 'system-sendgrid',
				path: 'sendgrid',

				components: {
					default: r.loadView('asgard/system/SendGrid'),
					sidebar: SystemSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},
				
				meta: {
					permissions: [
						Permissions.INFRASTRUCTURE
					]
				}
			},
			
			/*
			    DEVIL
			 */
			
			{
				name: 'system-devil',
				path: 'devil',
				redirect: '/system/devil/overview'
			},
			
				{
					name: 'system-devil-overview',
					path: 'devil/overview',
					
					components: {
						default: r.loadView('asgard/system/devil/Overview'),
						sidebar: SystemSidebar
					},
					
					props: {
						default: true,
						
						sidebar: {
							appearance
						}
					},
					
					meta: {
						permissions: [
							Permissions.INFRASTRUCTURE,
							Permissions.ORDERS_VIEWS
						]
					}
				},
			
				{
					name: 'system-devil-view-source',
					path: 'devil/source',
					
					components: {
						default: r.loadView('asgard/system/devil/ViewSource'),
						sidebar: SystemSidebar
					},
					
					props: {
						default: true,
						
						sidebar: {
							appearance
						}
					},
					
					meta: {
						permissions: [
							Permissions.INFRASTRUCTURE,
							Permissions.ORDERS_VIEWS
						]
					}
				},
			
				{
					name: 'system-devil-view-overview-source',
					path: 'devil/source/:clientShort/:orderType/overview',
					
					components: {
						default: r.loadView('asgard/system/devil/ViewSource'),
						sidebar: SystemSidebar
					},
					
					props: {
						default: true,
						
						sidebar: {
							appearance
						}
					},
					
					meta: {
						permissions: [
							Permissions.INFRASTRUCTURE,
							Permissions.ORDERS_VIEWS
						]
					}
				},
			
				{
					name: 'system-devil-view-order-source',
					path: 'devil/source/:clientShort/:orderType/:orderID',
					
					components: {
						default: r.loadView('asgard/system/devil/ViewSource'),
						sidebar: SystemSidebar
					},
					
					props: {
						default: true,
						
						sidebar: {
							appearance
						}
					},
					
					meta: {
						permissions: [
							Permissions.INFRASTRUCTURE,
							Permissions.ORDERS_VIEWS
						]
					}
				},

			{
				path: '/imprint',
				name: 'internal-imprint',

				components: {
					default: r.loadView('asgard/Imprint'),
					sidebar: null
				}
			}
		]
	}
]