var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: _vm.classes,
      attrs: { href: _vm.niceHref, target: _vm.target },
      on: {
        click: function ($event) {
          return !_vm.upload ? _vm.click($event) : null
        },
      },
    },
    [
      _vm.upload
        ? _c("input", {
            ref: "file_input",
            attrs: {
              type: "file",
              accept: Array.isArray(_vm.upload) ? _vm.upload.join(",") : null,
              id: _vm.file_input_id,
            },
            on: {
              change: function ($event) {
                return _vm.$emit("file", $event.target.files)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "label",
        { attrs: { for: _vm.file_input_id } },
        [
          _vm.showIcon && _vm.side == "left"
            ? [_c("v-svg", { attrs: { file: _vm.icon } })]
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "span",
                { key: "loading", staticClass: "loading" },
                [
                  _c("sweet-v2-spinner", {
                    attrs: {
                      size: "nano",
                      color: _vm.disabled ? "light-grey" : _vm.color,
                    },
                  }),
                ],
                1
              )
            : _c(
                "span",
                { key: "content", staticClass: "content" },
                [_vm._t("default")],
                2
              ),
          _vm._v(" "),
          _vm.showIcon && _vm.side == "right"
            ? [_c("v-svg", { attrs: { file: _vm.icon } })]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }