"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const User_1 = require("lib/User");
const Asgard_1 = require("lib/Asgard");
const UIHelper_1 = require("lib/UIHelper");
class UserManager {
    /* ===============================
        PUBLIC API FOR
        CHECKING AND GETTING THE USER
       =============================== */
    /**
     * Check if the user is logged in
     *
     * @return {boolean}
     */
    static isLoggedIn() {
        return UserManager.loggedInUser instanceof User_1.default;
    }
    /**
     * Get the currently logged in user
     *
     * @return {User|null}
     */
    static getUser() {
        return UserManager.loggedInUser;
    }
    /**
     * Get the currently logged in user.
     * Syntactic alias for {@see getUser()}
     *
     * @return {User|null}
     */
    static get user() {
        return UserManager.getUser();
    }
    /**
     * Get the currently used backend
     * Use UserManager::BACKEND_* constants
     *
     * @return {string}
     */
    static get currentBackend() {
        return UserManager.backend;
    }
    /* ====================
        PUBLIC API FOR
        LOGGING IN AND OUT
       ==================== */
    static login(backend, username, password) {
        return new Promise((resolve, reject) => {
            let api = UserManager.BACKENDS[backend] || null;
            if (!api) {
                reject(new Error('Unknown Backend.'));
                return false;
            }
            api.login(username, password).done((d) => {
                UserManager.handleLoginRequest(d, backend).then((u) => {
                    UserManager.backend = backend;
                    resolve(u);
                });
            }).fail((res) => {
                UserManager.handleLoginFail(api.parseResponse(res)).then((e) => {
                    reject(e);
                });
            });
        });
    }
    /**
     * Login using a OneTimeLink. Only supported with asgard.
     *
     * @param token
     */
    static loginOTL(token) {
        return new Promise((resolve, reject) => {
            Asgard_1.default.loginOTL(token).done(d => {
                UserManager.handleLoginRequest(d, UserManager.BACKEND_ASGARD).then((u) => {
                    UserManager.backend = UserManager.BACKEND_ASGARD;
                    resolve(u);
                });
            }).fail(res => {
                UserManager.handleLoginFail(Asgard_1.default.parseResponse(res)).then((e) => {
                    reject(e);
                });
            });
        });
    }
    static refresh() {
        let api = UserManager.BACKENDS[UserManager.backend];
        return new Promise((resolve, reject) => {
            api.refreshUser(UserManager.user.token).done(d => {
                UserManager.handleLoginRequest(d, UserManager.backend).then(_ => {
                    UIHelper_1.default.jConfirm('Refresh successful.', 'success');
                });
            }).fail((res) => {
                UserManager.handleLoginFail(api.parseResponse(res)).then((e) => {
                    reject(e);
                });
            });
        });
    }
    static logout() {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('u_a');
            localStorage.removeItem('u_pr');
            localStorage.removeItem('u_pm');
            localStorage.removeItem('login');
            UserManager.loggedInUser = null;
            UserManager.fireChange();
            let api = UserManager.BACKENDS[UserManager.currentBackend] || null;
            if (api) {
                api.logout();
            }
            else {
                console.warn('Unknown backend: ' + JSON.stringify(UserManager.currentBackend));
            }
            resolve();
        });
    }
    /* =====================
        PRIVATE HANDLING OF
        LOGGING IN AND OUT
       ===================== */
    static handleLoginRequest(data, backend = null) {
        return new Promise((resolve, reject) => {
            // Parse user
            let user = User_1.default.fromJSON(data);
            // Log in user
            localStorage.setItem('login', String(+new Date()));
            let userAccess = {
                u: user.username,
                i: user.userID,
                t: user.token
            };
            let profile = {
                avatar: user.avatar,
                firstname: user.firstname,
                lastname: user.lastname
            };
            localStorage.setItem('u_pm', JSON.stringify(data.permissions));
            localStorage.setItem('u_pr', JSON.stringify(profile));
            localStorage.setItem('u_a', JSON.stringify(userAccess));
            localStorage.setItem('u_b', backend);
            if (backend in UserManager.BACKENDS) {
                UserManager.backend = backend;
            }
            else {
                UserManager.backend = UserManager.BACKENDS[Object.keys(UserManager.BACKENDS)[0]];
            }
            UserManager.loggedInUser = user;
            resolve(user);
            UserManager.fireChange();
        });
    }
    static restoreLogin() {
        if (localStorage.getItem('u_a') && localStorage.getItem('login')) {
            let lastLogin = (+localStorage.getItem('login')) / 1000;
            let loginDiff = Math.floor((+new Date() / 1000) - lastLogin);
            if (loginDiff > 60 * 60 * 7 * 2) {
                UserManager.logout();
                UIHelper_1.default.jConfirm('You have to relogin for security.');
            }
            else {
                let access = JSON.parse(localStorage.getItem('u_a'));
                let permissions = User_1.default.parsePermissionsJSON(JSON.parse(localStorage.getItem('u_pm')) || []);
                let profile = JSON.parse(localStorage.getItem('u_pr')) || {};
                let backend = localStorage.getItem('u_b');
                let user = new User_1.default(access.i, access.u, profile.avatar, profile.firstname, profile.lastname, access.t, permissions);
                UserManager.loggedInUser = user;
                UserManager.backend = backend in UserManager.BACKENDS ? backend : 'asgard';
                UserManager.fireChange();
                return true;
            }
            return false;
        }
    }
    static handleLoginFail(response) {
        if (response.status == 500) {
            console.error(response);
        }
        return new Promise((resolve, reject) => {
            UserManager.fireChange();
            resolve({ message: response.message, status: response.status });
        });
    }
    /* ===========================
        MIDDLEWARE FOR THE ROUTER
       =========================== */
    static requireAuth(to, from, next) {
        if (to.name == 'login' || to.name == 'login-otl' || to.name == 'logout') {
            next();
        }
        else {
            UserManager.restoreLogin();
            if (!UserManager.isLoggedIn()) {
                next({
                    path: '/login',
                    query: { to: to.fullPath }
                });
            }
            else {
                if (from.query.to) {
                    to.query.to = from.query.to;
                }
                to.matched.some((record) => {
                    if (record.meta.permissions && (record.meta.permissions instanceof Array)) {
                        if (!UserManager.user.hasAnyPermission(record.meta.permissions)) {
                            let section = to.fullPath.split('/')[1];
                            next('/' + section + '/accessDenied?to=' + to.fullPath);
                        }
                        else {
                            next();
                        }
                    }
                    else {
                        next();
                    }
                });
            }
        }
    }
    /* ==================
        CHANGE LISTENERS
       ================== */
    /**
     * Add a listener to be called when the current login status changes.
     *
     * @param  {function}   cb authStatus as first argument (boolean), User as second argument
     */
    static addChangeListener(cb) {
        UserManager.changeListeners.push(cb);
    }
    /**
     * Remove a listener when login status changes
     * If the listener was never added nothing happens.
     *
     * @param  {Function} cb Callback to be removed
     */
    static removeChangeListener(cb) {
        let position = UserManager.changeListeners.indexOf(cb);
        if (position > -1) {
            UserManager.changeListeners.splice(position, 1);
        }
    }
    static fireChange() {
        for (let listener of UserManager.changeListeners) {
            listener(UserManager.isLoggedIn(), UserManager.loggedInUser);
        }
    }
}
exports.default = UserManager;
UserManager.changeListeners = [];
UserManager.BACKEND_ASGARD = 'asgard';
UserManager.BACKENDS = {
    [UserManager.BACKEND_ASGARD]: Asgard_1.default
};
