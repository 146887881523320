"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Permission_1 = require("./Permission");
const UserSettings_1 = require("./UserSettings");
/**
 * User
 * Represents a logged in user and is responsible for managing
 * log ins.
 *
 * @author bluefirex
 * @version 1.0
 * @package as.adepto.sweet-acp.lib
 */
class User {
    constructor(userID, username, avatar, firstname, lastname, token, permissions) {
        this.userID = userID;
        this.username = username;
        this.avatar = avatar;
        this.firstname = firstname;
        this.lastname = lastname;
        this.token = token;
        this.permissions = permissions;
    }
    /**
     * Check if this user has a specific permission.
     *
     * @param  {string}      perm  Permission, i.e. 'acp.access.acq'
     * @param  {any[] = []}  info  Info to check with
     *
     * @return {boolean}
     */
    hasPermission(perm, info = []) {
        return this.hasModelPermission(Permission_1.default.fromString(perm, info), info.length > 0);
    }
    /**
     * Check if this user has a specific permission but use the data model
     * instead of a plain string.
     *
     * @param  {Permission}      perm      Permission to check for
     * @param  {boolean = true}  withInfo  Whether or not to check additional info as well
     *
     * @return {boolean}
     */
    hasModelPermission(perm, withInfo = true) {
        for (let groupPerm of this.permissions) {
            if (groupPerm.allows(perm, withInfo)) {
                return true;
            }
        }
        return false;
    }
    /**
     * Check if this user has any of the specified permissions.
     * This works with strings as well as permission models.
     *
     * @param  {any[]}  permissions Permissions to check for
     *
     * @return {boolean}
     */
    hasAnyPermission(permissions) {
        for (let perm of permissions) {
            if (typeof (perm) == 'string' && this.hasPermission(perm)) {
                return true;
            }
            if (typeof (perm) != 'string' && this.hasModelPermission(perm)) {
                return true;
            }
        }
        return false;
    }
    /**
     * Check if this user has all of the specified permissions.
     * This works with strings as well as permission models.
     *
     * @param  {any[]}   permissions Permissions to check for
     *
     * @return {boolean}
     */
    hasAllPermissions(permissions) {
        for (let perm of permissions) {
            if (typeof (perm) == 'string' && !this.hasPermission(perm)) {
                return false;
            }
            if (typeof (perm) != 'string' && !this.hasModelPermission(perm)) {
                return false;
            }
        }
        return true;
    }
    getPermission(permission) {
        for (let perm of this.permissions) {
            if (perm.getName() == permission) {
                return perm;
            }
        }
        return null;
    }
    get settings() {
        if (User.settingsSingleton === null) {
            User.settingsSingleton = JSON.parse(localStorage.getItem('settings') || '{}', UserSettings_1.default.reviver);
        }
        return User.settingsSingleton;
    }
    toJSON() {
        let permissions = [];
        for (let permission of this.permissions) {
            permissions.push({
                name: permission.getName(),
                info: permission.getInfo()
            });
        }
        return {
            user_id: this.userID,
            username: this.username,
            avatar: this.avatar,
            firstname: this.firstname,
            lastname: this.lastname,
            token: this.token,
            permissions: permissions
        };
    }
    static parsePermissionsJSON(json) {
        let perms = [];
        for (let perm of json) {
            perms.push(Permission_1.default.fromString(perm.name, perm.info));
        }
        return perms;
    }
    static fromJSON(json) {
        return new User(json.user_id, json.username, json.avatar, json.firstname, json.lastname, json.token, this.parsePermissionsJSON(json.permissions));
    }
    static reviver(key, value) {
        return key === '' ? User.fromJSON(value) : value;
    }
}
exports.default = User;
User.settingsSingleton = null;
