import { DomainRouter } from 'lib/DomainRouter'

/**
 * debuggable-mixin
 * Allows for debugging of a component by providing these methods:
 * _debug, _info, _log, _warn, _error
 *
 * All of those methods work the same as console.debug, console.info etc.
 * but they're only printed when the environment is set to 'dev'.
 * The name of the component that emitted a log is prepended automatically
 * for better identification.
 *
 * @author bluefirex
 * @version 1.0
 * @package as.adepto.sweet-acp.mixins
 */
export default {
	computed: {
		$_component_name() {
			return this.$vnode.tag.replace(/^vue-component-\d+-/, '')
		}
	},

	methods: {
		$_debug() {
			this.$_call_console('debug', Array.prototype.slice.call(arguments))
		},

		$_info() {
			this.$_call_console('info', Array.prototype.slice.call(arguments))
		},

		$_log() {
			this.$_call_console('log', Array.prototype.slice.call(arguments))
		},

		$_warn() {
			this.$_call_console('warn', Array.prototype.slice.call(arguments))
		},

		$_error() {
			this.$_call_console('error', Array.prototype.slice.call(arguments))
		},

		$_call_console(method, args) {
			if (!DomainRouter.isDev() || !args.length) return

			// Try to get the component's name
			if (this.$_component_name) {
				args.unshift('[' + this.$_component_name + ']')
			}

			console[method].apply(this, args)
		}
	}
}
