"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const moment = require("moment");
require("moment/locale/nb");
const i18n_1 = require("locales/i18n");
/**
 * DateTimeExt
 * Wrapper for DateTime calculations.
 * Currently uses moment() as its implementation.
 *
 * @author bluefirex
 * @version 1.0
 * @package as.adepto.sweet-acp.lib
 */
class DateTimeExt {
    constructor(...args) {
        this.moment = moment(...args);
        if (!this.moment.isValid()) {
            throw new Error('Date is invalid: ' + args);
        }
    }
    /**
     * Clone this object.
     *
     * @return {DateTimeExt}
     */
    clone() {
        return new DateTimeExt(this.moment.clone());
    }
    /**
     * Get a clone of this object in UTC time.
     *
     * @return {DateTimeExt}
     */
    get utc() {
        return new DateTimeExt(this.moment.clone().utc());
    }
    /**
     * Get a clone of this object in local time
     *
     * @return {DateTimeExt}
     */
    get local() {
        return new DateTimeExt(this.moment.clone().local());
    }
    /**
     * number of seconds
     *
     * @return {number}
     */
    get seconds() {
        return this.moment.seconds();
    }
    set seconds(val) {
        this.moment.seconds(val);
    }
    /**
     * number of minutes
     *
     * @return {number}
     */
    get minutes() {
        return this.moment.minutes();
    }
    set minutes(val) {
        this.moment.minutes(val);
    }
    /**
     * number of hours
     *
     * @return {number}
     */
    get hours() {
        return this.moment.hours();
    }
    set hours(val) {
        this.moment.hours(val);
    }
    /**
     * Day of the month
     *
     * @return {number}
     */
    get date() {
        return this.moment.date();
    }
    /**
     * Day of the month
     *
     * @return {number}
     */
    get day() {
        return this.date;
    }
    set date(val) {
        this.moment.date(val);
    }
    set day(val) {
        this.date = val;
    }
    /**
     * Day of the month
     *
     * @return {number}
     */
    get dayOfMonth() {
        return this.moment.date();
    }
    set dayOfMonth(val) {
        this.moment.date(val);
    }
    /**
     * day of the week, between 1 and 7 depending on locale
     *
     * @return {number}
     */
    get dayOfWeek() {
        return this.moment.day();
    }
    set dayOfWeek(val) {
        this.moment.dayOfWeek(val);
    }
    /**
     * Day of the Week, between 1 (Monday) and 7 (Sunday)
     *
     * @return {number}
     */
    get weekday() {
        return this.moment.isoWeekday();
    }
    set weekday(val) {
        this.moment.isoWeekday(val);
    }
    /**
     * week number, 1-52
     *
     * @return {number}
     */
    get week() {
        return this.moment.isoWeek();
    }
    set week(val) {
        this.moment.week(val);
    }
    /**
     * month, 0-11
     *
     * @return {number}
     */
    get month() {
        return this.moment.month();
    }
    set month(val) {
        this.moment.month(val);
    }
    /**
     * Quarter, 1-4
     *
     * @return {number}
     */
    get quarter() {
        return this.moment.quarter();
    }
    set quarter(val) {
        this.moment.quarter(val);
    }
    /**
     * Year
     *
     * @return {number}
     */
    get year() {
        return this.moment.year();
    }
    set year(val) {
        this.moment.year(val);
    }
    set(what, value) {
        this.moment[what](value);
        return this;
    }
    setTime(hour, minute, second) {
        this.hours = hour;
        this.minutes = minute;
        this.seconds = second;
        return this;
    }
    /**
     * Implementation of add(...args)
     *
     * @param  {any[]} args Arguments for moment()
     *
     * @return {DateTimeExt}
     */
    add(...args) {
        this.moment.add(...args);
        return this;
    }
    /**
     * Implementation of subtract(...args)
     *
     * @param  {any[]} args Arguments for moment()
     *
     * @return {DateTimeExt}
     */
    subtract(...args) {
        this.moment.subtract(...args);
        return this;
    }
    /**
     * Similar to Date.toString(), i.e. "Sat Apr 30 2016 16:59:46 GMT-0500"
     *
     * @return {string}
     */
    toString() {
        return this.moment.format();
    }
    /**
     * Formats a string to the ISO8601 standard, i.e. "2013-02-04T22:44:30.652Z"
     *
     * @return {string}
     */
    toISOString() {
        return this.moment.toISOString();
    }
    /**
     * Converts this DateTimeExt to native Date
     *
     * @return {Date}
     */
    toDate() {
        return this.moment.toDate();
    }
    /**
     * This returns an array that mirrors the parameters from new Date(), i.e. [2013, 1, 4, 14, 40, 16, 154]
     *
     * @return {number[]}
     */
    toArray() {
        return this.moment.toArray();
    }
    /**
     * This returns an object containing year, month, day-of-month, hour, minute, seconds, milliseconds, i.e.:
     * {
     *     years: 2015
     *     months: 6
     *     date: 26,
     *     hours: 1,
     *     minutes: 53,
     *     seconds: 14,
     *     milliseconds: 600
     * }
     *
     * @return {Object}
     */
    toObject() {
        return this.moment.toObject();
    }
    /**
     * When serializing an object to JSON. It will be represented as an ISO8601 string, adjusted to UTC,
     * i.e. "2013-02-04T22:44:30.652Z"
     *
     * @return {string}
     */
    toJSON() {
        return this.moment.toJSON();
    }
    /**
     * simply outputs the number of milliseconds or seconds since the Unix Epoch,
     *
     * @param  {boolean = false}  unix  If true, seconds are used. If false, milliseconds are used.
     *
     * @return {number}
     */
    valueOf(unix = false) {
        if (unix) {
            return this.moment.unix();
        }
        else {
            return this.moment.valueOf();
        }
    }
    /**
     * Proxy for moment()
     *
     * @return {string}
     */
    format(...args) {
        return this.moment.format(...args);
    }
    /**
     * Check if the date is before a certain other date
     *
     * @param {DateTimeExt|string|number|Date|Moment|MomentInputObject|any[]}  arg         Other Date
     * @param precision                                                                    'year', 'month', 'day', 'hour', …
     */
    isBefore(arg, precision) {
        return this.moment.isBefore(arg, precision);
    }
    /**
     * Check if the date is after a certain other date
     *
     * @param {DateTimeExt|string|number|Date|Moment|MomentInputObject|any[]}  arg         Other Date
     * @param precision                                                                    'year', 'month', 'day', 'hour', …
     */
    isAfter(arg, precision) {
        return this.moment.isAfter(arg, precision);
    }
    /**
     * Check if the date is after a certain other date
     *
     * @param {DateTimeExt|string|number|Date|Moment|MomentInputObject|any[]}  arg1         Date 1
     * @param {DateTimeExt|string|number|Date|Moment|MomentInputObject|any[]}  arg2         Date 2
     * @param precision                                                                    'year', 'month', 'day', 'hour', …
     */
    isBetween(arg1, arg2, precision) {
        if (arg1 instanceof DateTimeExt) {
            arg1 = arg1.moment;
        }
        if (arg2 instanceof DateTimeExt) {
            arg2 = arg2.moment;
        }
        return this.moment.isBetween(arg1, arg2, precision, '[]');
    }
    calendar(arg1, formats) {
        if (arg1 instanceof DateTimeExt) {
            arg1 = arg1.moment;
        }
        return this.moment.calendar(arg1, formats);
    }
    calendar_date_only(arg1) {
        if (arg1 instanceof DateTimeExt) {
            arg1 = arg1.moment;
        }
        let dateStrings = i18n_1.i18n.messages[i18n_1.i18n.locale].date;
        return this.calendar(arg1, {
            sameDay: '[' + dateStrings.today + ']',
            nextDay: '[' + dateStrings.tomorrow + ']',
            nextWeek: 'DD.MM.YYYY',
            lastDay: '[' + dateStrings.yesterday + ']',
            lastWeek: 'DD.MM.YYYY',
            sameElse: 'DD.MM.YYYY'
        });
    }
    /**
     * A common way of displaying time. This is sometimes called timeago or relative time.
     *
     * @param  {boolean = true}  withSuffix  Whether or not to append "ago"
     *
     * @return {string}
     */
    fromNow(withSuffix = true) {
        return this.moment.fromNow(!withSuffix);
    }
    /**
     * A common way of displaying time. This is sometimes called timeago or relative time.
     * In contrast to fromNow() this allows any other time to be chosen.
     *
     * @param  {DateTimeExt|string|number|Date|Moment|MomentInputObject|any[]}  arg         Other Date to be relative to
     * @param  {boolean = true}                               withSuffix  Whether or not to append "ago"
     *
     * @return {string}
     */
    from(arg, withSuffix = true) {
        if (arg instanceof DateTimeExt) {
            arg = arg.moment;
        }
        return this.moment.from(arg, !withSuffix);
    }
    /**
     * A common way of displaying time. This is sometimes called timeago or relative time.
     *
     * @param  {boolean = true}  withSuffix  Whether or not to append "ago"
     *
     * @return {string}
     */
    toNow(withSuffix = true) {
        return this.moment.toNow(!withSuffix);
    }
    /**
     * A common way of displaying time. This is sometimes called timeago or relative time.
     * In contrast to toNow() this allows any other time to be chosen.
     *
     * @param  {DateTimeExt|string|number|Date|Moment|MomentInputObject|any[]}  arg         Other Date to be relative to
     * @param  {boolean = true}                               withSuffix  Whether or not to append "ago"
     *
     * @return {string}
     */
    to(arg, withSuffix = true) {
        if (arg instanceof DateTimeExt) {
            arg = arg.moment;
        }
        return this.moment.to(arg, !withSuffix);
    }
    /**
     * Calculate the difference between this and another date.
     * When writing such an expression, think of it as a subtraction operation, i.e.:
     *     this.diff(other)  ->  this - other
     *
     * @param  {DateTimeExt|number|string|Date|any[]}  arg    Anything the DateTimeExt constructor accepts as well
     * @param  {string = undefined}                    unit   Unit. Use DateTimeExt::UNIT_* or leave it off entirely.
     * @param  {boolean = undefined}                   float  If true, result can be a floating point number
     *
     * @return {number|string}
     */
    diff(arg, unit = undefined, float = undefined) {
        if (arg instanceof DateTimeExt) {
            arg = arg.moment;
        }
        if (unit !== undefined && unit !== null) {
            unit = moment.normalizeUnits(unit);
            if (!DateTimeExt.UNITS.filter(val => val == unit).length) {
                throw new Error('Invalid unit.');
            }
        }
        return this.moment.diff(arg, unit, float);
    }
    changeLocale(locale) {
        this.moment.locale(locale);
        return this;
    }
    static changeGlobalLocale(locale) {
        moment.locale(locale);
        return DateTimeExt;
    }
    getTimestamp(unix = true) {
        if (unix) {
            return this.moment.unix();
        }
        else {
            return this.moment.valueOf();
        }
    }
    setTimestamp(timestamp, unix = true) {
        if (unix) {
            this.moment = moment.unix(timestamp);
        }
        else {
            this.moment = moment(timestamp);
        }
        return this;
    }
    /**
     * Create a new instance from a timestamp in milliseconds or seconds (default).
     *
     * @param  {number}          timestamp  Timestamp
     * @param  {boolean = true}  unix       If true, seconds are assumed
     *
     * @return {DateTimeExt}
     */
    static fromTimestamp(timestamp, unix = true) {
        if (unix) {
            return new DateTimeExt(moment.unix(timestamp));
        }
        else {
            return new DateTimeExt(moment(timestamp));
        }
    }
    static formatTimestamp(timestamp, format) {
        return DateTimeExt.fromTimestamp(timestamp).format(format);
    }
    /**
     * Create a new instance from a string in one of these formats:
     *
     *
     * @param  {string} str String
     *
     * @return {DateTimeExt|null}
     */
    static fromString(str) {
        let matches = str.match(/^([0-9]{1,2})(\.|-)([0-9]{1,2})(\.|-)([0-9]{4})$/);
        if (matches) {
            let day = parseInt(matches[1]), month = parseInt(matches[3]) - 1, year = parseInt(matches[5]);
            let dt = new DateTimeExt();
            dt.year = year;
            dt.month = month;
            dt.day = day;
            dt.hours = 0;
            dt.minutes = 0;
            dt.seconds = 0;
            return dt;
        }
        return null;
    }
    /**
     * Create DateTimeExt from UTC time.
     * Accepts anything the DateTimeExt constructor accepts as well.
     *
     * @param  {any[]}  args  Arguments
     *
     * @return {DateTimeExt}
     */
    static fromUTC(...args) {
        return new DateTimeExt(moment.utc(...args));
    }
    /**
     * Get a now-instance.
     *
     * @return {DateTimeExt}
     */
    static now() {
        return new DateTimeExt();
    }
    /**
     * Return maximum date from the ones given.
     *
     * @param  {DateTimeExt[]} dates Dates
     *
     * @return {DateTimeExt}
     */
    static max(...dates) {
        return new DateTimeExt(moment.max(dates));
    }
    /**
     * Return minimum date from the ones given.
     *
     * @param  {DateTimeExt[]} dates Dates
     *
     * @return {DateTimeExt}
     */
    static min(...dates) {
        return new DateTimeExt(moment.min(dates));
    }
    /**
     * Translate a number of minutes into a more human-readable version, if possible
     *
     * e.g.:
     *  120 -> 2 hours
     *  121 -> 121 minutes
     *  1440 -> 1 day
     *  1500 -> 25 hours
     *  1505 -> 1505 minutes
     *  etc.
     *
     * @param {number} minutes
     * @returns {[number, string]} Value, DateTimeExt.UNIT_* (minute, hour, day only)
     */
    static minutesToHuman(minutes) {
        let value = Math.abs(minutes);
        if (value % 1440 == 0) {
            return [
                value / 1440,
                DateTimeExt.UNIT_DAY
            ];
        }
        if (value % 60 == 0) {
            return [
                value / 60,
                DateTimeExt.UNIT_HOUR
            ];
        }
        return [
            value,
            DateTimeExt.UNIT_MINUTE
        ];
    }
    /**
     * Translate a value in a unit into a number of minutes
     *
     * @param {number} value    Value
     * @param {string} unit     Use DateTimeExt.UNIT_* (minute, hour, day only)
     * @returns {number}
     */
    static humanToMinutes(value, unit) {
        let factor = {
            [DateTimeExt.UNIT_DAY]: 1440,
            [DateTimeExt.UNIT_HOUR]: 60,
            [DateTimeExt.UNIT_MINUTE]: 1
        }[unit];
        return value * factor;
    }
    static i18n_getMonths() {
        return moment.months();
    }
    static i18n_getWeekdaysShort() {
        return moment.weekdaysShort();
    }
}
exports.default = DateTimeExt;
DateTimeExt.UNIT_SECOND = 'second';
DateTimeExt.UNIT_MINUTE = 'minute';
DateTimeExt.UNIT_HOUR = 'hour';
DateTimeExt.UNIT_DAY = 'day';
DateTimeExt.UNIT_WEEK = 'week';
DateTimeExt.UNIT_MONTH = 'month';
DateTimeExt.UNIT_QUARTER = 'quarter';
DateTimeExt.UNIT_YEAR = 'year';
DateTimeExt.UNITS = [
    DateTimeExt.UNIT_SECOND,
    DateTimeExt.UNIT_MINUTE,
    DateTimeExt.UNIT_HOUR,
    DateTimeExt.UNIT_DAY,
    DateTimeExt.UNIT_WEEK,
    DateTimeExt.UNIT_MONTH,
    DateTimeExt.UNIT_QUARTER,
    DateTimeExt.UNIT_YEAR
];
