import Vue from 'vue'
import PlanetSpinner from 'components/PlanetSpinner'
import { SweetModal } from 'sweet-modal-vue'
import SweetButton from 'ui-2/core/Button'
import SweetInput from 'ui-2/core/Input'

// Load jQuery
require('expose-loader?exposes=$,jQuery!jquery')

// hint
import('../styles/vendor/hint.scss')

// SelectOrDie
require('../styles/vendor/jquery.selectordie.scss')
require('../vendor/jquery-selectordie/selectordie.js')

// BlockUI
require('block-ui/jquery.blockUI.js')

// jConfirm
require('script-loader!jgrowl/jquery.jgrowl.min.js')
require('../styles/vendor/jquery.jgrowl.scss')

const BodyScrollLock = require('body-scroll-lock')

let _vueEventBus
let planetLoaderHTML = null

/**
 * UIHelper
 * Helper functions for controlling UI and global state handler
 * for global plugins, like jQuery.
 *
 * @author bluefirex
 * @version 2.0
 * @package as.adepto.sweetacp.lib
 */
export default class UIHelper {

	/**
	 * Proxy for $
	 *
	 * @return {JQuery}
	 */
	static get $() {
		return window.$
	}

	static get planetLoaderHTML() {
		if (planetLoaderHTML === null) {
			let $vm = new Vue({
				template: '<planet-spinner size="bigger"></planet-spinner>',
				components: {
					PlanetSpinner
				}
			}).$mount()

			planetLoaderHTML = $vm.$el
		}

		return planetLoaderHTML
	}

	/**
	 * Block an element using jQuery BlockUI
	 *
	 * @param  {jQuery} $el      element to block
	 * @param  {string} message  Message to show, defauls to '*-loading-*'
	 * @param  {string} mode     Theme of the overlay, defaults to light
	 * @param  {number} timeout  Timeout, if > 0 then it will auto-close after timeout ms
	 */
	static blockElement($el, message = '*-loading-*', mode = 'light', timeout = 0) {
		if (message == '*-loading-*') {
			message = '<img src="/images/spinner.svg" style="height: 48px;" />'
		} else if (message == '*-crunching-*') {
			message = this.planetLoaderHTML
		}

		if (typeof($el) == 'string' || $el instanceof Node) {
			$el = $($el)
		}

		$el.block({
			message,

			css: {
				border: 'none',
				background: 'transparent',
				opacity: 1.0,
				color: mode == 'dark' ? '#fff' : '#272727',
				fontFamily: 'Roboto, Open Sans, sans-serif',
				fontWidth: '300',
				fontSize: '16px',
				cursor: 'default',
				width: '100%',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)'
			},

			overlayCSS: {
				backgroundColor: mode == 'dark' ? '#141B24' : '#fff',
				opacity: mode == 'dark' ? 0.84 : 0.9,
				//height: 'calc(100% - 36px)'
				boxSizing: 'border-box',
				borderRadius: '6px'
			},

			centerY: false,
			centerX: false
		})

		if (timeout > 0) {
			window.setTimeout(() => {
				this.unblockElement($el)
			}, timeout)
		}
	}

	static unblockElement($el) {
		if (typeof($el) == 'string' || $el instanceof Node) {
			$el = $($el)
		}

		$el.unblock()
	}

	static openWindow(url, options) {
		return window.open(
			url,
			'_blank',
			Object.keys(options)
			      .map(key => key + '=' + options[key])
			      .join(',')
		)
	}

	/**
	 * Open some code in a highlighted format, currently a new window
	 *
	 * @param code              Code to display
	 * @param mode (optional)   Language, e.G. "javascript"
	 * @param title (optional)  Title to show
	 */
	static openCode(code, mode = 'json', title = null) {
		let handle = UIHelper.openWindow('/services/highlight', {
			...UIHelper.getScreenCenterCoordinates(800, 600),
			toolbar: 'no',
			location: 'no'
		})

		let tries = 0
		const MAX_TRIALS = 32

		// Eager to posting code!
		let postCode = (code, mode, title = null) => {
			if (!handle.postCode) {
				if (tries++ <= MAX_TRIALS) {
					setTimeout(_ => postCode(code, mode, title), 100)
				} else {
					console.error('[UIHelper]', 'Failed to deliver code after ' + MAX_TRIALS + ' trials')
				}

				return
			}

			handle.postCode(code, mode, title)
		}

		postCode(code, mode, title)
	}

	static openCodeAsync() {
		let handle = UIHelper.openWindow('/services/highlight', {
			...UIHelper.getScreenCenterCoordinates(800, 600),
			toolbar: 'no',
			location: 'no'
		})

		let tries = 0
		const MAX_TRIALS = 32

		let waitForWindow = (callback, error) => {
			if (!handle.postCode) {
				if (tries ++ <= MAX_TRIALS) {
					setTimeout(_ => waitForWindow(callback), 100)
				} else {
					console.error('[UIHelper]', 'Failed to retrieve window function after ' + MAX_TRIALS + ' trials')
					error()
				}

				return
			}

			callback(handle)
		}

		return new Promise((resolve, reject) => {
			waitForWindow(h => {
				resolve(h)
			}, _ => {
				reject()
			})
		})
	}

	/**
	 * Get the top+left coordinates of where to place a window to have it centered on screen
	 * Also considers screen zooming
	 *
	 * @param windowWidth       Intended window width
	 * @param windowHeight      Intended window height
	 *
	 * @returns {{top: number, left: number, width: number, height: number}}
	 */
	static getScreenCenterCoordinates(windowWidth, windowHeight) {
		return {
			width: windowWidth,
			height: windowHeight,
			top: (screen.height - windowHeight) / 2,
			left: (screen.width - windowWidth) / 2,
		}
	}

	/**
	 * Show an animated error message.
	 * Requires sweet-modal-vue to be installed as well as an element "#modals" to bind these to.
	 *
	 * @param  {String} content           Message / HTML-Content
	 * @param  {String} title             Title
	 * @param  {number} timer             Milliseconds to show the message, 0 = unlimited
	 * @param  {boolean} showCancelButton  Whether or not to show a cancel-button
	 * @param  {boolean} allowOutsideClick Whether or not to allow a click to dismiss it
	 * @param  {boolean} ultrawide         If true, fills almost the entire width of the screen
	 */
	static errorMessage(content, title = '', timer = false, showCancelButton = true, allowOutsideClick = true, ultrawide = false) {
		return new Promise((resolve, reject) => {
			let $vm = new Vue({
				render: function(h) {
					let buttons = []

					if (showCancelButton) {
						buttons.push(h(SweetButton, {
							slot: 'button',

							props: {
								color: 'red',
								noBorder: true,
							},

							on: {
								click: () => {
									if (typeof(callback) == 'function') {
										console.warn('DEPRECATED', '[UIHelper.errorMessage] callback has been replaced by Promises.')
										callback()
									}

									$vm.$refs.modal.close()
								}
							}
						}, 'Cancel'))
					}

					return h(SweetModal, {
						props: {
							title,
							icon: 'error',
							hideCloseButton: showCancelButton || allowOutsideClick,
							blocking: !allowOutsideClick,
							modalTheme: 'light',
							width: ultrawide ? '94%' : null,
							overlayTheme: document.documentElement.classList.contains('backdropfilter') ? 'light' : 'dark'
						},

						on: {
							close: () => {
								resolve()

								setTimeout(() => {
									$vm.$el.remove()
								}, 500)
							}
						},

						ref: 'modal'
					}, [
						h('div', {
							domProps: {
								innerHTML: content
							}
						}),

						...buttons
					])
				}
			}).$mount()

			document.getElementById('modals').appendChild($vm.$el)
			$vm.$refs.modal.open()

			if (timer > 0) {
				setTimeout(() => {
					$vm.$refs.modal.close()
				}, timer)
			}
		})
	}

	/**
	 * Show an animated message.
	 * Requires sweet-modal-vue to be installed as well as an element "#modals" to bind these to.
	 *
	 * @param  {string}   content  Message / HTML-Content
	 * @param  {string}   title    Title
	 * @param  {number}   timer    Milliseconds to show the message, 0 = undefined
	 * @param  {string}   icon     Icon to use
	 * @param  {string}   theme    Which theme to use (light or dark)
	 */
	static textMessage(content, title = '', timer = null, icon = null, theme = 'light') {
		return new Promise((resolve, reject) => {
			let $vm = new Vue({
				render: function(h) {
					return h(SweetModal, {
						props: {
							title,
							icon,
							hideCloseButton: true,
							blocking: false,
							modalTheme: theme,
							overlayTheme: document.body.classList.contains('backdropfilter') ? 'light' : 'dark'
						},

						on: {
							close: () => {
								resolve()

								setTimeout(() => {
									$vm.$el.remove()
								}, 500)
							}
						},

						ref: 'modal'
					}, [
						h('div', {
							domProps: {
								innerHTML: content
							}
						}),

						h(SweetButton, {
							slot: 'button',

							props: {
								color: 'green',
								dark: theme == 'dark'
							},

							on: {
								click: () => {
									if (typeof(callback) == 'function') {
										console.warn('DEPRECATED', '[UIHelper.textMessage] callback has been replaced by Promises.')
										callback()
									}

									$vm.$refs.modal.close()
								}
							}
						}, 'OK')
					])
				}
			}).$mount()

			document.getElementById('modals').appendChild($vm.$el)
			$vm.$refs.modal.open()

			if (timer > 0) {
				setTimeout(() => {
					$vm.$refs.modal.close()
				}, timer)
			}
		})
	}

	/**
	 * Show an animated success message.
	 * Requires sweet-modal.js to be loaded.
	 *
	 * @param  {string}   content  Message / HTML-Content
	 * @param  {string}   title    Title
	 * @param  {number}   timer    Milliseconds to show the message, 0 = undefined
	 * @param  {string}   theme    Theme to use, 'light' or 'dark'
	 */
	static successMessage(content, title = '', timer = null, theme = 'light') {
		return this.textMessage(content, title, timer, 'success', theme)
	}

	/**
	 * Show an animated info message.
	 * Requires sweet-modal.js to be loaded.
	 *
	 * @param  {string}   content  Message / HTML-Content
	 * @param  {string}   title    Title
	 * @param  {number}   timer    Milliseconds to show the message, 0 = undefined
	 * @param  {string}   theme    Theme to use, 'light' or 'dark'
	 */
	static infoMessage(content, title = '', timer = null, theme = 'light') {
		return this.textMessage(content, title, timer, 'info', theme)
	}

	/**
	 * Show an animated info message.
	 * Requires sweet-modal.js to be loaded.
	 *
	 * @param  {string}   content  Message / HTML-Content
	 * @param  {string}   title    Title
	 * @param  {number}   timer    Milliseconds to show the message, 0 = undefined
	 * @param  {string}   theme    Theme to use, 'light' or 'dark'
	 */
	static warningMessage(content, title = '', timer = null, theme = 'light') {
		return this.textMessage(content, title, timer,  'warning', theme)
	}

	/**
	 * Ask for input
	 * Requires sweet-modal-vue to be installed as well as an element "#modals" to bind these to.
	 *
	 * @param  {string}   question     Title for the dialog
	 * @param  {string}   placeholder  Placeholder for the input value
	 * @param  {string}   value        Pre-populated value
	 * @param  {string}   theme        Theme to use, 'light' or 'dark'
	 */
	static prompt(question, placeholder = null, value = null, theme = 'light') {
		return new Promise((resolve, reject) => {
			let $vm = new Vue({
				render: function(h) {
					return h(SweetModal, {
						props: {
							title: question,
							hideCloseButton: true,
							blocking: true,
							modalTheme: theme,
							overlayTheme: document.body.classList.contains('backdropfilter') ? 'light' : 'dark'
						},

						on: {
							close: () => {
								setTimeout(() => {
									$vm.$el.remove()
								}, 500)
							}
						},

						ref: 'modal'
					}, [
						h(SweetInput, {
							props: {
								placeholder,
								value,
								size: 'big'
							},

							style: {
								width: '100%'
							},

							on: {
								input: newValue => {
									value = newValue
								},

								keyup(e) {
									if (e.keyCode == 13) {
										$vm.confirm()
									}
								}
							},

							ref: 'input'
						}),

						h(SweetButton, {
							slot: 'button',

							props: {
								color: 'red',
								bordered: true,
								noBorder: true,
							},

							on: {
								click: () => {
									$vm.$refs.modal.close()
								}
							}
						}, 'Cancel'),

						h('span', {
							slot: 'button',

							style: {
								display: 'inline-block',
								width: '12px'
							}
						}),

						h(SweetButton, {
							slot: 'button',

							props: {
								color: 'green',
							},

							on: {
								click: () => {
									$vm.confirm()
								}
							}
						}, 'OK')
					])
				},

				mounted() {
					this.$nextTick().then(this.$refs.input.focus)
				},

				methods: {
					confirm() {
						if (typeof(callback) == 'function') {
							console.warn('DEPRECATED', '[UIHelper.prompt] callback has been replaced by Promises.')
							callback(value)
						}

						resolve(value)
						$vm.$refs.modal.close()
					}
				}
			}).$mount()

			document.getElementById('modals').appendChild($vm.$el)
			$vm.$refs.modal.open()
		})
	}

	/**
	 * Ask for confirmation
	 * Requires sweet-modal-vue to be installed as well as an element "#modals" to bind these to.
	 *
	 * @param  {string}   question     Content for the dialog
	 * @param  {string}   theme        Theme to use, 'light' or 'dark'
	 */
	static confirm(question, theme = 'light') {
		return new Promise((resolve, reject) => {
			let $vm = new Vue({
				render: function(h) {
					return h(SweetModal, {
						props: {
							icon: 'question',
							hideCloseButton: true,
							blocking: true,
							modalTheme: theme,
							overlayTheme: document.body.classList.contains('backdropfilter') ? 'light' : 'dark'
						},

						on: {
							close: () => {
								setTimeout(() => {
									$vm.$el.remove()
								}, 500)
							}
						},

						ref: 'modal'
					}, [
						h('div', {
							domProps: {
								innerHTML: question
							}
						}),

						h(SweetButton, {
							slot: 'button',

							props: {
								color: 'red',
								bordered: true,
								noBorder: true,
								dark: theme == 'dark',
							},

							on: {
								click: () => {
									resolve(false)
									$vm.$refs.modal.close()
								}
							}
						}, 'Cancel'),

						h('span', {
							slot: 'button',

							style: {
								display: 'inline-block',
								width: '12px'
							}
						}),

						h(SweetButton, {
							slot: 'button',

							props: {
								color: 'green',
								dark: theme == 'dark',
							},

							on: {
								click: () => {
									if (typeof(callback) == 'function') {
										console.warn('DEPRECATED', '[UIHelper.confirm] callback has been replaced by Promises.')
										callback()
									}

									resolve(true)
									$vm.$refs.modal.close()
								}
							}
						}, 'OK')
					])
				}
			}).$mount()

			document.getElementById('modals').appendChild($vm.$el)
			$vm.$refs.modal.open()
		})
	}

	/**
	 * Display a jGrowl notification
	 *
	 * @param  {string} message Message
	 * @param  {string} type    info, confirm/success, error | defaults to info
	 */
	static jConfirm(message, type = 'info') {
		let icon, heading
		let path = '/images/icons/info'

		switch (type) {
			case 'confirm':
			case 'success':
				icon = 'success_white.svg'
				heading = 'Success'
				break;

			case 'error':
				icon = 'error_white.svg'
				heading = 'Error'
				break;

			default:
				icon = 'info_white.svg'
				heading = 'Info'
		}

		let template = `
			<div class="jConfirm type-${type}">
				<div class="icon">
					<img src="${path + '/' + icon}" />
				</div>

				<div class="content">
					<h4>${heading}</h4>
					${message}
				</div>
			</div>
		`

		$.jGrowl.defaults.closer = false

		$.jGrowl(template, {
			openDuration: 0,
			closeDuration: 600,
			sticky: false,
			life: 4000,

			animateOpen: {
				opacity: 1
			},

			animateClose: {
				opacity: 1
			},

			afterOpen: (e, m, o) => {
				e.addClass('opened')
			},

			beforeClose: (e, m, o) => {
				e.removeClass('opened')
			}
		})
	}

	/**
	 * Select the contents of an element
	 *
	 * @param  {Node} $element Element
	 *
	 * @return {Boolean} Whether or not text could be selected
	 */
	static selectElement($element) {
		let range, selection

		if (window.getSelection && document.createRange) {
			selection = window.getSelection()

			range = document.createRange()
			range.selectNodeContents($element)

			selection.removeAllRanges()
			selection.addRange(range)

			return true
		} else if (document.selection && document.body.createTextRange) {
			range = document.body.createTextRange()

			range.moveToElementText($element)
			range.select()

			return true
		}

		return false
	}

	/**
	 * Scroll to the top of the page
	 *
	 * @param {number} speed = 300 Speed to scroll, use 0 for instant scroll
	 */
	static scrollToTop(speed = 300) {
		window.$('body, html').animate({
			scrollTop: 0
		}, speed)
	}

	static blockBodyScroll($target) {
		BodyScrollLock.disableBodyScroll($target)
	}

	static unblockBodyScroll($target) {
		BodyScrollLock.enableBodyScroll($target);
	}

	/**
	 * Activate all global listeners from jQuery plugins and other
	 * stuff
	 */
	static setGlobalListeners() {
	//	$.sweetDropdown.attachAll()
		$('select.selectordie').selectOrDie({
			cycle: true
		})
	}

	/**
	 * Emit a global vue event through the event bus.
	 *
	 * @param {string} name Name of the event
	 * @param {Array}  data Variable args: Data to pass on
	 */
	static emitVue(name, ...data) {
		if (!_vueEventBus) {
			_vueEventBus = new Vue()
		}

		_vueEventBus.$emit(name, ...data)
	}

	/**
	 * Listen to an event on the global Vue bus
	 *
	 * @param {string}   name Name of the event
	 * @param {Function} cb   Function to call, receives the data from {@see emitVue} as variable args
	 */
	static onVueBus(name, cb) {
		if (!_vueEventBus) {
			_vueEventBus = new Vue()
		}

		_vueEventBus.$on(name, cb)
	}
}
