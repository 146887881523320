import Permissions from 'lib/Permissions'
import * as r from 'routes/_functions'

const StatisticsSidebar = r.loadView('asgard/statistics/Sidebar')
const appearance = 'amber'

export default [
	{
		path: '/statistics',
		component: r.loadLayout('Basic'),

		meta: {
			permissions: [
				Permissions.STATISTICS,
				Permissions.STATISTICS + '.*'
			]
		},

		children: [
			{
				name: 'statistics',
				path: '/statistics',
				redirect: '/statistics/overview'
			},

			{
				name: 'statistics-overview',
				path: '/statistics/overview',

				components: {
					default: r.loadView('asgard/statistics/Overview'),
					sidebar: StatisticsSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				}
			},
			{
				name: 'statistics-orders-report',
				path: '/statistics/orders-report',

				components: {
					default: r.loadView('asgard/statistics/OrdersReport'),
					sidebar: StatisticsSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				}
			},
			{
				name: 'statistics-protocols-report',
				path: '/statistics/protocols-report',

				components: {
					default: r.loadView('asgard/statistics/ProtocolsReport'),
					sidebar: StatisticsSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				}
			},
			{
				name: 'statistics-leads-report',
				path: '/statistics/leads-report',

				components: {
					default: r.loadView('asgard/statistics/LeadsReport'),
					sidebar: StatisticsSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				}
			}
		]
	}
]