import UIHelper from 'lib/UIHelper'

let Block = {}

Block.install = function(Vue, options) {
	Vue.directive('block', function($el, binding) {
		let message = binding.value
		let mode = binding.modifiers.dark ? 'dark' : 'light'

		if (message === true) {
			UIHelper.blockElement($el, '*-loading-*', mode)
		} else if (message) {
			UIHelper.blockElement($el, message, mode)
		} else {
			UIHelper.unblockElement($el)
		}
	})
}

export default Block
