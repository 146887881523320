import UserManager from 'lib/UserManager'
import UIHelper from 'lib/UIHelper'
import Vue from 'vue'
import { DomainRouter, Domain } from 'lib/DomainRouter'

let loggedIn = null
let init = false

export default class Raygun {
	static init() {
		if (!init) {
			!function(a,b,c,d,e,f,g,h){a.RaygunObject=e,a[e]=a[e]||function(){
			    (a[e].o=a[e].o||[]).push(arguments)},f=b.createElement(c),g=b.getElementsByTagName(c)[0],
			    f.async=1,f.src=d,g.parentNode.insertBefore(f,g),h=a.onerror,a.onerror=function(b,c,d,f,g){
			    h&&h(b,c,d,f,g),g||(g=new Error(b)),a[e].q=a[e].q||[],a[e].q.push({
			    e:g})}}(window,document,"script","//cdn.raygun.io/raygun4js/raygun.min.js","rg4js");
		}

		Raygun.enableCrashReporting(Raygun.shouldCrashReportingBeEnabled())

		window.rg4js('apiKey', 'uOi32MYvxxlKfJMYd8QA')
		window.rg4js('withTags', ['sweetdesk', DomainRouter.get().host, DomainRouter.get().mode])
		window.rg4js('logContentsOfXhrCalls', true)
		window.rg4js('setVersion', process.build)

		window.rg4js('whitelistCrossOriginDomains', ['eu-central-1.s3.amazonaws.com'])
		window.rg4js('options', {
			ignore3rdPartyErrors: true,
			disableAnonymousUserTracking: true
		})

		if (Raygun.shouldCrashReportingBeEnabled()) {
			UserManager.addChangeListener(Raygun.onUserChange)
			Raygun.attachToVue()
		}

		// UIHelper.onVueBus('routeChange', Raygun.onRouteChange)

		init = true
	}

	static shouldCrashReportingBeEnabled() {
		return process.env.NODE_ENV != 'dev' && (UserManager.isLoggedIn() && UserManager.user.settings.get('behavior.tracking', true))
	}

	static enableCrashReporting(state = true) {
		console.info('[Raygun] State:', state)
		window.rg4js('enableCrashReporting', state)
		window.rg4js('enablePulse', state)
	}

	static onUserChange(state, user) {
		if (loggedIn == state) return;

		Raygun.enableCrashReporting(Raygun.shouldCrashReportingBeEnabled())

		if (state) {
			console.info('[Raygun] Logged in')

			window.rg4js('setUser', {
				identifier: user.username,
				isAnonymous: false,
				fullName: user.username
			})

			loggedIn = true
		} else {
			console.info('[Raygun] Logged out')
			window.rg4js('setUser', '')

			loggedIn = false
		}
	}

	static onRouteChange($route) {
		window.rg4js('trackEvent', {
			type: 'pageView',
			path: $route.path
		})
	}

	static attachToVue() {
		let currentErrorHandler = Vue.config.errorHandler

		let newErrorHandler = function(error, $vm, info) {
			if (Raygun.shouldCrashReportingBeEnabled()) {
				window.rg4js('send', {
					error,
					customData: [
						{
							info
						}
					]
				})
			} else {
				console.error(error)
			}
		}

		if (typeof(currentErrorHandler) == 'function') {
			Vue.config.errorHandler = function(err, $vm, info) {
				currentErrorHandler(...arguments)
				newErrorHandler(...arguments)
			}
		} else {
			Vue.config.errorHandler = newErrorHandler
		}

		console.info('[Raygun] Attached')
	}
}