export default class SVG {

	static load(pack, file) {
		return require('public/images/icons/' + pack + '/' + file + '.svg')
	}

	static loadAbsolute(file) {
		return require('public/images/' + file + '.svg')
	}

	static get(string) {
		if (string.charAt(0) == '/') {
			return this.loadAbsolute(string.substring(1))
		} else {
			const config = string.split('/')
			const pack = config.slice(0, config.length - 1)
			const icon = config[config.length - 1]

			return this.load(pack, icon)
		}
	}
}
