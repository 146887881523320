import * as r from 'routes/_functions'

export default [
	{
		path: '/v2',
		component: r.loadLayout('Basic'),

		children: [
			{
				name: 'welcome',
				path: 'welcome',

				components: {
					default: r.loadView('asgard/Welcome')
				}
			},

			{
				name: 'profile',
				path: 'profile',

				components: {
					default: r.loadView('asgard/system/users/Edit')
				}
			},

			{
				name: 'settings-asgard',
				path: 'settings',

				components: {
					default: r.loadView('asgard/Settings')
				}
			}
		]
	}
]