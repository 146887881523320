import TDL from 'data/asgard/TDL'

export default {
	locale: 'nb',
	locale_specifier: 'no-NB',

	messages: {
		generic: {
			copied_to_clipboard: 'Kopiert til utklippstavlen.',
			client: 'Klient',
			order: 'Ordre',
			orders: 'Ordre',
			order_type: 'Ordre Type',
			order_id: 'Oppdragsnummer',
			order_reference: 'Referanse',
			leads: 'Leads',
			meter: 'Måler',
			edit: 'Endre',
			change: 'Velg',
			save: 'Lagre',
			delete: 'Slett',
			cancel: 'Avbryt',
			open: 'Åpne',
			add: 'Legg til',
			view: 'Se',
			create: 'Legg til',
			back: 'Tilbake',
			download: 'Last ned',
			submit: 'Send',
			resubmit: 'Send på nytt',
			done: 'Ferdig',
			next: 'Neste',
			search: 'Søk',
			search_placeholder: 'Hva ser du etter?',
			simple_search_placeholder: 'Skriv inn en ordrereferanse eller dato',
			details: 'Detaljer',
			choose_clients: 'Velg Klienter',
			title: 'Tittel',
			untitled: 'Uten navn',
			dev: 'Utvikler',
			ok: 'OK',

			select_all: 'Velg alle',
			deselect_all: 'Opphev alle',
			remove_all: 'Slette alle',

			show_active_only: 'Vis bare aktive',

			help: 'Hjelp',

			welcome: {
				night: 'Jobber om natten?<br /><b>Ikke glem å sove.</b>',
				morning: 'God morgen, {name}.',
				day: 'Hyggelig å se deg, {name}.',
				evening: 'God kveld, {name}.'
			}
		},

		date: {
			today: 'I dag',
			yesterday: 'I går',
			tomorrow: 'I morgen',
			days: '{0} dager',

			generic: {
				day: 'Dager | Dag | Dager',
				hour: 'Timer | Time | Timer',
				minute: 'Minutter | Minutt | Minutter',
				seconds: 'Sekunder | Sekunde | Sekunder',

				before: 'før',
				after: 'etter'
			}
		},

		navigation: {
			orders: 'Ordre',
			leads: 'Leads',
			statistics: 'Statistikk',
			finance: 'Finans',
			system: 'System'
		},

		sidebars: {
			orders: {
				overview: 'Oversikt',

				section_orders: 'Ordre',
				orders_upcoming: 'Kommende',
				orders_submitted: 'Innsendt',
				orders_search: 'Søk',
				orders_fetch: 'Hente',
				orders_logs: 'Logg',

				create_order: 'Legg til Ordre',
				create_electricity: 'Legg til Strøm',

				section_clients: 'Clients',
				clients: 'Clients',
				create_client: 'Legg til Client'
			},

			leads: {
				overview: 'Oversikt',
				section_leads: 'Leads',
				leads_search: 'Søk',

				section_providers: 'Providers',
				providers: 'Providers',
				create_provider: 'Create Provider',

				dev_products: 'Produkter'
			},

			help: {
				overview: 'Oversikt'
			}
		},

		user_widget: {
			edit_profile: 'Rediger Profilen',
			settings: 'Instellinger',
			refresh: 'Oppdater',
			copy_app_token: 'Kopiere AppToken',
			report_issue: 'Rapport Problem',
			logout: 'Logg ut'
		},

		person: {
			title: 'Personer | Person | {count} Personer',

			types: {
				seller: 'Selgere | Selger | {count} Selgere',
				buyer: 'Kjøpere | Kjøper | {count} Kjøpere',
				broker: 'Meglere | Megler |{ count} Meglere',
				renter: 'Utleiere | Utleier | {count} Utleiere',
				rentee: 'Leietakere | Leiertaker | {count} Leietakere',
				landlord: 'Utleiere | Utleier | {count} Utleiere',
				tenant: 'Leietakere | Leiertaker | {count} Leietakere',
				legal_owner: 'Nåværende eiere | Nårværende eier | {count} Nårværende eiere',
				new_owner: 'Ny eiere | Ny eier | {count} Ny eiere',
				entrepreneur: 'Entreprenøre | Entreprenør | {count} Entreprenøre',
				builder: 'Byggherre | Byggherr | {count} Byggherre',
				surveyor: 'Takstmann | Takstmenn | {count} Takstmenn',
				next_of_kin: 'Pårørende | Pårørend | {count} Pårørende',
				guardian: 'Verge | Verg | {count} Verge',
				ceo: 'Daglig ledere | Daglig leder | {count} Daglig ledere',
				board_member: 'Styremedlemmer | Styremedlem | {count} Styremedlemmer',
				chairman: 'Styreledere | Styreleder | {count} Styreledere',
				signature_authority: 'Signaturberettiget | Signaturberettiget | {count} Signaturberettiget',
				general_agency: 'Proukrister | Prokurist | {count} Prokurister',
				real_rights_holder: 'Reelle rettighetshavere | Reelle rettighetshaver | {count} Reelle rettighetshavere',
				contact: 'Kontaktpersoner | Kontaktperson | {count} Kontaktpersoner'
			},

			selected_hint: 'Denne personen er valgt for produktbestillinger.',

			type: 'Type',

			firstname: 'Fornavn',
			name: 'Navn',
			person_number: 'Personnummer',
			org_number: 'Orgnummer',
			birthdate: 'Fødselsdato',
			birthdate_format: 'Fødselsdato ({format})',

			contact: 'Kontakt',
			email: 'Epost',
			phone: 'Telefon',

			address: 'Adresse',
			street: 'Gate',
			street_house_number: 'Gate + House Nr',
			postcode: 'Postnummer',
			city: 'Sted',
			country: 'Land',

			errors: {
				missing_person_name: 'En eller flere personer mangler et navn.'
			}
		},

		leads: {
			section_leads: 'Leads',
			leads_search: 'Søk'
		},

		orders_overview: {
			open_demo: 'Åpne Demo',
			open_demo_description: 'Åpne en demo for en klient.',

			open_demo_order_type: 'Ordretyp',
			open_demo_client: 'Klient',

			no_permission_to_create_demo: 'Du har ikke tillatelse til å lage demoer for {client}.'
		},

		orders_collection: {
			type: {
				upcoming: 'Kommende',
				submitted: 'Innsendt'
			},

			submitted_on: 'Sendt inn',
			orders_per_day: 'Ordre per dag',

			hints: {
				status_0: 'Venter',
				status_1: 'Behandling',
				status_2: 'Klar',
				['status_-1']: 'Feil'
			}
		},

		orders_upload: {
			title: 'Laste Opp',

			upload: {
				instructions: `
					Velg en fil for å importere ordre fra ved å klikke filikonet.<br />
					Hvilke filtyper som støttes, avhenger av integrasjonen med CRM.<br />
					<small>Maksimal filstørrelse: {filesize}</small>
				`,

				verify: 'Vurdere',
				try_again: 'Prøv igjen'
			},

			errors: {
				missing_values: 'Denne verdien mangler.',
				missing_values_short: 'Manglende verdi',

				invalid_values: 'Denne verdien er ugyldig.',
				invalid_values_short: 'Ugyldig verdi',

				invalid_headers: 'Denne overskriften er ukjent. Dens kolonne vil bli ignorert.',

				other: 'Annen feil',
				other_short: 'Annen feil',

				incomplete_line: 'Denne linjen har færre verdier enn kolonner.',
				incomplete_line_short: 'Ufullstendig linje'
			},

			verification: {
				errors_title: '⚠️ Vi har oppdaget noen problemer',
				errors_description: 'Vennligst gå gjennom dem og prøv igjen.',
				date_notice: 'OBS: Celler som skal ha en dato, men inneholder et nummer, er fortsatt gyldige datoer og vil bli importert riktig.',

				type_warnings: 'Advarsler',
				type_errors: 'Feil',

				type_warnings_description: 'Disse cellene kan ignoreres.',
				type_errors_description: 'Denne filen kan ikke importeres før disse er løst.'
			},

			uploaded: {
				title: 'Filen din er lastet opp.',
				description: 'Det kan ta opptil 12 timer før behandlingen er ferdig, og dine nye ordre vises.',
				upload_new_file: 'Last opp ny fil',

				references_description: 'Hvis du støter på problemer, vennligst send oss disse referansene (klikk for å velge):'
			}
		},

		order_view: {
			actions: {
				view_on_google_maps: 'Se på Google Maps',
				view_gallery: 'Se galleri',
				view_protocol_queue: 'Se Protocol Queue',
				view_source: 'Se code',

				copy_reference: 'Kopier Referanse'
			},

			tickets: {
				title: 'Linker',

				hints: {
					auto_submit: 'Sendes automatisk, enten direkte eller etter at det har gått noe tid.',
					single_use: 'Kan bare brukes til å sende inn en protokoll en gang.',
					protocols: 'Antall protokoller som er opprettet med denne linken.'
				},

				actions: {
					copy_link: 'Kopier Link',
					create_link: 'Opprett Link',
					open: 'Åpne',
					edit_notes: 'Rediger Notater',
					send_notifications: 'Send Varsler',
					view_devices: 'Se Enheter'
				},

				send_notifications: {
					choose_template: '@:order_view.tickets.actions.send_notifications: Velg Mal',
					choose_groups: '@:order_view.tickets.actions.send_notifications: Velg Mottaker',
					click_template_to_continue: 'Klikk på en mal for å gå videre.',
					send: 'Send {total} varsler | Send {total} varsel | Send {total} varsler',
					sent: 'Varsler er sendt.'
				},

				devices: 'Enheter',

				create_testing: 'Testing?',
				notes_placeholder: 'Skriv inn et notat, e.eks. hvem har mottatt denne linken, …',
				notes_saved: 'Notater lagret',

				link_created: 'Linken vær opprett.',
				link_deleted: 'Linken vær slett.',
				link_delete_confirm: 'Er dere sikker at du vil slette linken {ticketID}?\nLinken vil slutte å virke og kan ikke gjenopprettes under sin opprinnelige ID.'
			},

			notifications: {
				title: 'Meldinger',

				tabs: {
					sent: 'Sendt før Protokollen',
					confirmations: 'Sendt etter Protokollen',
					upcoming: 'Kommende'
				},

				sender: 'Avsender',
				subject: 'Subjekt',
				template: 'Template',
				params: 'Parametere'
			},

			no_persons: 'Ingen personer.',
			no_notifications_sent: 'Ingen meldinger har blitt sendt.',
			no_notifications_to_be_sent: 'Ingen meldinger som vil bli sendt.',
			no_protocols: 'Ingen protokoller har blitt sendt.',

			order_not_found: 'Kunne ikke finne ordren.',
			order_not_found_description: 'Sjekk at du har riktig klient og ordre ID, eller prøv å {0}.',

			protocols: {
				title: 'Protokoller',
				new_protocol_incoming: 'En ny protokoll er nettopp sendt inn og vil vises her om kort tid.',

				delete_confirm: 'Er dere sikker at du vil slette protokollen? Dette kan ikke angres.',

				protocol_deleted: 'Protokollen vær slett.'
			},

			last_updated: 'Last Updated',
			size: 'Areal',
			price_net: 'Pris (Net)',
			floor: 'Etasje',
			contract_meeting_date: 'Kontraktmøte',
			takeover_date: 'Overtakelsesdato',
			cadaster: 'GNR/BNR/SNR/HNR',
			proprietary: 'Proprietær',
		},

		order_edit: {
			broker: {
				preset: 'Preset?',
			},

			testing_warning: 'Rediger av testordre er anbefales ikke.',
			send_notifications: 'Send varsler',
			choose_order_type: 'Velg ordre type',
			add_person: 'Legg til person',

			errors: {
				invalid_address: 'Adresse er ugyldig. Sjekk at adressen, postnummeret og stedet er riktige.',
				takeover_date_missing: 'Overtakelsesdato mangler.',
				no_persons: 'Det er ingen personer.',

				delete_has_protocols: 'Denne ordre kan ikke slettes fordi den har protokoller.'
			}
		},

		leads_view: {
			overview: {
				search_description: 'Søk for spesifisert leads'
			},

			finance: {
				income: 'Inntekt',
				kickback: 'Kickback',
				revenue: 'Inntekt (Revenue)'
			},

			actions: {
				view_order: 'Se Ordre'
			},

			tag: {
				[TDL.TAG_REVOKED]: 'Angret',
				[TDL.TAG_INCOMPLETE]: 'Ufullstendig',
				[TDL.TAG_PROVIDER_EXTERNAL_ERROR]: 'Oppstartsfeil',
				[TDL.TAG_METER_SHARED]: 'Fellesmålt',
				[TDL.TAG_DUPLICATE]: 'Bestilt allerede',
			},

			shortcuts: {
				today: 'I dag',
				this_week: 'Denne uken',
				last_7_days: 'Siste 7 dager',
				last_14_days: 'Siste 14 dager',
				last_30_days: 'Siste 30 dager',
				this_month: 'Denne måned',
				last_month: 'Siste måned',
			}
		},

		protocol: {
			notice: {
				still_processing: 'Denne protokollen behandles fortsatt. Det kan mangle informasjon. {0}',
				error: 'Det oppstod en feil under behandlingen av denne protokollen. {0}',
				last_update: 'Siste oppdater: {0}'
			},

			no_documents: 'Ingen dokumenter tilgjengelig.',
			no_persons: 'Ingen personer sendt inn eller personer er de samme som på ordre.',
			no_meters: 'Ingen målere sendt inn.',
			no_leads: 'Ingen produkter bestillt.',

			tabs: {
				information: 'Informasjon',
				persons: 'Personer',
				meters: 'Målere',
				leads: 'Leads',
				review: 'Anmeldelse',
				documents: 'Dokumenter',
				stages: 'Framgang',
				cost: 'Kostnader',
				actions: 'Aksjoner'
			},

			actions: {
				view_protocol_queue_entry: 'Se Protocol Queue Entry'
			},

			document: {
				copy_link: 'Kopier Link'
			},

			meters: {
				types: {
					gas: 'Gass',
					electricity: 'Strøm',
					water: 'Vann',
					'warm-water': 'Varmtvann',
					'cold-water': 'Kaldtvann',
					heating: 'Varme'
				},

				type: 'Typ',
				mileage: 'Målerstand',
				number: 'Målernummer',
				ean: 'EAN (f.eks. Nubix MID)',
				images: 'Bilder'
			},
		},

		search: {
			title: 'Søk',
			placeholder: 'Hva ser du etter?',

			tabs: {
				simple: 'Enkelt',
				advanced: 'Avansert'
			},

			no_results: 'Ingen resultater.',
			no_results_generic_description: 'Ingen resultater funnet som samsvarer med søket ditt.',
			no_results_orders_description: 'Ingen ordre funnet som samsvarer med søket ditt.',
			no_results_protocol_queue_entries_description: 'Ingen køoppføringer funnet som somsvarer med søket ditt.',

			export_as: 'Eksporter som {type}',
		},

		orders_fetch: {
			add_order_ids: 'Oppdragsnumrene',

			add_order_ids_description: 'Skriv inn en eller flere oppdragsnummere eller la tom for å hente alt.',

			actions: {
				fetch_all: 'Hente alle',
				fetch: 'Hente {num} ordre',
				next: 'Neste',
				force_updates: 'Oppdater før henting'
			}
		},

		protocol_queue: {
			title: 'Protocol Queue',
			overview: 'Oversikt',

			columns: {
				id: 'ID',
				order: 'Ordre',
				status: 'Status'
			},

			actions: {
				select_all: 'Velg alle',
				deselect_all: 'Opphev alle',
				change_status: 'Endre status',
				submit_again: 'Send inn på nytt',
				view_order: 'Se Ordre',
				run_again: 'Løp igjen',
			},

			status: {
				0: 'Venter',
				1: 'Behandling',
				2: 'Klar',
				error: 'Feil'
			},

			edit: {
				title: '#{entry_id}',
				stages: 'Stadier',
				entry: 'Inngang',
				response: 'Respons',
				request: 'Forespørsel',
				auto_submit: 'Sende inn automatisk?',
				scheduled_at: 'Planlagt på',
				created_at: 'Opprettet på',
				protocol_id: 'Protokoll ID',
				completed: 'Fullført',
				delete_confirmation: 'Er du sikker på at du vil slette denne forespørselen?'
			}
		},

		client_overview: {
			actions: 'Aksjoner',
			settings: 'Instillinger',

			action_all_orders: 'Alle ordre',
			action_all_orders_description: 'Se alle ordre på denne klienten'
		},

		clients_branding: {
			title: 'Branding',
			description: 'Reider logos og farger',
		},

		clients_notifications: {
			title: 'Varsler',
			description: 'Rediger varslingsforsendelser og maler',
			empty_content: 'Velg en rutine eller mal til venstre.',

			ticket_type: 'Hvilke protokoller?',
			schedules: 'Når?',
			receivers: 'Hvem?',
			template: 'Mal',

			timeframe: 'Tidsramme (Valgfritt)',
			start_date: 'Første varsling',
			end_date: 'Siste varsling',
			timeframe_swapped: 'First sendout must be before last sendout.',
			swap_dates: 'Swap',

			time_mode: {
				exact: 'Nøyaktig klokka',
				relative: 'Nøyaktig'
			},

			section_routines: 'Rutiner',
			section_templates: 'Maler',

			change_template: 'Endre',
			choose_template: 'Velge',
			select_template: 'Velg mal',
			template_predefined: 'Forhåndsdefinert',

			action_simulate: 'Simulere',

			create_routine: 'Legg til rutine…',
			create_template: 'Legg til mal…',

			sender: 'Avsender',
			content: 'Innhold',
			subject: 'Subjekt',

			simulation_title: 'Simulering',
			simulation_description_key_date: 'Anta overtakelsen var på',
			simulation_description_reference_date: 'og i dag ville være',
			simulation_description_notifications: '…da ville vi ha sendt disse varslene:',

			notification_type: 'Medium',
			sendgrid_params: 'SendGrid: Parametere',
			sendgrid_template_id: 'SendGrid: Mal ID',

			notification_types: {
				sms: 'SMS',
				sendgrid: 'E-post',
				email: 'E-post',

				MailSG: 'E-post',
				SMS: 'SMS'
			},

			errors: {
				no_ticket_type: 'Ingen protokoll type valgt.',
				no_template: 'Minst en tidsplan har ingen mal valgt.',
				invalid_time: 'Tiden som er angitt i minst en tidsplan er ugyldig. Pass på at den er formatert som HH:mm, f.eks. 07:45.',
				no_title: 'Tittel mangler.',
				no_notification_type: 'Ingen @:clients_notifications.notification_type valgt.',
				no_sender: '@:clients_notifications.sender mangler.',
				no_content: '@:clients_notifications.content mangler.',
				no_sendgrid_template: '@:clients_notifications.sendgrid_template_id mangler.',
				no_subject: '@:clients_notifications.subject mangler.',
			},

			delete_routine_confirmation: 'Er du sikker på at du vil slette denne rutinen?',
			routine_deleted: 'Rutine slettet.',
			routine_saved: 'Rutine lagret.',

			delete_template_confirmation: 'Er du sikker på at du vil slette denne malen?',
			template_deleted: 'Mal slettet.',
			template_saved: 'Mal lagret.',
		},

		clients_ticket_types: {
			title: 'Protokolltyper',
			description: 'Kontroller hvilke typer protokoller som er tilgjengelige for en klient.',

			enabled_types: 'Aktivert',
			available_types: 'Tilgjengelig',
			new_types: 'Ny',

			removal_info: 'Deaktivering av protokolltype ikke deaktiverer eller fjerner eksisterende lenker av den typen.',
			no_enabled_types: 'Det er ingen protokolltyper aktivert for denne klienten.',
			changes_saved: 'Alle endringer er lagret'
		},

		query_builder: {
			and: 'OG',
			or: 'ELLER',

			add_rule: 'Regel',
			add_group: 'Gruppe',

			shortcuts: 'Snarvei',
			results: '{0} resultater',

			search: 'Søk',

			equal: "lik",
			not_equal: "ikke lik",
			in: "in",
			not_in: "not in",
			less: "less",
			less_or_equal: "less or equal",
			greater: "greater",
			greater_or_equal: "greater or equal",
			between: "between",
			not_between: "not between",
			begins_with: "begins with",
			not_begins_with: "doesn't begin with",
			contains: "contains",
			not_contains: "doesn't contain",
			ends_with: "ends with",
			not_ends_with: "doesn't end with",
			is_empty: "is empty",
			is_not_empty: "is not empty",
			is_null: "is null",
			is_not_null: "is not null"
		},

		order_types: {
			estate: 'Eiendom',
			estate_description: 'Overtakelse, Nybygg, Leads, …',

			phone_number_handover: 'Portering',
			phone_number_handover_description: '- Ingen beskrivelse -'
		},

		protocol_types: {
			estate_acquisition: 'Overtakelse',
			estate_acquisition_commercial: 'Næring (Salg)',
			estate_electricity_seller: 'Strømavtalen for Selgere',
			estate_electricity: 'Digitalt strømskjema',
			estate_electricity_buyer: 'Strømavtalen for Kjøpere',
			estate_rental_commercial_moving_in: 'Næring (Utleie-Innflytt)',
			estate_rental_commercial_moving_out: 'Næring (Utleie-Utflytt)',
			estate_moving: 'Flytting',
			estate_leads: 'Deals',
			estate_single_product_leads: 'Deals (Enkelt produkt)',
			estate_settlement: 'Oppgjør',
			estate_new_construction: 'Nybygg',
			estate_rental_moving_in: 'Leie: Flytting Inn',
			estate_rental_moving_out: 'Leie: Flytting Ut',
			estate_forced_sale: 'Tvangssalg',
			estate_forced_sale_before_expiry: 'Tvangssalg (før ankefristens utløp)',
			estate_chairman_housing_association: 'Styreleder - Borettslag',
			estate_exchange: 'Boligbytte',
			estate_empty: 'Tomt',
			api_leads: 'API Leads [ingen UI]',
			visitor_leads: 'Strømbestilling [external UI]',
			estate_meglerfront: 'WebOT Overtakelse',
			estate_settlement_seller: 'Oppgjør for Selger',
			estate_settlement_buyer: 'Oppgjør for Kjøper (Privat)',
			estate_settlement_buyer_commercial: 'Oppgjør for Kjøper (Bedrift)',
			estate_politically_exposed_person: 'Politisk eksponert person',
			estate_customer_information_seller: 'Kundeopplysningsskjema for Selger',
			estate_customer_information_buyer: 'Kundeopplysningsskjema for Kjøper',
			estate_customer_declaration_commercial: 'Kundeerklæring juridisk person',

			estate_myrent_in_default: 'MyRent: Flytting inn (standard)',
			estate_myrent_in_no_electricity: 'MyRent: Flytting inn (ingen strømbestilling)',
			estate_myrent_in_with_water: 'MyRent: Flytting inn (ink vannmåler)',

			phone_handover_confirmation: 'Bekreftelse (Scenario 1)',
			phone_handover_addition: 'Addisjon (Scenario 2)',
			phone_handover_reparation: 'Reparasjon (Scenario 3)',
		},

		protocol_stages: {
			processing: 'Databehandling',
			protocol_pdf: 'PDF-oppretting',
			submit_electricity_pdf: 'Sende leads til Meglerfront',
			register_products: 'Leads Innlevering',
			leads_email: 'Sende leads via epost til leverandør',
			notifications: 'Sende varslinger',
			recycle_tickets: 'Oppdater linker',
			update_crm: 'Oppdater CRM'
		},

		product_entry_status: {
			[TDL.STATUS_ERROR]: 'Feil',
			[TDL.STATUS_WAITING]: 'Venter',
			[TDL.STATUS_PROCESSING]: 'Behandling',
			[TDL.STATUS_DONE]: 'Ferdig',
			[TDL.STATUS_NO_INTEGRATION]: 'Ingen integrasjon',

			[TDL.ERROR_BIX_DATA_UNVERIFIED]: 'EAN ugyldig',
			[TDL.ERROR_BIX_DATA_NOT_FOUND]: 'Mangler EAN',
			[TDL.ERROR_BIX_NOT_REACHABLE]: 'BIX not reachable',
			[TDL.ERROR_BIX_TIMEOUT]: 'BIX timeout',
			[TDL.ERROR_INVALID_BIRTHDATE]: 'Ugyldig fødselsdato',
			[TDL.ERROR_INVALID_EMAIL]: 'Ugyldig e-post',
			[TDL.ERROR_INVALID_PHONE]: 'Ugyldig telefon',
			[TDL.ERROR_INVALID_NAME]: 'Ugyldig navn',
			[TDL.ERROR_INVALID_PERSON_NUMBER]: 'Ugyldig personnummer',
			[TDL.ERROR_INVALID_TYPE]: 'Ugyldig type'
		},

		countries: {
			no: 'Norge',
			dk: 'Danmark',
			se: 'Sverige',
			de: 'Tyskland'
		},

		tdl: {
			title: 'Problemer',

			types: {
				ean_missing: 'Mangler EAN',
				invalid_person_data: 'Ugyldig personvern',
				marked_failed: 'Merket som feil',
				other: 'Andre'
			},

			descriptions: {
				ean_missing: 'Legg til manglende EAN',
				invalid_person_data: 'Korrigiere person eller kontaktinformasjon',
				marked_failed: 'Se leads som er merket feil',
				other: 'Andre problemer å bli håndtert av teknisk personale'
			},

			errors: {
				type_not_found: 'Kunne ikke finne typ.',

				other_error_title: 'Annen feil',
				other_error_description: 'Vennligst ta kontakt med IT for å lære mer om denne leaden.'
			},

			successes: {
				saved: 'Endringer lagret. Lead vil bli sendt på nytt i løpet av de neste 1-2 minuttene.',
				deleted: 'Lead er slettet.',
				status_updated: 'Status oppdatert.'
			},

			questions: {
				delete_entry: 'Er du sikker på at du vil slette denne underlaget?'
			},

			waiting_title: 'Venter på innsending…',
			waiting_description: 'Dette underlaget forbereder seg. Vennligst vent noen minutter.',

			processing_title: 'Behandling…',
			processing_description: 'Dette underlaget behandles nå. Vennligst vent noen sekunder.',

			show_logs: 'Se Logg',
			show_images: 'Bilder',
			add_person: 'Legg til person',
		},

		logs: {
			title: 'Logg',

			actions: {
				'crm_request': 'Forespørsel til leverandøren',
				'crm_response': 'Svar fra leverandøren',
				'request': 'Ekstern forespørsel',
				'create': 'Laget',
				'edit': 'Endret',
				'edit_status': 'Status endret'
			}
		}
	}
}