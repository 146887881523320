<template>
	<span :class="['svg', {colored: !multiColor}]" v-html="svg" :style="style" />
</template>

<script>
	import SVG from '../SVG'
	
	export default {
		name: 'VSvg',
		
		props: {
			file: {
				type: String,
				required: true
			},
			
			color: {
				type: String,
				required: false,
				default: 'inherit'
			},
			
			multiColor: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		
		computed: {
			svg() {
				return SVG.get(this.file).replace('<svg', '<svg role="img"')
			},
			
			style() {
				return {
					color: this.color
				}
			}
		}
	}
</script>

<style lang="scss">
	@import '~styles/mixins';
	
	.svg {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		
		&.colored {
			
			svg,
			svg path,
			svg polygon,
			svg rect,
			svg circle {
				/*width: inherit;
				height: inherit;*/
				fill: currentColor;
				transition: fill 0.2s;
			}
		}
	}
</style>
