var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["sweet-planet-spinner", "color-" + _vm.color, "size-" + _vm.size],
    },
    [
      _c(
        "div",
        { staticClass: "plane main" },
        _vm._l(5, function (i) {
          return _c("div", { staticClass: "circle" })
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }