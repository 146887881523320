/**
 * SweetUI 2 definition file
 * Defines which UI components are located where.
 * All listed components will be loaded using a 'Sweet' prefix and a '2' suffiy, i.e. 'SweetInput-2' -> sweet-ui-2.
 */

let ui = {
	core: [
		'Accordion',
		'AccordionItem',
		'Action',
		'Autocomplete',
		'Button',
		'Checkbox',
		'DataGrid',
		'DataGridItem',
		'Dropdown',
		'DropdownItem',
		'FileDrop',
		'FileUpload',
		'Flag',
		'FlipCard',
		'Input',
		'Pagination',
		'Popover',
		'ProgressBar',
		'Radiobox',
		'RangeSlider',
		'SegmentedButtons',
		'Select',
		'SelectItem',
		'Spinner',
		'TagsInput',
		'Tab',
		'Tabs'
	],

	charts: [
		'BarChart',
		'HorizontalBarChart',
		'DonutChart',
		'LineChart'
	],

	extended: [
		'AddressInput',
		'Breadcrumb',
		'ClientBranding',
		'CountrySelect',
		'DataTable',
		'DatePicker',
		'Form',
		'Gallery',
		'Heading',
		'InfoMessage',
		'ListItem',
		'MainTitle',
		'OptionChooser',
		'OptionChooserItem',
		'PhoneInput',
		'QueryBuilder',
		'Rating',
		'RelativeTimePicker',
		'Sidebar',
		'SidebarItem',
		'Timeline',
		'TimelineItem',
		'ViewSwitcher'
	]
}

let components = {}

for (let pack in ui) {
	for (let component of ui[pack]) {
		components['SweetV2' + component] = () => import('ui-2/' + pack + '/' + component)
	}
}

export default {
	install(Vue, options) {
		for (let key in components) {
			if (!components.hasOwnProperty(key)) {
				continue
			}
			
			Vue.component(key, components[key])
		}
	}
}