import Permissions from 'lib/Permissions'
import * as r from 'routes/_functions'

const LeadsSidebar = r.loadView('asgard/leads/Sidebar')
const appearance = 'deep-purple'

export default [
	{
		path: '/leads',
		component: r.loadLayout('Basic'),

		meta: {
			permissions: [
				Permissions.LEADS,
				Permissions.LEADS + '.*'
			]
		},

		children: [
			{
				name: 'leads',
				path: '/leads',
				redirect: '/leads/overview'
			},

			{
				name: 'leads-overview',
				path: 'overview',

				components: {
					default: r.loadView('asgard/leads/Overview'),
					sidebar: LeadsSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				}
			},

			{
				name: 'leads-search',
				path: 'search',

				components: {
					default: r.loadView('asgard/leads/Search'),
					sidebar: LeadsSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},
				
				meta: {
					permissions: [
						Permissions.LEADS_SEARCH
					]
				}
			},

			{
				name: 'leads-providers-manage',
				path: 'providers',

				components: {
					default: r.loadView('asgard/leads/providers/Manage'),
					sidebar: LeadsSidebar
				},
				
				props: {
					default: {
						appearance
					},
					
					sidebar: {
						appearance
					}
				},
				
				meta: {
					permissions: [
						Permissions.LEADS_PROVIDERS_VIEW
					]
				}
			},

			{
				name: 'leads-provider-edit',
				path: 'providers/:providerID(\\d+)',

				components: {
					default: r.loadView('asgard/leads/providers/Edit'),
					sidebar: LeadsSidebar
				},
				
				props: {
					default: (route) => ({
						providerID: route.params.providerID,
						appearance
					}),
					
					sidebar: {
						appearance
					}
				},
				
				meta: {
					permissions: [
						Permissions.LEADS_PROVIDERS_EDIT
					]
				}
			},
			
			{
				name: 'leads-tdl-type',
				path: 'tdl/:type',
				
				components: {
					default: r.loadView('asgard/leads/TDL'),
					sidebar: LeadsSidebar
				},
				
				props: {
					default: (route) => ({
						type: route.params.type,
						appearance
					}),
					
					sidebar: {
						appearance
					}
				},
				
				meta: {
					permissions: [
						Permissions.LEADS_TDL
					]
				}
			},

			{
				name: 'leads-dev-products',
				path: 'dev/products',

				components: {
					default: r.loadView('asgard/leads/dev/Products'),
					sidebar: LeadsSidebar
				},

				props: {
					default: {
						appearance
					},

					sidebar: {
						appearance
					}
				},

				meta: {
					permissions: [
						Permissions.DEVELOPER
					]
				}
			},
		]
	}
]