import VueRouter from 'vue-router'
import UserManager from 'lib/UserManager'
import UIHelper from 'lib/UIHelper'
import * as r from 'routes/_functions'

// Asgard Routes
import routesAsgardGeneral from 'routes/asgard/general'
import routesAsgardSystem from 'routes/asgard/system'
import routesAsgardOrders from 'routes/asgard/orders'
import routesAsgardLeads from 'routes/asgard/leads'
import routesAsgardStatistics from 'routes/asgard/statistics'
import routesAsgardHelp from 'routes/asgard/help'

/**
 * Route Definitions
 *
 * @type {Array}
 */
let routes = [
	{
		path: '/',
		name: 'global-index',

		beforeEnter(to, from, next) {
			if (UserManager.isLoggedIn()) {
				next('/v2/welcome')
			} else {
				next('/login')
			}
		}
	},

	{
		path: '/login',
		component: r.loadView('Login'),
		name: 'login'
	},

	{
		path: '/login/otl/:token',
		component: r.loadView('Login'),
		name: 'login-otl'
	},

	{
		path: '/logout',
		name: 'logout',

		beforeEnter(to, from, next) {
			UserManager.logout()
			next({
				name: 'login',

				query: {
					to: to.query.to
				}
			})
		}
	},

	...routesAsgardGeneral,
	...routesAsgardSystem,
	...routesAsgardOrders,
	...routesAsgardLeads,
	...routesAsgardStatistics,
	...routesAsgardHelp,

	/**
	 * ELHUB
	 */
	{
		path: '/services/elhub',
		component: r.loadView('services/Elhub'),
		name: 'service-elhub'
	},

	/**
	 * Code Highlight
	 */
	{
		path: '/services/highlight',
		component: r.loadView('services/CodeViewer'),

	},

	/**
	 * ACCESS DENIED
	 */
	{
		path: '/:section/accessDenied',
		component: r.loadLayout('Basic'),

		props: {
			showChrome: false
		},

		children: [
			{
				name: 'access-denied',
				path: '',

				components: {
					default: r.loadView('GlobalError')
				},

				props: {
					default(route) {
						let type = 'not-found'

						if (route.query.to) {
							if (route.query.to.substr(0, 7) == '/orders') {
								type = 'asgard-view'
							}
						}

						return ({
							type
						})
					}
				}
			},
		]
	},

	/**
	 * DEFAULT ROUTE
	 */
	{
		path: '*',
		component: r.loadLayout('Basic'),

		props: {
			showChrome: false
		},

		children: [
			{
				name: 'not-found',
				path: '',

				components: {
					default: r.loadView('GlobalError')
				},

				props: {
					default: {
						type: 'not-found'
					}
				}
			},
		]
	}
]

/**
 * VueRouter instance
 *
 * @type {VueRouter}
 */
const router = new VueRouter({
	mode: 'history',
	routes
})

router.beforeEach(UserManager.requireAuth)
router.afterEach((to, from) => {
	UIHelper.emitVue('routeChange', to)

	if (!!window.matchMedia('screen and (max-width: 850px)').matches && to.name != from.name) {
		UIHelper.scrollToTop()
	}
})

/**
 * Export dat stuff.
 */
export default router
