var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "sweet-input",
        "type-" + _vm.type,
        "size-" + _vm.size,
        { active: _vm.value, dark: _vm.dark, underlined: _vm.underlined },
      ],
    },
    [
      _c("input", {
        ref: "input",
        class: [
          "color-" + _vm.color,
          "size-" + _vm.size,
          {
            "has-icon": _vm.icon,
            "has-label": _vm.label,
            "has-dropdown": _vm.dataDropdown,
            error: _vm.error,
            dark: _vm.dark,
          },
        ],
        attrs: {
          type: _vm.finalType,
          placeholder: _vm.placeholder,
          id: _vm.id,
          disabled: _vm.disabled,
          autocomplete:
            _vm.autocomplete == false && _vm.autocomplete !== null
              ? "off"
              : _vm.autocomplete,
          name: _vm.name,
        },
        domProps: { value: _vm.value },
        on: {
          click: _vm.onClick,
          input: _vm.onInput,
          keyup: [
            _vm.onKeyup,
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onKeyupEnter.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.onKeyupEscape.apply(null, arguments)
            },
          ],
          keydown: _vm.onKeydown,
          focus: _vm.onFocus,
        },
      }),
      _vm._v(" "),
      _vm.icon
        ? _c(
            "label",
            { staticClass: "icon", attrs: { for: _vm.id } },
            [_c("v-svg", { attrs: { file: _vm.icon } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.label
        ? _c("label", { staticClass: "label", attrs: { for: _vm.id } }, [
            _vm._v("\n\t\t" + _vm._s(_vm.label) + "\n\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "color"
        ? _c("label", {
            staticClass: "color",
            style: _vm.colorStyle,
            attrs: { for: _vm.id },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.dataDropdown
        ? _c(
            "label",
            {
              staticClass: "dropdown-trigger",
              attrs: {
                "data-dropdown": _vm.dataDropdown,
                "data-add-anchor-x": _vm.dataAddAnchorX,
              },
            },
            [_c("span", [_vm._v("›")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("dropdown"),
      _vm._v(" "),
      _c("transition", { attrs: { name: "move-fade-right" } }, [
        _vm.showReset
          ? _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.value != "" && _vm.value !== null,
                    expression: "value != '' && value !== null",
                  },
                ],
                staticClass: "reset",
                attrs: { for: _vm.id },
              },
              [
                _c(
                  "a",
                  { on: { click: _vm.reset } },
                  [_c("v-svg", { attrs: { file: "acp/cross-circle" } })],
                  1
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "move-fade-bottom" } }, [
        _vm.type == "color"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.colorpicker_open == true,
                    expression: "colorpicker_open == true",
                  },
                ],
                staticClass: "colorpicker",
              },
              [
                _c("chrome-color-picker", {
                  on: { input: _vm.onColorPick },
                  model: {
                    value: _vm.colors,
                    callback: function ($$v) {
                      _vm.colors = $$v
                    },
                    expression: "colors",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }