<template>
	<a
		:class="classes"
		:href="niceHref"
		:target="target"
		v-on:click="$event => !upload ? click($event) : null"
		>

		<input v-if="upload"
		       type="file"
		       ref="file_input"
		       :accept="Array.isArray(upload) ? upload.join(',') : null"
		       @change="$emit('file', $event.target.files)"
		       :id="file_input_id" />

		<label :for="file_input_id">
			<template v-if="showIcon && side == 'left'">
				<v-svg :file="icon" />
			</template>

			<span class="loading" v-if="loading" key="loading">
				<sweet-v2-spinner size="nano" :color="disabled ? 'light-grey' : color"></sweet-v2-spinner>
			</span>

			<span class="content" v-else key="content">
				<slot></slot>
			</span>

			<template v-if="showIcon && side == 'right'">
				<v-svg :file="icon" />
			</template>
		</label>
	</a>
</template>

<script>
	import mixinHref from 'mixins/href'
	import Functions from 'lib/Functions'

	export default {
		name: 'Button',

		mixins: [
			mixinHref
		],

		props: {
			color: {
				type: String,
				required: false,
				default: 'blue',
			},

			bordered: {
				type: Boolean,
				required: false,
				default: false,
			},

			noBorder: {
				type: Boolean,
				required: false,
				default: false,
			},

			dark: {
				type: Boolean,
				required: false,
				default: false
			},

			icon: {
				type: String,
				required: false,
			},

			dropdownIcon: {
				type: Boolean,
				required: false,
				default: false,
			},

			side: {
				type: String,
				default: 'left',
				required: false,
			},

			disabled: {
				type: Boolean,
				required: false,
				default: false,
			},

			active: {
				type: Boolean,
				required: false,
				default: false,
			},

			loading: {
				type: Boolean,
				required: false,
				default: false,
			},

			upload: {
				type: [Boolean, Array],
				required: false,
				defauit: false,
			}
		},

		computed: {
			classes() {
				return [
					'sweet-v2-button',
					'color-' + this.color,
					'side-' + this.side,
					{
						dark: this.dark,
						disabled: this.disabled,
						active: this.active,
						bordered: this.bordered,
						'no-border': this.noBorder,
						'has-icon': this.icon,
						'icon-only': this.isIconOnly,
						'has-dropdown-icon': this.dropdownIcon
					}
				]
			},

			showIcon() {
				return this.icon && !this.loading
			},

			isIconOnly() {
				return Functions.size(this.$slots) == 0
			}
		},

		data() {
			return {
				file_input_id: 'sbfi' + (Math.random() + 1).toString(36).substring(2, 9)
			}
		}
	}
</script>

<style lang="scss">
	@import "~styles/_mixins";
	@import "~styles/_colors";
	@import "~styles/_fonts";
	@import "~styles/_transitions";

	.sweet-v2-button {
		@include border-box;
		@include mp0;
		@include unselectable;

		display: inline-block;
		text-decoration: none;
		outline: 0;
		text-align: center;
		position: relative;
		line-height: 1.5;
		vertical-align: center;

		font: {
			family: font(main);
			size: 13px;
			weight: 500;
		}

		padding: {
			top: 10px;
			bottom: 10px;
			left: 16px;
			right: 16px;
		}

		&, > a {
			color: #fff;
			-webkit-tap-highlight-color: transparent;
		}

		background-color: mix(color(blue), #fff, 22%);

		border: 0px;
		border-radius: 6px;

		transition: all 0.2s;

		&, label {
			cursor: pointer;
		}

		input {
			display: none;
		}

		&.bordered {
			background: none;
			color: color(blue);
		}

		&:not(.icon-only) {
			min-width: 74px;
		}

		&.has-icon {

			svg, img {
				width: 14px;
				height: 14px;
				
				vertical-align: bottom;
				
				margin: {
					bottom: 4px;
				}

				color: inherit !important;
			}

			&.side-left {

				svg, img {
					margin: {
						left: -4px;
						right: 4px;
					}
				}
			}

			&.side-right {

				svg, img {
					margin: {
						left: 4px;
						right: -4px;
					}
				}
			}
		}

		&.has-dropdown-icon {
			padding-right: 16px + 12px + 4px;

			&::after {
				content: '›';
				position: absolute;
				top: 5px;
				right: 15px;

				font-size: 20px;

				transition: all 0.2s;

				@include transform(rotate(90deg));
			}

			&.dropdown-open::after {
				@include transform(rotate(-90deg));

				right: 17px;
			}
		}

		&.icon-only {

			&.side-left, &.side-right {
				
				svg, img {
					margin: {
						left: 0;
						right: 0;
						top: -2px;
					}
				}
			}
		}

		.loading {
			display: inline-block;
			vertical-align: middle;

			margin-top: -3px;
			margin-bottom: -3px;
		}

		@each $name, $color in getGenericColors() {

			&.color-#{$name} {
				color: $color;
				background-color: mix($color, #fff, 22%);

				&:hover {
					background-color: mix($color, #fff, 45%);
					color: darken($color, 8%);
				}

				&:active, &.active, &.dropdown-open {
					$activeBackground: mix($color, #fff, 64%);

					background-color: $activeBackground;
					color: #fff;
				}

				&.bordered {
					background: none;
					color: $color;
					box-shadow: inset 0px 0px 0px 1px mix($color, #fff, 34%);

					&:hover {
						background: mix($color, #fff, 22%);
					}

					&:active,
					&.active,
					&.dropdown-open {
						background-color: mix($color, #fff, 32%);
						box-shadow: inset 0px 0px 0px 1px mix($color, #fff, 38%);
						color: $color;
					}
				}

				&.dark {
					background-color: mix($color, color(dark), 30%);
					color: lighten($color, 8%);

					&:hover {
						background-color: mix($color, color(dark), 45%);
						color: lighten($color, 12%);
					}

					&:active, &.active, &.dropdown-open {
						background-color: mix($color, color(dark), 64%);
						color: #fff;
					}

					&.bordered {
						background: none;
						color: $color;
						box-shadow: inset 0px 0px 0px 1px mix($color, color(dark), 34%);

						&:hover {
							background: mix($color, color(dark), 22%);
						}

						&:active,
						&.active,
						&.dropdown-open {
							background-color: mix($color, color(dark), 30%);
							box-shadow: inset 0px 0px 0px 1px mix($color, color(dark), 40%);
							color: lighten($color, 8%);

							@if lightness($color) > 50% {
								color: darken($color, 30%);
							}
						}
					}
				}
			}
		}

		&.no-border:not(:hover):not(:active) {
			border-color: transparent;
		}

		&.disabled {
			@include unselectable;
			pointer-events: none;

			background: mix(color(disabled-state), #fff, 64%);

			&, > a {
				color: darken(mix(color(disabled-state), #fff, 64%), 18%);
			}

			&.bordered {
				box-shadow: inset 0px 0px 0px 1px color(disabled-state);
				color: color(light-grey);
			}

			&.dark {
				background: mix(color(disabled-state), color(dark), 16%);

				&, > a {
					color: lighten(mix(color(disabled-state), color(dark), 16%), 18%);
				}

				&.bordered {
					box-shadow: inset 0px 0px 0px 1px mix(color(disabled-state), color(dark), 16%);
					color: lighten(mix(color(disabled-state), color(dark), 16%), 18%);
				}
			}
		}
	}
	
	.sweet-popover .actions {
		
		.sweet-v2-button {
			
			@each $name, $color in getGenericColors() {
				&.color-#{$name} {
					background-color: rgba($color, 0.18);
					color: darken($color, 12%);
					
					@if brightness($color) > 67% {
						color: darken($color, 18%);
					}
					
					&:hover {
						background-color: rgba($color, 0.45);
						color: darken($color, 16%);
					}
					
					&:active, &.active, &.dropdown-open {
						$activeBackground: rgba($color, 0.64);
						
						background-color: $activeBackground;
						color: #fff;
						
						@if brightness($color) > 67% {
							color: darken($color, 18%);
						}
					}
					
					&.dark {
						@if brightness($color) < 38% {
							background-color: rgba($color, 0.4);
							color: lighten($color, 26%);
						} @else {
							background-color: rgba($color, 0.3);
							color: lighten($color, 14%);
						}
						
						&:hover {
							@if brightness($color) < 38% {
								background-color: rgba($color, 0.6);
								color: lighten($color, 32%);
							} @else {
								background-color: rgba($color, 0.45);
								color: lighten($color, 18%);
							}
						}
						
						&:active, &.active, &.dropdown-open {
							@if brightness($color) < 38% {
								background-color: rgba($color, 0.74);
								color: #fff;
							} @else {
								background-color: rgba($color, 0.64);
								color: #fff;
							}
						}
					}
				}
			}
		}
	}

	.sweet-input + .sweet-v2-button ~ .sweet-v2-button,
	.sweet-input + .sweet-v2-button,
	.sweet-autocomplete + .sweet-v2-button ~ .sweet-v2-button,
	.sweet-autocomplete + .sweet-v2-button {
		padding: {
			top: 10px;
			bottom: 12px;
		}

		vertical-align: middle;
	}

	.sweet-select ~ .sweet-v2-button {
		padding: {
			top: 9px;
			bottom: 9px;
			left: 20px;
			right: 20px;
		}
	}

	.sweet-action.has-icon + .sweet-v2-button {
		margin-left: 12px;
	}

	.sweet-action:not(.has-icon) + .sweet-v2-button {
		margin-left: 24px;
	}
	
	.sweet-v2-button + .sweet-v2-button {
		margin-left: 8px;
	}
</style>
