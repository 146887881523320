<template>
	<div :class="['sweet-input', 'type-' + type, 'size-' + size, { active: value, dark, underlined }]">
		<input
			:type="finalType"
			:placeholder="placeholder"
			:value="value"
			:class="['color-' + color, 'size-' + size, {'has-icon': icon, 'has-label': label, 'has-dropdown': dataDropdown, 'error': error, dark}]"
			:id="id"
			:disabled="disabled"
			:autocomplete="autocomplete == false && autocomplete !== null ? 'off' : autocomplete"
			:name="name"
			v-on:click="onClick"
			v-on:input="onInput"
			v-on:keyup="onKeyup"
			v-on:keyup.enter="onKeyupEnter"
			v-on:keyup.esc="onKeyupEscape"
			v-on:keydown="onKeydown"
			v-on:focus="onFocus"
			ref="input"
			/>

		<label :for="id" class="icon" v-if="icon">
			<v-svg :file="icon" />
		</label>

		<label :for="id" class="label" v-if="label">
			{{ label }}
		</label>

		<label :for="id" class="color" v-if="type == 'color'" :style="colorStyle"></label>
		<label class="dropdown-trigger" v-if="dataDropdown" :data-dropdown="dataDropdown" :data-add-anchor-x="dataAddAnchorX"><span>›</span></label>

		<slot name="dropdown"></slot>

		<transition name="move-fade-right">
			<label :for="id" class="reset" v-if="showReset" v-show="value != '' && value !== null">
				<a v-on:click="reset"><v-svg file="acp/cross-circle" /></a>
			</label>
		</transition>

		<transition name="move-fade-bottom">
			<div class="colorpicker" v-if="type == 'color'" v-show="colorpicker_open == true">
				<chrome-color-picker v-model="colors" v-on:input="onColorPick" />
			</div>
		</transition>
	</div>
</template>

<script>
	import { Chrome } from 'vue-color'

	export default {
		name: 'Input',

		props: {
			type: {
				type: String,
				required: false,
				default: 'text',
			},

			id: {
				type: String,
				required: false,
				default() {
					return 'si' + (Math.random() + 1).toString(36).substring(2, 9)
				},
			},
			
			name: {
				type: String,
				required: false,
				default: null
			},

			color: {
				type: String,
				required: false,
				default: 'blue'
			},

			dark: {
				type: Boolean,
				required: false,
				default: false,
			},

			placeholder: {
				type: String,
				required: false,
				default: '',
			},

			value: {
				type: [String, Number],
				required: false,
				default: null,
			},

			icon: {
				type: String,
				required: false
			},

			showReset: {
				type: Boolean,
				required: false,
				default: false,
			},

			label: {
				type: String,
				required: false,
				default: '',
			},

			size: {
				type: String,
				required: false,
				default: 'regular',
			},

			dataDropdown: {
				type: String,
				required: false,
				default: null,
			},

			dataAddAnchorX: {
				type: [String, Number],
				required: false,
				default: null,
			},

			disabled: {
				type: Boolean,
				required: false,
				default: false,
			},

			autocomplete: {
				type: [Boolean, String],
				required: false,
				default: null,
			},
			
			valid: {
				type: [Boolean, Function],
				required: false,
				default: null,
			},
			
			regex: {
				type: [String, RegExp],
				required: false,
				defauit: null,
			},
			
			underlined: {
				type: Boolean,
				required: false,
				default: false
			}
		},

		mounted() {
			document.querySelector('body').addEventListener('click', this.onBodyClick, true)
		},

		beforeDestroy() {
			document.querySelector('body').removeEventListener('click', this.onBodyClick, true)
		},

		data() {
			return {
				error: false,
				colorpicker_open: false,
				colors: {
					hex: '#' + this.value
				}
			}
		},

		computed: {
			input() {
				return this.$refs.input
			},

			finalType() {
				return this.type == 'color' ? 'text' : this.type
			},

			colorStyle() {
				if (this.type != 'color') return {}

				let color = this.value.replace('#', '')

				return {
					backgroundColor: '#' + color
				}
			}
		},

		methods: {

			onBodyClick(e) {
				if (!this.$el.contains(e.target) && this.$el != e.target) {
					this.colorpicker_open = false
				}
			},

			onClick(event) {
				this.$emit('click', event)
			},

			onFocus(event) {
				if (this.type == 'color') {
					this.colorpicker_open = true
				}

				this.$emit('focus', event)
			},

			/*
			_onInput(value) {
				if (this.valid) {
					try {
						this.error = !(new RegExp(this.valid).test(value))

						if (this.error) {
							this.$emit('error', value)
						} else {
							this.$emit('success', value)
						}
					} catch (e) {
						console.warn('Warning: Invalid RegEx: ' + this.valid)
						console.error(e)
					}
				}
			},*/
			
			onInput(event) {
				this.$emit('input', event.target.value)
				this._onInput(event.target.value)
			},
			
			_onInput(value) {
				if (this.valid === false || this.valid === true) {
					this.error = !this.valid
				} else if (typeof(this.valid) == "function") {
					this.error = !this.valid(String(value))
				} else if (this.regex) {
					try {
						this.error = !(new RegExp(this.regex).test(value))
					} catch (e) {
						console.warn('Warning: Invalid RegEx: ' + this.regex)
						console.error(e)
					}
				} else {
					this.error = !this.$refs.input.validity.valid
				}
				
				if (this.error) {
					this.$emit('error', value)
				} else {
					this.$emit('success', value)
				}
			},

			onKeyup(event) {
				this.$emit('keyup', event)
			},

			onKeyupEnter(event) {
				this.$emit('keyup.enter', event)
			},

			onKeyupEscape(event) {
				this.$emit('keyup.esc', event)
			},

			onKeydown(event) {
				this.$emit('keydown', event)
			},

			onColorPick(val) {
				this.setValue(val.hex.replace('#', ''))
			},

			setValue(val) {
				this.$emit('input', val)
			},

			isError() {
				return this.error
			},

			focus() {
				this.$refs.input.focus()
			},

			reset() {
				this.$emit('input', '')
				this.$emit('reset')
			}
		},

		watch: {
			valid(regex) {
				this._onInput(this.value)
			}
		},

		components: {
			ChromeColorPicker: Chrome
		}
	}
</script>

<style lang="scss">
	@import "~styles/_mixins";
	@import "~styles/_colors";
	@import '~styles/inputs';
	@import "~styles/_transitions";

	.sweet-input {
		position: relative;
		display: inline-block;
		vertical-align: bottom;
		line-height: 0.9;

		input {
			position: relative;
			z-index: 1;
			width: 100%;
			-webkit-tap-highlight-color: transparent;
			line-height: 1.3;

			&[type=date] {
				padding: {
					top: 9px;
					bottom: 8px;
				}
			}

			&.has-icon {
				padding-left: 36px;
			}

			&.has-label {
				padding-top: 18px;
				padding-bottom: 18px;
			}

			&.has-dropdown {
				padding-right: 36px;
			}
		}

		&.error input {
			@extend input[type=text].error;
		}

		&.type-color {
			width: 108px;

			> input {
				padding-left: 38px;
			}

			label.color {
				display: block;

				position: absolute;
				z-index: 3;
				top: 8px;
				left: 8px;

				height: 23px;
				width: 23px;

				border-radius: 6px;
			}

			.colorpicker {
				position: absolute;
				top: 40px;
				left: 1px;

				z-index: 40;

				.vue-color__chrome {
					box-shadow: 0px 12px 24px rgba(#000, 0.09),
								0px 20px 26px rgba(#000, 0.06);
				}
			}
		}

		label.dropdown-trigger {
			position: absolute;
			top: 1px;
			right: 1px;
			z-index: 3;

			height: calc(100% - 2px);
			width: 32px;

			cursor: pointer;
			text-align: center;

			border-left: 1px solid color(border);
			color: color(dark);

			border-top-right-radius: 3px;
			border-bottom-right-radius: 3px;

			span {
				position: absolute;
				top: 50%;
				left: 50%;

				font-size: 18px;

				transform: translate(calc(-50% + 1px), -50%) rotate(90deg);
			}

			&:hover {
				background: color(border);
			}

			&.dropdown-open {
				top: 0;
				right: 0;

				height: 100%;

				background: color(blue);

				span {
					color: #fff;
				}
			}
		}

		.icon {
			position: absolute;
			z-index: 3;
			
			display: flex;
			align-items: center;

			top: 0px;
			left: 13px;
			
			height: 100%;

			cursor: text;
			color: color(light-grey);

			svg {
				width: 16px;
				height: 16px;
				vertical-align: middle;
			}
		}

		&.size-big .icon {
			left: 15px;
		}

		.label {
			position: absolute;
			z-index: 3;

			top: 19px;
			left: 13px;

			color: color(light-grey);
			font-size: 13px;

			cursor: text;
			transition: all 0.2s;
		}
		
		&.size-huge .label {
			font-size: 18px;
		}

		$sizes: (
			small: 11px,
			regular: 13px,
			big: 16px,
			huge: 24px
		);

		@each $name, $size in $sizes {

			&.size-#{$name} input {
				font-size: $size;

				@if $name == "small" {
					padding: {
						top: 7px;
						bottom: 7px;
					}
				}
			}
		}

		&.size-small {

			input.has-label {
				padding-top: 18px;
				padding-bottom: 18px;
			}
		}

		&.active {

			input.has-label {
				padding-top: 22px;
				padding-bottom: 14px;
			}

			.label {
				top: 12px;
				font-size: 10px;
				text-transform: uppercase;
				font-weight: 600;
			}
		}

		.reset {
			position: absolute;
			z-index: 3;

			top: 13px;
			right: 9px;

			cursor: text;

			background: linear-gradient(to right, rgba(#fff, 0) 0%, rgba(#fff, 1) 25%);

			padding: {
				right: 5px;
				left: 12px;
			}

			svg {
				width: 11px;
				height: 11px;
				color: color(light-grey);
			}

			a {
				display: block;
			}

			a:hover svg {
				cursor: pointer;
				color: color(red);
			}
		}

		&.size-big .reset {
			top: 16px;
			right: 15px;
		}

		&:not(.error) input:hover ~ .icon,
		&:not(.error) input:focus ~ .icon {
			color: color(blue);
		}

		&:not(.error) input:hover ~ .label,
		&:not(.error) input:focus ~ .label {
			color: color(blue);
		}

		@each $name, $color in getGenericColors() {
			&:not(.error) input.color-#{$name}:hover ~ .icon,
			&:not(.error) input.color-#{$name}:focus ~ .icon {
				color: $color;
			}

			&:not(.error) input.color-#{$name}:hover ~ .label,
			&:not(.error) input.color-#{$name}:focus ~ .label {
				color: $color;
			}
		}
		
		&.underlined {
			
			input {
				border-radius: 0px;
				
				border: {
					top: 0;
					right: 0;
					left: 0
				}
			}
		}

		&.dark {
			$background: lighten(color(dark), 1%);

			.icon {
				color: color(light-grey-blue);
			}
			
			.label {
				color: color(light-grey-blue);
			}

			input {
				background: $background;
				color: #fff;

				&:not(:focus):not(:active):not(:hover) {
					border-color: color(dark-border);
				}

				@include placeholder {
					color: color(light-grey-blue);
				}
			}

			.reset {
				background: linear-gradient(to right, rgba($background, 0) 0%, rgba($background, 1) 25%);

				svg {
					color: color(light-grey-blue);
				}
			}
			
			&.underlined {
				
				input {
					background: transparent;
					
					&:not(:focus):not(:active):not(:hover) {
						border-color: lighten(color(dark), 16%);
					}
				}
			}
		}

		input.error ~ .icon,
		&.error .icon {
			color: color(red);
		}

		&.action-overlayed input {
			padding-right: 96px;
		}

		& + a.sweet-action.overlay {
			position: absolute;
			top: 11px;
			right: 16px;
			z-index: 3;
		}
		
		&.full-width {
			width: 100%;
		}
	}

	.sweet-modal .sweet-box-actions {

		.sweet-input {
			margin-top: -1px;
			vertical-align: middle;
		}

		.sweet-checkbox + .sweet-input {
			margin-left: 16px;
		}
	}
	
	.sweet-modal .sweet-list-item.search .sweet-input {
		width: 100%;
	}
</style>
