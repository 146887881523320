import UserManager from 'lib/UserManager'

/**
 * Load a view when requested (lazy loading)
 *
 * @param  {string} name Component's name/path to load.
 *
 * @return {Function}    Resolver
 */
export function loadView(name) {
	return resolve => require(['views/' + name + '.vue'], resolve)
}

/**
 * Load a layout when requested (lazy loading)
 *
 * @param  {string} name Component's name/path to load.
 *
 * @return {Function}    Resolver
 */
export function loadLayout(name) {
	return resolve => require(['layouts/' + name + '.vue'], resolve)
}

export function trySection(permissions, nextRouteName, next) {
	if (!nextRouteName) {
		nextRouteName = 'access-denied'
	}

	let redirect = {
		name: nextRouteName
	}

	if (!UserManager.isLoggedIn()) {
		next(redirect)
	} else if (!UserManager.user.hasAnyPermission(permissions)) {
		next(redirect)
	} else {
		next()
	}
}