import UIHelper from 'lib/UIHelper'

/**
 * href-Mixin
 * Provides a href-property with default behaviour
 * for detecting internal, external and null-links
 * to respond appropriately:
 *
 * internal links will be pushed to the router
 * external links will be mounted as a href-attribute (use niceHref for this!)
 * null-links will still be mounted as niceHref but due to browser behaviour they do not
 * act as links and therefore do not have a click behaviour
 *
 * @author bluefirex
 * @version 1.0
 * @package as.adepto.sweet-acp.mixins
 */
export default {
	props: {
		href: {
			type: [String, Object],
			required: false,
			default: null,
			description: 'If internal (no https?://), router is ultilized'
		},

		hrefDisabled: {
			type: [Boolean],
			require: false,
			default: false,
			description: 'Disable href-navigation even though href is a valid route/URL'
		},

		target: {
			type: String,
			required: false,
			default: null,
			description: 'Target to open href in, i.e. _blank'
		}
	},

	data() {
		return {
			/**
			 * Holds whether the href is part of the current route
			 *
			 * @type {Boolean}
			 */
			href_routeActive: false
		}
	},

	mounted() {
		UIHelper.onVueBus('routeChange', this.actOnRoute)

		if (this.$router) {
			this.actOnRoute(this.$router.currentRoute)
		}
	},

	computed: {
		/**
		 * Is the link external?
		 * External links start with a protocol,
		 * i.e. http://, https://, ftp://, ...
		 *
		 * @type {boolean}
		 */
		isExternalLink() {
			return this.href !== null && typeof(this.href) == 'string' && (this.href.match(/^[a-zA-Z]+:/) || this.href.match(/^(\/|\.\/)?(mid|asg)\//))
		},

		/**
		 * Is the link internal?
		 * Internal links do not have a protocol and may or may not be an object
		 * for programmatic navigation for the Vue router.
		 *
		 * @type {boolean}
		 */
		isInternalLink() {
			return this.href !== null && (!this.isExternalLink || typeof(this.href) == 'object')
		},

		/**
		 * Is it a null-link?
		 *
		 * @type {boolean}
		 */
		isNullLink() {
			return this.href === null
		},

		/**
		 * A nice href value for the browser:
		 * - Raw Link for external links
		 * - '#' for internal links
		 * - null for null-links (which makes the attribute disappear)
		 */
		niceHref() {
			if (this.isExternalLink) {
				return this.href
			}

			if (this.isInternalLink) {
				if (typeof(this.href) == 'object') {
					try {
						return this.$router.resolve(this.href).href
					} catch (e) {
						return '#route-not-found'
					}
				} else if (this.href == 'back') {
					return '#'
				}

				return this.href
			}

			return null
		}
	},

	methods: {
		/**
		 * Handler for when someone clicked something that uses niceHref
		 * Emits 'click'.
		 *
		 * @param {Event} event Click Event Data
		 */
		click(event) {
			this.$emit('click')

			if (this.disabled || this.href == '#') {
				event.preventDefault()
				return
			}
			
			if (this.href == 'back') {
				event.preventDefault()
				this.$router.go(-1)
				
				return
			}

			if (this.href !== null && this.niceHref === null) {
				console.warn('Route not found:', this.href)
				return
			}

			if (this.isInternalLink) {
				if (event.metaKey || event.ctrlKey) {
					window.open(this.niceHref, '_blank')
				} else {
					try {
						this.$router.push(this.href)
					} catch (e) {
						console.warn('Route not found:', this.href)
					}

					event.preventDefault()
				}
			}
			
			if (this.isExternalLink) {
				if (event.metaKey || event.ctrlKey || this.target == '_blank') {
					window.open(this.niceHref, '_blank')
				} else {
					self.location.href = this.niceHref
				}
				
				event.preventDefault()
			}

			if (this.isNullLink && !this.hrefDisabled) {
				event.preventDefault()
			}
		},

		/**
		 * Check if a given route matches this href
		 *
		 * @param  {VueRoute} $route Route
		 *
		 * @return {Boolean}
		 */
		routeMatches($route) {
			if (this.href === null) {
				return false
			}

			let regexHref = this.niceHref
			                    // Creation routes have a "+" in them, which needs to be escaped
			                    .replace('+', '\\+')
			                    // Also brackets need be escaped as they can possibly throw errors when mismatched
			                    .replace(/\(/g, '\\(')
			                    .replace(/\)/g, '\\)')

			return !!$route.fullPath.match(new RegExp('^' + regexHref + '((/|\\?).*)?$'))
		},

		/**
		 * Act when the route changed
		 *
		 * @param  {VueRoute} $route Route
		 */
		actOnRoute($route) {
			this.href_routeActive = this.routeMatches($route)
		}
	},

	watch: {
		/**
		 * Emit an event "active-changed" whenever the routeActive status changes
		 *
		 * @param  {Boolean} val Currently active?
		 */
		href_routeActive(val) {
			this.$emit('active-changed', val)
		}
	}
}
