import Permissions from 'lib/Permissions'
import * as r from 'routes/_functions'

const OrdersSidebar = r.loadView('asgard/orders/Sidebar')
const appearance = 'blue'

export default [
	{
		path: '/orders',
		component: r.loadLayout('Basic'),

		meta: {
			permissions: [
				Permissions.ORDERS,
				Permissions.ORDERS + '.*',
				Permissions.ORDERS + '.*.*',
				Permissions.CLIENTS,
				Permissions.CLIENTS + '.*',
				Permissions.CLIENTS + '.*.*',
				Permissions.CERBERUS,
				Permissions.CERBERUS + '.*'
			]
		},

		children: [
			{
				name: 'orders',
				path: '/orders',
				redirect: '/orders/overview'
			},

			{
				name: 'orders-overview',
				path: 'overview',

				components: {
					default: r.loadView('asgard/orders/Overview'),
					sidebar: OrdersSidebar
				},
			},

			{
				name: 'orders-upcoming',
				path: 'upcoming',

				components: {
					default: r.loadView('asgard/orders/Collection'),
					sidebar: OrdersSidebar
				},

				meta: {
					type: 'upcoming',

					permissions: [
						Permissions.ORDERS_VIEWS
					]
				},
			},

			{
				name: 'orders-submitted',
				path: 'submitted',

				components: {
					default: r.loadView('asgard/orders/Collection'),
					sidebar: OrdersSidebar
				},

				meta: {
					type: 'submitted',

					permissions: [
						Permissions.ORDERS_VIEWS
					]
				},
			},

			{
				name: 'orders-search',
				path: 'search',

				components: {
					default: r.loadView('asgard/orders/Search'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_SEARCH
					]
				},
			},

			{
				name: 'orders-single',
				path: ':referrer(upcoming|submitted|search|leads|report|test|api)/:clientShort/:orderType/:orderID',

				props: {
					default: (route) => ({
						referrer: route.params.referrer,
						clientShort: route.params.clientShort,
						orderType: route.params.orderType,
						orderID: route.params.orderID,
						protocolID: route.query.protocol || null,
						protocolTab: route.query.protocolTab || null
					})
				},

				components: {
					default: r.loadView('asgard/orders/View'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_VIEW
					]
				},
			},

			{
				name: 'orders-demo',
				path: 'demo',

				components: {
					default: r.loadView('asgard/orders/Demo'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_VIEW
					]
				},
			},

			{
				name: 'orders-create',
				path: '+create',

				components: {
					default: r.loadView('asgard/orders/Create'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_EDIT
					]
				},
			},

			{
				name: 'orders-create-for-client',
				path: '+create/:clientShort',

				props: {
					default: true
				},

				components: {
					default: r.loadView('asgard/orders/Create'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_EDIT
					]
				},
			},

			{
				name: 'orders-create-for-client-with-order-type',
				path: '+create/:clientShort/:orderType',

				props: {
					default: (route) => ({
						clientShort: route.params.clientShort,
						orderType: route.params.orderType,
						orderID: 'create'
					}),

					sidebar: false
				},

				components: {
					default: r.loadView('asgard/orders/Edit'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_EDIT
					]
				},
			},

			{
				name: 'orders-edit',
				path: 'edit/:clientShort/:orderType/:orderID',

				props: {
					default: true
				},

				components: {
					default: r.loadView('asgard/orders/Edit'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_EDIT
					]
				},
			},

			{
				name: 'orders-fetch',
				path: 'fetch',

				components: {
					default: r.loadView('asgard/orders/Fetch'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.ORDERS_FETCH
					]
				},
			},

				{
					name: 'orders-fetch-for-client',
					path: 'fetch/:clientShort',

					props: {
						default: true
					},

					components: {
						default: r.loadView('asgard/orders/Fetch'),
						sidebar: OrdersSidebar
					},

					meta: {
						permissions: [
							Permissions.ORDERS_FETCH
						]
					},
				},

			{
				name: 'orders-clients-manage',
				path: 'clients',

				components: {
					default: r.loadView('asgard/clients/Overview'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.CLIENTS_VIEW,
						Permissions.CLIENTS_EDIT,
						Permissions.CLIENTS_EDIT + '.*',
					]
				},
			},

				{
					name: 'orders-clients-edit',
					path: 'clients/:clientShort',

					props: {
						default: true
					},

					components: {
						default: r.loadView('asgard/clients/Edit'),
						sidebar: OrdersSidebar
					},

					meta: {
						permissions: [
							Permissions.CLIENTS_EDIT,
							Permissions.CLIENTS_EDIT + '.*'
						]
					},

					children: [
						{
							name: 'orders-clients-edit-branding',
							path: 'branding',
							component: r.loadView('asgard/clients/edit/Branding'),
							props: true,

							meta: {
								permissions: [
									Permissions.CLIENTS_EDIT_BRANDING
								]
							},
						},

						{
							name: 'orders-clients-edit-notifications',
							path: 'notifications',
							component: r.loadView('asgard/clients/edit/Notifications'),
							props: (route) => {
								const routeName = route.name || ''

								return {
									clientShort: route.params.clientShort,
									routineID: route.params.routineID || (routeName == 'orders-clients-edit-notifications-routine-create' ? 'create' : null),
									templateID: route.params.templateID || (routeName == 'orders-clients-edit-notifications-template-create' ? 'create' : null)
								}
							},

							meta: {
								permissions: [
									Permissions.CLIENTS_EDIT_NOTIFICATIONS
								]
							},

							children: [
								{
									name: 'orders-clients-edit-notifications-routine',
									path: 'routines/:routineID(\\d+)',
									component: r.loadView('asgard/clients/edit/Notifications')
								},

								{
									name: 'orders-clients-edit-notifications-routine-create',
									path: 'routines/+create',
									component: r.loadView('asgard/clients/edit/Notifications')
								},

								{
									name: 'orders-clients-edit-notifications-template',
									path: 'templates/:templateID(\\d+)',
									component: r.loadView('asgard/clients/edit/Notifications')
								},

								{
									name: 'orders-clients-edit-notifications-template-create',
									path: 'templates/+create',
									component: r.loadView('asgard/clients/edit/Notifications')
								}
							]
						},

						{
							name: 'orders-clients-edit-ticket-types',
							path: 'protocol-types',
							component: r.loadView('asgard/clients/edit/TicketTypes'),
							props: (route) => {
								return {
									clientShort: route.params.clientShort
								}
							},

							meta: {
								permissions: [
									Permissions.CLIENTS_EDIT_TICKET_TYPES
								]
							}
						}
					]
				},

			{
				name: 'orders-upload',
				path: 'upload',

				components: {
					default: r.loadView('asgard/orders/Upload'),
					sidebar: OrdersSidebar
				},

				meta: {
					permissions: [
						Permissions.CERBERUS_UPLOAD
					]
				},
			},
		]
	}
]